import React from "react";
import GoogleMapReact from "google-map-react";
import markerImg from "../../../Assets/img/location.png";

const Marker = ({ children }) => {
  return children || null;
};

function SingleHotelMapView({ latitude, longitude, className }) {
  return (
    <div className={className} style={{ height: "161px" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY }}
        defaultCenter={{ lat: 28.70406, lng: 77.102493 }}
        center={{ lat: latitude, lng: longitude }}
        defaultZoom={12}
      >
        <Marker lat={latitude} lng={longitude}>
          <img src={markerImg} alt="marker" height="28px" />
        </Marker>
      </GoogleMapReact>
    </div>
  );
}

export default SingleHotelMapView;
