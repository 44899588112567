import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import NotesBlueImg from "../../Assets/img/icons/Terms-icon-blue.png";
import NotesImg from "../../Assets/img/icons/Terms-icon.png";

function TermsTab() {
  // State of class for list
  const location = useLocation();
  const pathName = location.pathname;

  const [active, setActive] = useState(() => {
    if (pathName == "/terms-conditions") return 0;
    else if (pathName == "/privacy-policy") return 1;
    else if (pathName == "/cookies-policy") return 2;
    return 0;
  });

  // Handling class with id
  const handleActive = (id) => {
    setActive(id);
  };

  // Faqs tab list
  const TermsList = [
    {
      id: 0,
      title: "Terms And Conditons",
      path: "/terms-conditions",
      image: active == 0 ? NotesImg : NotesBlueImg,
    },
    {
      id: 1,
      title: "Privacy Policy",
      path: "/privacy-policy",
      image: active == 0 ? NotesImg : NotesBlueImg,
    },
    {
      id: 2,
      title: "Cookie Policy",
      path: "/cookies-policy",
      image: active == 0 ? NotesImg : NotesBlueImg,
    },
  ];

  return (
    <div className="col leftContent">
      <div className="tabsWrapper">
        {/* getting faqs tab list */}
        <ul className="list-unstyled">
          {TermsList.map((item, index) => (
            <li
              key={index}
              className={active === index ? "active" : ""}
              onClick={() => handleActive(index)}
            >
              <Link to={item.path} key={index}>
                <img
                  src={active === index ? NotesBlueImg : NotesImg}
                  alt="icon"
                  key={index}
                />
                {item.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default TermsTab;
