import React, { useState, useEffect } from "react";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import HeaderNavbar from "../../Component/HeaderNavbar";
import PayoutTransactions from "./PayoutTransactions";
import TabMenu from "../AffiliateDashbord/TabMenu";
import WalletDetailModal from "./WalletDetailModal";
import infoIcon from "../../Assets/img/info-icon-white.png";
import DetailsInfoModal from "../AffiliateDashbord/DetailsInfoModal";
import Scrollbars from "react-custom-scrollbars";
import { useDispatch, useSelector } from "react-redux";


function Wallet() {
  // State for details info modal
  const [openModal, setOpenModal] = useState(false);

  // Handle open modal
  const handleOpenCloseModal = () => {
    if (!openModal) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  };
 
  // Modal for Wallet Table
  const [openTableModal, setOpenTableModal] = useState(false);
  // Handle open modal
  const handleOpenCloseTableModal = () => {
    if (!openTableModal) {
      setOpenTableModal(true);
    } else {
      setOpenTableModal(false);
    }
  };

  const [hideNavbar, setHideNavbar] = useState(false);

  return (
    <div className="siteBg landingPage membersCheckoutPageWrap dashboard membersRewardsPageWrap walletPage dashboard-pages">
      <div className="pageWrap pageWrapInner">
        <div className="fixedHeader">
          {!hideNavbar && <HeaderNavbar />}
        </div>
        <section className="mainContent ">
          <Scrollbars
            autoHide
            autoHeight
            autoHeightMin={120}
            autoHeightMax={`calc(100%)`}
            className="pageInternalScroll"
          >
            <div className="container walletWrapper common-wrapper">
              <TabMenu infoIcon={infoIcon} modalOpen={handleOpenCloseModal} />
              <PayoutTransactions setHideNavbar={setHideNavbar}  modalOpen={handleOpenCloseTableModal} />
            </div>
          </Scrollbars>
        </section>
        <FooterWithMediaIcon
          className="white-icon"
          iconClassName="icon-white"
        />
        {openModal && (
          <DetailsInfoModal
            openModal={openModal}
            closeModal={handleOpenCloseModal}
          />
        )}
        {openTableModal && (
          <WalletDetailModal
            openTableModal={openTableModal}
            closeTableModal={handleOpenCloseTableModal}
          />
        )}
      </div>
    </div>
  );
}

export default Wallet;