import React from "react";
import Modal from "react-modal";

function IpadPortraitPopup({ isOpen }) {
  return (
    <Modal
      isOpen={isOpen}
      ariaHideApp={false}
      className="popup ipad-portrait-popup"
    >
      <div className="popup-content">
        <div className="popup-title">
          <button className="btn close">
            <img src="img/close-blue-icon.png" alt="icon" />
          </button>
        </div>
        <div className="content-box">
          <img src="img/logo2.png" alt="logo" />
          <h6>
            For the <strong>Best User Experience</strong> on our platform,
            <br /> please turn/tilt your device
            <strong>horizontally</strong>
            <br /> into <strong>landscape mode.</strong>
            <br />
            Thank you.
          </h6>
        </div>
      </div>
    </Modal>
  );
}

export default IpadPortraitPopup;
