import React, { useEffect, useState, useRef, forwardRef } from "react";
import { hotelDetailMockData } from "../../Data/hotelMockData";
import HotelDetailsTabs from "./HotelDetailsTabs";
import HotelDetailCard from "./HotelDetailCard/HotelDetailCard";
import Overview from "./Overview/Overview";
import Rooms from "./Rooms/Rooms";
import Amenities from "./Amenities/Amenities";
import PropertiesDetails from "./PropertyDetails/PropertiesDetails";
import Reviews from "./Reviews/Reviews";
import { useParams, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { homeActions } from "../../Stores/Actions";
import HeaderNavbar from "../../Component/HeaderNavbar";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import HomeWidget from "../HomeSearchResult/HomeWidget";
import { Scrollbars } from "react-custom-scrollbars";
import { convertTime12To24 } from "../../Util/util";
import ExpireRate from "../../Component/ExpireRate/ExpireRate";


function HotelDetail() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = JSON.parse(window.localStorage.getItem("user"));
  const storedPayload = window.localStorage.getItem("homePayload")
    ? JSON.parse(window.localStorage.getItem("homePayload"))
    : JSON.parse(window.localStorage.getItem("mapPayload"));

  const hotelDetails = useSelector((state) => state.hotelReducer.hotelDetails);
  const [hideFilter, setHideFilter] = useState(false);
  const [hideHeader, setHideHeader] = useState(false);
  const [toggleFilterDrop, setToggleFilterDrop] = useState(true);
  const [activeTab, setActiveTab] = useState("overview");


  const scrollRef = useRef(null);

  const currencyAndLanguage =
    window.localStorage.getItem("currencyAndLanguage") &&
    JSON.parse(window.localStorage.getItem("currencyAndLanguage"));

  const currency =
    currencyAndLanguage?.currencyLanguageReducer?.data?.currency || {};

  const currentGeoLocation =
    window.localStorage.getItem("currentGeoLocation") &&
    JSON.parse(window.localStorage.getItem("currentGeoLocation"));

  const isUS = currentGeoLocation === "US";

  const scrollToView = (idName) => {
    const offsetTop = document.getElementById(idName).offsetTop
    if (window.innerWidth > 991) {
      scrollRef.current.view.scroll({
        top: offsetTop,
        behavior: 'smooth',
      })
    } else {
      window.scrollTo({
        top: offsetTop,
        behavior: 'smooth',
      })
    }
    setActiveTab(idName);
  }


  const initialPayload = {
    rooms: storedPayload?.rooms.length,
    region_id: storedPayload?.region_id,
    check_in_date: storedPayload?.check_in_date,
    check_out_date: storedPayload?.check_out_date,
    children_count: 0,
    city_name: storedPayload?.city_name,
    currency: currency.isoCode,
    home: storedPayload?.home,
    hotel_id: params?.hotel_id,
    locale: storedPayload?.locale,
    onlyLocale: storedPayload?.onlyLocale,
    optional: "amenities,moreDetails,policy,moreRatings",
    preventCache: 0.9299991871126359,
    search_type: storedPayload?.search_type,
    source_market: storedPayload?.source_market,
    token: window.localStorage.getItem("accessToken"),
    type: storedPayload?.search_type,
    nationality: storedPayload?.nationality,
    use_wallets:
      user.type_id === process.env.REACT_APP_FREMIUM_USER_TYPE_ID ? 1 : 0,
  };

  useEffect(() => {

    if (window.localStorage.getItem("homePayload")) {
      dispatch(homeActions.getHotelDetails(initialPayload, params?.hotel_id));
    } else {
      navigate("/landing");
    }

    return () => {
      dispatch(homeActions.resetHotelDetails());
    };
  }, []);

  function hideFunc() {
    setHideFilter((prev) => !prev);
  }

  const [CountdownStatus, setCountDownStatus] = useState(null)

  const onComplete = () => {
    // setCountDownStatus(Date.now())
    // const initialPayload = {
    //   rooms: storedPayload?.rooms,
    //   region_id: storedPayload?.region_id,
    //   check_in_date: storedPayload?.check_in_date,
    //   check_out_date: storedPayload?.check_out_date,
    //   children_count: 0,
    //   city_name: storedPayload?.city_name,
    //   currency: currency.isoCode,
    //   home: storedPayload?.home,
    //   hotel_id: params?.hotel_id,
    //   locale: storedPayload?.locale,
    //   onlyLocale: storedPayload?.onlyLocale,
    //   search_type: storedPayload?.search_type,
    //   source_market: storedPayload?.source_market,
    //   token: window.localStorage.getItem("accessToken"),
    //   type: storedPayload?.search_type,
    //   nationality: storedPayload?.nationality,
    //   use_wallets:
    //     user.type_id === process.env.REACT_APP_FREMIUM_USER_TYPE_ID ? 1 : 0,
    // };

    // console.log("initialPayload",initialPayload);

    // dispatch(homeActions.getHotelRooms(initialPayload, params?.hotel_id));
  }

  const Countdown = () => {
    return <>
      <ExpireRate
        className="expireTimer mobileViewDisplayNone"
        showIcon={true}
        text="Rate will expire"
        time={1200000}
        onComplete={onComplete}
      />
    </>
  }
  const CountDownForMobile = () => {
    return <>
      <ExpireRate
        className="expireTimer desktopViewDisplayNone"
        showIcon={true}
        text="Rate will expire"
        time={1200000}
        onComplete={onComplete}
      />
    </>
  }


  return (
    <div className="siteBg listingPage  detailPageWrap">
      <div className="pageWrap ">
        <div className="fixedHeader">
          {!hideHeader && <HeaderNavbar hideFunc={hideFunc} useBlueIcon={true}/>}
        </div>
        <section className="mainContent">
          {!hideFilter && (
            <HomeWidget
              toggleFilterDrop={toggleFilterDrop}
              isFromDetailPage={true}
            />
          )}

          {hotelDetails && (
            <Scrollbars
              ref={scrollRef}
              autoHeightMin={120}
              autoHeightMax={`calc(100%)`}
              className="pageInternalScroll"
            >
              <div className="container">
                <HotelDetailCard
                  {...hotelDetailMockData}
                  scrollToView={scrollToView}
                  name={hotelDetails ? hotelDetails?.hotel_data?.name : ""}
                  images={
                    hotelDetails && hotelDetails.static_data
                      ? hotelDetails?.static_data?.images
                      : []
                  }
                  heroImage={
                    hotelDetails && hotelDetails?.hotel_data?.images
                      ? hotelDetails.hotel_data?.images[0]
                      : ""
                  }
                  checkIn={
                    hotelDetails
                      ? 
                      isUS ? hotelDetails?.hotel_data?.check_in_time_format1 :(hotelDetails?.hotel_data?.check_in_time_format1 ? convertTime12To24(hotelDetails?.hotel_data?.check_in_time_format1) : "--")
                      : "" 
                  }
                  checkOut={
                    hotelDetails
                      ? isUS
                        ? hotelDetails?.hotel_data?.check_out_time_format1 :(hotelDetails?.hotel_data?.check_out_time_format1 ? convertTime12To24(hotelDetails?.hotel_data?.check_out_time_format1) : "--")
                      : ""
                  }
                  address={hotelDetails ? hotelDetails?.hotel_data?.address : ""}
                  addressObj={
                    hotelDetails
                      ? hotelDetails?.hotel_data?.address_components
                      : {}
                  }
                  contactNo={hotelDetails ? hotelDetails?.hotel_data?.phone : ""}
                  themes={
                    hotelDetails && hotelDetails?.hotel_data?.themes
                      ? hotelDetails?.hotel_data?.themes
                      : []
                  }
                  rating={hotelDetails ? hotelDetails?.hotel_data?.rating : 0}
                  latitude={
                    hotelDetails && hotelDetails?.hotel_data?.latitude
                      ? hotelDetails?.hotel_data?.latitude
                      : 0
                  }
                  longitude={
                    hotelDetails && hotelDetails?.hotel_data?.longitude
                      ? hotelDetails?.hotel_data?.longitude
                      : 0
                  }
                  currencySymbol={
                    hotelDetails &&
                    hotelDetails?.currency &&
                    hotelDetails?.currency?.symbol_native
                  }
                  currencyCode={
                    hotelDetails &&
                    hotelDetails?.currency &&
                    hotelDetails?.currency?.code
                  }
                />
                <div className="overviewTitle sectionTitle desktopViewDisplayNone">
                  <h2>Overview</h2>
                </div>
                <div className="tabsWrapper mobileViewDisplayNone">
                  <HotelDetailsTabs
                    activeTab={activeTab}
                    scrollToView={scrollToView}
                    hasReviews={hotelDetails?.reviews?.review_count != 0}
                  />
                </div>
                <Overview
                  reviews={hotelDetails ? hotelDetails?.reviews : {}}
                  scrollToView={scrollToView}
                  amenities={
                    hotelDetails ? hotelDetails?.static_data?.top_amenities : []
                  }
                />
                <Rooms
                  Countdown={<Countdown />}
                  CountDownForMobile={<CountDownForMobile />}
                  CountdownStatus={CountdownStatus}
                  hotel_id={params?.hotel_id}
                  currencySymbol={
                    hotelDetails &&
                    hotelDetails?.currency &&
                    hotelDetails?.currency?.symbol_native
                  }
                  currencyCode={
                    hotelDetails &&
                    hotelDetails?.currency &&
                    hotelDetails?.currency?.code
                  }
                />
                <Amenities
                  amenities={
                    hotelDetails && hotelDetails?.static_data?.facilities
                      ? hotelDetails.static_data?.facilities
                      : []
                  }
                />
                <PropertiesDetails
                  description={
                    hotelDetails && hotelDetails?.static_data?.description
                      ? hotelDetails.static_data?.description
                      : "No Description..."
                  }
                  nearBy={
                    hotelDetails && hotelDetails?.static_data?.attractions
                      ? hotelDetails.static_data?.attractions
                      : "No NearBy..."
                  }
                />

                {hotelDetails?.reviews?.review_count != 0 && (
                  <Reviews
                    {...hotelDetailMockData.reviews}
                    reviews={hotelDetails ? hotelDetails?.reviews : {}}
                  />
                )}
              </div>
            </Scrollbars>
          )}
          {!hotelDetails && (
            <Scrollbars
              // autoHeight={`calc(100vh - 110px)`}
              // autoHeight={true}
              autoHeightMin={120}
              autoHeightMax={`calc(100%)`}
              className="pageInternalScroll"
            >
              <div className="container not-found-container">
                <div className="not-found-content">
                  <div className="not-found-content-wrap text-center">
                    <h2>Sold out.</h2>
                    <p>
                     Please try again!
                    </p>
                    <NavLink className="btn" to={`/home-search-result/`}>
                      Search Again
                    </NavLink>
                  </div>
                </div>
              </div>
            </Scrollbars>
          )}
        </section>
        <FooterWithMediaIcon
          className="white-icon"
          iconClassName="icon-white"
        />
      </div>
    </div>
  );
}

export default HotelDetail;
