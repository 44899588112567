import { useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { ssnMasking, einMasking } from "../../../Util/util";
import InputField from "../../../Component/FormComponent/InputField";

const SSNNumberModal = ({ formikProps, closeHandler }) => {
  const { values, errors, touched, handleBlur } = formikProps;
  const [currentTab, setCurrentTab] = useState(1);
  const handleCustomChange = (field, value, formikProps) => {
    if (field === "ssnNumber" || field === "confirmSsnNumber") {
      formikProps.setFieldValue(field, ssnMasking(value));
      return;
    } else if (field === "einNumber" || field === "confirmEinNumber") {
      formikProps.setFieldValue(field, einMasking(value));
      return;
    }
    formikProps.setFieldValue(field, value);
  }
  return (
    <div className="popup ssn-collection-popup popup-open">
      <div className="popup-content">
        <div className="popup-title">
          <h2>
            For TAX reporting purposes all US based Affiliates are by law required to provide a valid Social Security or EIN Number and valid Telephone Number at Sign Up*.
          </h2>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              closeHandler(formikProps, true)
            }}
          >
            <i className="icon-close"></i>
          </button>
        </div>
        <div className="content-box">
          <Tabs
            className="flight-tab-wrap"
            defaultIndex={0}
            id="flightsTab2"
          >
            <div
              id="selectFlightOptions"
              className={`react-tabs__tab-list open`}
            >
              <TabList>
                <Tab>
                  <button
                    type="button"
                    className={`react-tabs__tab ${currentTab === 1 ? "react-tabs__tab--selected" : ""
                      }`}
                    onClick={() => setCurrentTab(1)}
                  >
                    Social Security
                  </button>
                </Tab>

                <Tab>
                  <button
                    type="button"
                    className={`react-tabs__tab ${currentTab === 2 ? "react-tabs__tab--selected" : ""
                      }`}
                    onClick={() => setCurrentTab(2)}
                  >
                    EIN
                  </button>
                </Tab>
              </TabList>
            </div>

            <div className="tab-content">
              <TabPanel>
                <div className="BSrow gutter15">
                  <InputField
                    className="col-12 inputStyle inputStyle2"
                    placeholder="Social Security Number"
                    name="ssnNumber"
                    value={values.ssnNumber}
                    error={errors.ssnNumber}
                    touched={touched.ssnNumber}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleCustomChange("ssnNumber", e.target.value, formikProps)
                    }}
                  />

                  <InputField
                    className="col-12 inputStyle inputStyle2"
                    placeholder="Confirm Social Security Number"
                    name="confirmSsnNumber"
                    value={values.confirmSsnNumber}
                    error={errors.confirmSsnNumber}
                    touched={touched.confirmSsnNumber}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleCustomChange("confirmSsnNumber", e.target.value, formikProps)
                    }}
                  />
                </div>

                <div className="submit-btn">
                  <p>
                    * Invalid information at Sign Up will result in
                    suspension of the Affiliate account
                  </p>
                  <button
                    type="button" className="btn"
                    onClick={() => {
                      closeHandler(formikProps, false)
                    }}
                  >
                    Submit
                  </button>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="BSrow gutter15">
                  <InputField
                    className="col-12 inputStyle inputStyle2"
                    placeholder="EIN Number"
                    name="einNumber"
                    value={values.einNumber}
                    error={errors.einNumber}
                    touched={touched.einNumber}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleCustomChange("einNumber", e.target.value, formikProps)
                    }}
                  />

                  <InputField
                    className="col-12 inputStyle inputStyle2"
                    placeholder="Confirm EIN Number"
                    name="confirmEinNumber"
                    value={values.confirmEinNumber}
                    error={errors.confirmEinNumber}
                    touched={touched.confirmEinNumber}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleCustomChange("confirmEinNumber", e.target.value, formikProps)
                    }}
                  />
                </div>

                <div className="submit-btn">
                  <p>
                    * Invalid information at Sign Up will result in
                    suspension of the Affiliate account
                  </p>
                  <button type="button" className="btn"
                    onClick={() => {
                      closeHandler(formikProps, false)
                    }}
                  >
                    Submit
                  </button>
                </div>
              </TabPanel>
            </div>
          </Tabs>
        </div>
        {/* <Formik
          initialValues={{
            ssnNumber: "",
            confirmSsnNumber: "",
            einNumber: "",
            confirmEinNumber: "",
          }}
          validate={(values) => {
            const errors = {};

            if (currentTab === 1) {
              if (!values.ssnNumber) {
                errors.ssnNumber = "Required";
              } else if (String(values.ssnNumber).length !== 10) {
                errors.ssnNumber = "Invalid";
              }

              if (!values.confirmSsnNumber) {
                errors.confirmSsnNumber = "Required";
              } else if (values.ssnNumber !== values.confirmSsnNumber) {
                errors.confirmSsnNumber = "Do not match with.";
              }
            } else {
              if (!values.einNumber) {
                errors.einNumber = "Required";
              } else if (String(values.einNumber).length !== 9) {
                errors.einNumber = "Invalid";
              }

              if (!values.confirmEinNumber) {
                errors.confirmEinNumber = "Required";
              } else if (values.einNumber !== values.confirmEinNumber) {
                errors.confirmEinNumber = "Do not match with.";
              }
            }
            return errors;
          }}
          onSubmit={(values) => {
            getSSNModalValues(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                
              </form>
            );
          }}
        </Formik> */}
      </div>
    </div>
  );
};

export default SSNNumberModal;
