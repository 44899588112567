/* eslint-disable quotes */
import moment from "moment";

const serialize = (obj) => {
  const str = [];
  for (const p in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  }

  return str.join("&");
};

const ssnMasking = (value) => {
  let currentValue = value.replace(/[^\d]/g, "").slice(0, 9);
  const cvLength = currentValue.length;
  if (cvLength < 4) return `${currentValue}`;
  if (cvLength < 6)
    return `${currentValue.slice(0, 3)}-${currentValue.slice(3, 5)}`;
  if (cvLength >= 6)
    return `${currentValue.slice(0, 3)}-${currentValue.slice(
      3,
      5
    )}-${currentValue.slice(5)}`;
};

function numFormatter(num) {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
  } else if (num > 1000000) {
    return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
  } else if (num <= 999) {
    return num; // if value < 1000, nothing to do
  }
}
function truncateString(string, length) {
  if (string.length > length) {
    return string.substring(0, length - 3) + "...";
  } else {
    return string;
  }
}
function getLocationParams() {
  var locationURL = decodeURIComponent(window.location.search);
  if (locationURL !== "") {
    return JSON.parse(
      '{"' +
        decodeURI(
          locationURL.replace("?", "").replace(/&/g, '","').replace(/=/g, '":"')
        ) +
        '"}'
    );
  }
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return false;
}

const handleMaskedInput = (value) => {
  var currVal = value.replace("/", "");

  if (/^[0-9]*$/i.test(currVal)) {
    const cvLength = currVal.length;
    if (Number(currVal.slice(0, 2)) > 12) {
      return { value: "", error: false };
    }
    if (cvLength < 3) {
      return { value: currVal, error: false };
    }
    if (cvLength === 4 && Number(currVal.slice(2, 4)) < moment().format("YY")) {
      return { value: currVal.slice(0, 2) + "/", error: false };
    }
    return {
      value: currVal.slice(0, 2) + "/" + currVal.slice(2),
      error: false,
    };
  }
};

function commafy(num) {
  num = parseFloat(num).toFixed(2);
  var str = num.toString().split(".");
  if (str[0].length >= 4) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
  }
  if (str[1] && str[1].length >= 4) {
    str[1] = str[1].replace(/(\d{3})/g, "$1 ");
  }
  return str.join(".");
}

function get_age(time) {
  if (time) {
    let date_array = time.split("-");
    let years_elapsed =
      (new Date() - new Date(date_array[0], date_array[1], date_array[2])) /
      (1000 * 60 * 60 * 24 * 365);
    return years_elapsed;
  } else {
    return null;
  }
}

const debounce = (func, delay) => {
  let debounceTimer;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

const timeConvert = (num) => {
  let hours = num / 60;
  let rhours = Math.floor(hours);
  let minutes = (hours - rhours) * 60;
  let rminutes = Math.round(minutes);
  return rhours + "h " + rminutes + "m";
};

const flightTripType = (value) => {
  if (value === "Roundtrip") {
    return "Round Trip";
  } else if (value === "Oneway") {
    return "Oneway Trip";
  } else if (value === "MultiCity") {
    return "MultiCity Trip";
  }
};

const cabinClassOptions = [
  { label: "Economy", value: 0, classValue: "Y", key: "economy" },
  {
    label: "Premium Economy",
    value: 1,
    classValue: "S",
    key: "premiumEconomy",
  },
  { label: "Business", value: 2, classValue: "C", key: "business" },
  // { label: "Premium Business", value: 3, classValue: "J" }
  { label: "First Class", value: 4, classValue: "F", key: "first" },
  // { label: "Premium First", value: 5, classValue: "P" },
];

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const convertTime12To24 = (time) => {
  if (time === "24-hour check-in") {
    return null;
  }
  let hours = Number(time.match(/^(\d+)/)[1]);
  let minutes = Number(time.match(/:(\d+)/)[1]);
  let AMPM = time.match(/\s(.*)$/)[1];

  if (["PM", "pm"].includes(AMPM) && hours < 12) hours = hours + 12;
  if (["AM", "am"].includes(AMPM) && hours === 12) hours = hours - 12;
  let sHours = hours.toString();
  let sMinutes = minutes.toString();
  if (hours < 10) sHours = "0" + sHours;
  if (minutes < 10) sMinutes = "0" + sMinutes;
  return sHours + ":" + sMinutes;
};

const convertTime24To12 = (time) => {
  if (time === "24-hour check-in") {
    return null;
  }
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    time = time.slice(1);
    time[5] = +time[0] < 12 ? " AM" : " PM";
    time[0] = +time[0] % 12 || 12;
  }
  return time.join("");
};

function openInNewTab(href) {
  Object.assign(document.createElement("a"), {
    target: "_blank",
    rel: "noopener noreferrer",
    href: href,
  }).click();
}

function httpGetAsync(imgUrl, callback) {
  var img = new Image();
  img.onerror = function () {
    callback(false);
  };
  img.onabort = function () {
    {
      callback(false);
    }
  };
  img.onload = function () {
    {
      callback(true);
    }
  };
  img.src = imgUrl;
}

const einMasking = (value) => {
  let currentValue = value.replace(/[^\d]/g, "").slice(0, 10);
  const cvLength = currentValue.length;

  if (cvLength < 3) return `${currentValue}`;
  if (cvLength >= 3)
    return `${currentValue.slice(0, 2)}-${currentValue.slice(2)}`;
};

const getPassesLink = (userType, passType) => {
  if (passType?.length) {
    let userObject = JSON.parse(localStorage.getItem("user"));
    let passLink = userObject.wallet_pass.filter(
      (ele) => ele.membership_type === userType && passType === ele.type
    )?.[0]?.link;
    return passLink;
  } else {
    return "";
  }
};

///
// Get hours and minutes from a specified Date
export function padTo2Digits(num) {
  return String(num).padStart(2, "0");
}

export const getHoursAndMin = (item) => {
  const date = new Date(item);
  const departureDate =
    padTo2Digits(date.getHours()) + ":" + padTo2Digits(date.getMinutes());
  return departureDate;
};

export const isEmptyValue = (value, valueType) => {
  switch (valueType) {
    case "number": {
      if (typeof value !== "number" || !value) {
        return true;
      }
      break;
    }
    case "string": {
      if (typeof value !== "string" || !value) {
        return true;
      }
      break;
    }
    case "array": {
      if (typeof value !== "object" || !value || !(value?.length > 0)) {
        return true;
      }
      break;
    }
    case "object": {
      if (
        typeof value !== "object" ||
        !value ||
        Object.keys(value)?.length === 0
      ) {
        return true;
      }
      break;
    }
  }
};

export const getLogedInUser = () => {
  const isFreemium =
    process.env.REACT_APP_FREMIUM_USER_TYPE_ID ==
    JSON.parse(localStorage?.user)?.type_id;
  const isPremium =
    process.env.REACT_APP_PREMIUM_USER_TYPE_ID ==
    JSON.parse(localStorage?.user)?.type_id;
  const isPremiumPlus =
    process.env.REACT_APP_PREMIUM_PLUS_USER_TYPE_ID ==
    JSON.parse(localStorage?.user)?.type_id;
  let user = {
    // isPaidUser: isPremium | isPremiumPlus,
    isPaidUser: false,
    isFreemiumUser: true,
    isPremiumUser: false,
    // isPremiumUser: isPremium,
    isPremiumPlusUser: isPremiumPlus,
  };
  return user;
};

export const getCurrencyAndLanguage = () => {
  const currencyAndLanguage = JSON.parse(
    window.localStorage.getItem("currencyAndLanguage") || "{}"
  );
  const data = currencyAndLanguage.currencyLanguageReducer.data;
  return {
    currency: data.currency.isoCode,
    currencySymbol: data.currency.symbol,
    language: {
      country: data.language.country,
      code: data.language.langCode,
    },
  };
};

export function isEmptyObject(obj) {
  return obj ? Object.keys(obj).length === 0 : true;
}

export {
  serialize,
  getPassesLink,
  getLocationParams,
  getCookie,
  handleMaskedInput,
  commafy,
  debounce,
  timeConvert,
  flightTripType,
  cabinClassOptions,
  get_age,
  getBase64,
  convertTime12To24,
  convertTime24To12,
  numFormatter,
  truncateString,
  openInNewTab,
  httpGetAsync,
  ssnMasking,
  einMasking,
};

export const keyTabNameMapMyTrip = {
  0: "upcoming",
  2: "cancelled",
  1: "completed",
  4: "failed",
  3: "pending",
};

export const startsWithURL = (str) => {
  const urlPattern = /^(https?:\/\/[^\s/$.?#].[^\s]*)$/i;
  return urlPattern.test(str);
};
