import React, { useState } from "react";
import Button from "../FormComponent/Button";
import RestaurantImg from "../../Assets/img/restaurant.png";
import {
  MdOutlineLocalLaundryService,
  MdOutlinePool,
  MdOutlineRestaurantMenu,
  MdOutlineSpa,
  MdOutlineSportsBar,
} from "react-icons/md";
import { TbCoffee, TbParking } from "react-icons/tb";
import { HiWifi } from "react-icons/hi";
import { CgGym } from "react-icons/cg";
import { FaHospitalUser, FaHotTub } from "react-icons/fa";

function Amenities({ amenities, scrollToView }) {
  const [showMoreAmenities, setshowMoreAmenities] = useState(false);

  const amenitie = [
    { image: <MdOutlineSportsBar />, id: 2 },
    { image: <TbCoffee />, id: 4 },
    { image: <FaHospitalUser />, id: 7 },
    { image: <HiWifi />, id: 8 },
    { image: <CgGym />, id: 9 },
    { image: <FaHotTub />, id: 10 },
    { image: <MdOutlineLocalLaundryService />, id: 12 },
    { image: <TbParking />, id: 13 },
    { image: <MdOutlinePool />, id: 15 },
    { image: <MdOutlineRestaurantMenu />, id: 16 },
    { image: <MdOutlineSpa />, id: 18 },
  ];
  return (
    <>
      <ul>
        {amenities &&
          amenities.map((amenity, index) => {
            return (
              <li key={`${index}_ameneties`}>
                <div className="images">
                  {amenitie.find((ele) => ele.id === amenity.id)?.image || (
                    <FaHospitalUser />
                  )}
                </div>
                <h4>{amenity.label}</h4>
              </li>
            );
          })}
      </ul>
      <div className="showMoreLink show-all-amenities">
        {/* <Button onClick={() => setshowMoreAmenities(!showMoreAmenities)}>
          Show All Amenities {" "}
          <i className={`fa fa-angle-${showMoreAmenities ? "up" : "down"}`}></i>
        </Button> */}
        <Button onClick={() => scrollToView("amenities")}>
          {showMoreAmenities ? "Show Less Amenities  " : "Show All Amenities "}
          <i className={`fa fa-angle-${showMoreAmenities ? "up" : "down"}`}></i>
        </Button>
      </div>
    </>
  );
}

export default Amenities;
