import React, { useEffect } from "react";
import Modal from "react-modal";
import infoGreyIcon from "../../Assets/img/info-grey-icon.png";
import closeIcon from "../../Assets/img/close-blue-icon.png";
import { useSelector } from "react-redux";

function WalletDetailModal({ openTableModal, closeTableModal }) {
  const transactionData = useSelector(
    (state) => state.marketingSiteReducer?.transactionData
  );

  return (
    <Modal
      isOpen={openTableModal}
      className="popup wallet-info-popup popup-open"
      ariaHideApp={false}
    >
      <div className="popup-content ">
        <div className="popup-title">
          <h3>
            <img src={infoGreyIcon} alt="icon" />
            Transaction Details
          </h3>
          <button className="btn close" onClick={closeTableModal}>
            <img src={closeIcon} alt="icon" />
          </button>
        </div>
        <div className="box-content">
          <div className="row">
            <div className="col left-content">
              <h5> Freemium Member Travel </h5>
            </div>
            <div className="col right-content">
              <h5 className="green-text">
                {" "}
                ${transactionData?.data?.freemium_member_travel}{" "}
              </h5>
            </div>
          </div>
          <div className="row">
            <div className="col left-content">
              <h5> Member to Member Referrals </h5>
            </div>
            <div className="col right-content">
              <h5 className="green-text">
                {" "}
                ${transactionData?.data?.member_to_member_commission}
              </h5>
            </div>
          </div>
          <div className="row">
            <div className="col left-content">
              <h5>Premium Member Sales </h5>
            </div>
            <div className="col right-content">
              <h5 className="green-text">
                {" "}
                ${transactionData?.data?.premium_member_commission}
              </h5>
            </div>
          </div>
          <div className="row">
            <div className="col left-content">
              <h5>Premium Plus Member Sales</h5>
            </div>
            <div className="col right-content">
              <h5 className="green-text">
                {" "}
                ${transactionData?.data?.premium_plus_member_commission}
              </h5>
            </div>
          </div>
          <div className="row">
            <div className="col left-content">
              <h5>Affiliate Member Sales</h5>
            </div>
            <div className="col right-content">
              <h5 className="green-text">
                {" "}
                ${transactionData?.data?.affilliate_member_commission}
              </h5>
            </div>
          </div>

          <div className="total-amount">
            <h5 className="green-text">
              <strong>Total:</strong>
              ${transactionData?.data?.total_commission}
            </h5>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default WalletDetailModal;