import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";

import HeaderNavbar from "../../Component/HeaderNavbar";
import Card from "../../Component/Card";
import planeImage from "../../Assets/img/flightImage.png";
import hotelImage from "../../Assets/img/cardImg2.png";
import carImage from "../../Assets/img/cardImg3.png";
import placeImage from "../../Assets/img/cardImg4.png";
import videoBg from "../../Assets/img/videoBg.png";
import playButton from "../../Assets/img/network/play-button.png";
import featuredOnImg from "../../Assets/img/home/featured-on-white.png";
import Button from "../../Component/FormComponent/Button";
import PlanInfo from "../../Component/PlanInfo";
import plusIcon from "../../Assets/img/membership-upgrade/plus-icon.png";
import AccordianTwo from "../../Component/AccordianTwo";
import UpgradeNowWrapper from "../../Component/UpgradeNowWrapper";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import { faqs } from "../../main/CustomConstants/Home";
import featuredOnWhite from "../../Assets/img/network/play-button.png";
import VideoModal from "../../Component/VideoModal";
import videoSrc from "../../Assets/video/premium-plus-sign-up/Premium-Invite-Landing-Page-V2.mp4";
import Scrollbars from "react-custom-scrollbars";
import { ReactComponent as RightArrowWhite } from "../../Assets/img/home/right-arrow-white.svg";
function PremiumPlusSignUp() {
  // const [showAllaccordion, setshowAll] = useState(false);
  const scrollRef = useRef();
  const queryString = window.location.href.split("?")[1];
  const [openModal, setOpenModal] = useState(false);

  // state for expanding accordian
  const [isExpandAccordian, setIsExpandAccoridan] = useState(false);
  const ScrollIntoCardRef = useRef(null);

  // const ScrollIntoCard = () => {
  //   ScrollIntoCardRef.current.scrollIntoView({
  //     behavior: "smooth",
  //     block: "start",
  //   });
  // };


  const ScrollIntoCard = () => {
    const offsetTop = document.querySelector(".choose-plan").offsetTop - 110;
    if (window.outerWidth > 991) {
      scrollRef.current.view.scroll({
        top: offsetTop,
        behavior: 'smooth',
      })
    } else {
      window.scrollTo({
        top: offsetTop,
        behavior: 'smooth',
      })
    } 
  }
  // state for open and close accordian list
  const [openedAccordian, setOpenedAccordian] = useState(0);

  const handleOpenClose = (id) => {
    if (id === openedAccordian) {
      setOpenedAccordian(null);
    } else {
      setOpenedAccordian(id);
    }
  };

  const handleOpenCloseVideoModal = () => {
    if (!openModal) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  };

  const getFaqs = (faqList) => {
    let accordinNodes = [];

    // Accordian list

    faqList.forEach((faq, index) => {
      //render the whole list if true
      if (isExpandAccordian) {
        accordinNodes.push(
          <AccordianTwo
            openedAccordian={openedAccordian}
            handleOpenClose={handleOpenClose}
            id={index}
            key={faq.title + index}
            title={faq.title}
            content={faq.description}
            className={`card-content`}
          />
        );
      } else {
        // render only limited list
        if (index < 8) {
          accordinNodes.push(
            <AccordianTwo
              openedAccordian={openedAccordian}
              handleOpenClose={handleOpenClose}
              id={index}
              key={faq.title + index}
              title={faq.title}
              content={faq.description}
              className={`card-content`}
            />
          );
        }
      }
    });

    return accordinNodes;
  };

  return (
    <>
      <div className="siteBg MembershipUpgradePageWrap premiumPlusSignUpPageWrap">
        <div className="pageWrap pageWrapInner">
          <div className="fixedHeader">
            <HeaderNavbar noHeader={true}/>
          </div>
          <section className="mainContent">
          <Scrollbars
              ref={scrollRef}
              autoHeight={true}
              autoHeightMin={120}
              autoHeightMax={`calc(100%)`}
              className="pageInternalScroll changeHeightPageInternalScroll"
            >
              <div className="container  pageInternalScrollTopBottomPadding ">
                <UpgradeNowWrapper
                  heading="Welcome,"
                  className="upgrade-now-wrapper"
                  ScrollIntoCard={ScrollIntoCard}
                  isScrollInternal={true}
                  para1={
                    <>
                      You have been invited to join a <strong>Unique</strong>{" "}
                      and <strong>New</strong> form of{" "}
                      <strong>Booking Travel.</strong> <br />
                      The <strong>first</strong> Platform to offer
                      <strong> Dynamic, True Wholesale/Net Rates</strong> in
                      Real Time.
                    </>
                  }
                  para2={
                    <>
                      No Mark-Ups, No Commissions, No Booking Fees -
                      <strong>
                        {" "}
                        Ever.
                        <br />
                      </strong>{" "}
                      Wholesale/Net Rates Directly To You.{" "}
                    </>
                  }
                  para3={
                    <>
                      More Savings, More Freedom, More Choices,{" "}
                      <strong> Better Experiences. </strong>{" "}
                    </>
                  }
                />
                <div className="grid-layout-wrap">
                  <div className="section-title">
                    <h3>
                      Get <strong>Full Access</strong> to <strong>ALL</strong>{" "}
                      Savings
                    </h3>
                    <h2>Save up to 65% and more on…</h2>
                  </div>
                  <div className="grid-layout row">
                    <Card
                      title={
                        <h6>
                          <Link to="/home">Global Flights</Link>
                        </h6>
                      }
                      description="Big savings on Flights, especially in premium cabins, from all Global Carries and over140 low-cost carriers from around the world."
                      image={planeImage}
                      colClassName="col grid-item"
                      styleClassName="infobox"
                      imgClassName="image-box"
                      textClassName="grid-content"
                      backgroundImage={true}
                    />
                    <Card
                      title={
                        <h6>
                          <Link to="/home">Hotels & Resorts</Link>
                        </h6>
                      }
                      description="Huge savings on over 1.5 million Hotels and Resorts worldwide, Including the most popular destinations."
                      image={hotelImage}
                      colClassName="col grid-item"
                      styleClassName="infobox"
                      imgClassName="image-box"
                      textClassName="grid-content"
                      backgroundImage={true}
                    />
                    <Card
                      title={
                        <h6>
                          <Link to="/home">Rental Cars & Services</Link>
                        </h6>
                      }
                      description="Great Deals on Rental Cars by the best and most trusted brands from around the world."
                      image={carImage}
                      colClassName="col grid-item"
                      styleClassName="infobox"
                      imgClassName="image-box"
                      textClassName="grid-content"
                      backgroundImage={true}
                    />
                    <Card
                      title={
                        <h6>
                          <Link to="/home">Vacation Homes</Link>
                        </h6>
                      }
                      description="More than 1.5 Million bookable Vacation rentals incl. Condos, Cabins, Villas, Bungalows, Beach Houses and more."
                      image={placeImage}
                      colClassName="col grid-item"
                      styleClassName="infobox"
                      imgClassName="image-box"
                      textClassName="grid-content"
                      backgroundImage={true}
                    />
                  </div>
                </div>

                <div className="full-video-main-wrapper">
                  <div className="full-video-wrapper  centerAlign">
                    <div className="gradiantBg videoSpace sideSpace centerAlign tripello-magic-wrapper">
                      <div className="section-title">
                        <h2>How Much Can You Save?</h2>
                        <h3>Let us show you the Math</h3>
                      </div>
                      <div className="cardStyle">
                        <div className="video-box">
                          <div className="videoPremiumPlus">
                            <div className="desktop-video">
                              <Button
                                type="button"
                                className="video-play-btn"
                                imageSrc={playButton}
                                onClick={handleOpenCloseVideoModal}
                              />
                            </div>
                            <div className="mobile-video">
                              <Button
                                type="button"
                                className="video-play-btn"
                                imageSrc={playButton}
                                onClick={handleOpenCloseVideoModal}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="video-box-bottom-content">
                      <div className="video-box-content">
                        <h3>
                          You can also test our platform for FREE. NO
                          Obligation, NO Risk, NO Credit Card required. Sign Up
                          for our FREEMIUM Membership{" "}
                          <a href={`freemium-signup?${queryString}`}>HERE.</a>
                        </h3>
                      </div>
                    </div>
                    <div className="featured-on-icons">
                      <img src={featuredOnImg} alt="image" />
                    </div>
                  </div>
                </div>

                <div className="plan-wrapper">
                  <div className="section-title">
                    <h2>You Choose Which One Is Right For You</h2>
                  </div>
                  <span ref={ScrollIntoCardRef}></span>
                  <div className="choose-plan">
                    <div className="row">
                      <div className="col plan-item">
                        <PlanInfo
                          planName="Premium Membership"
                          months="(6 Month)"
                          arrayList={[
                            "Credit/Debit Card Required*",
                            "Unlimited Platform Access",
                            "Savings of up to 65% and more",
                            "Access to 1.5 million Hotels and Resorts",
                            "Access to 1.5 million Vacation Homes",
                            "Access to Rental Cars and upcoming Chauffeur Service",
                            "Access to Flight Inventory of all Global Carriers and 140 Low Cost Carriers",
                          ]}
                          heading2={
                            <>
                              $32.50 <span>/ per month</span>
                            </>
                          }
                          heading3="($195.00 total at checkout*)"
                          para="*Credit/Debit Card required for Sign Up and
                            Bookings."
                          link={`membership-premium-checkout?${queryString}`}
                          text="Choose Plan"
                          btnArrow={<RightArrowWhite />}
                        />
                      </div>
                      <div className="col plan-item">
                        <PlanInfo
                          planName="Premium Plus Membership"
                          months="(12 Month)"
                          image={
                            <>
                              {" "}
                              <img src={plusIcon} alt="icon" />
                            </>
                          }
                          arrayList={[
                            "Credit/Debit Card Required*",
                            "Unlimited Platform Access",
                            "Savings of up to 65% and more",
                            "Access to 1.5 million Hotels and Resorts",
                            "Access to 1.5 million Vacation Homes",
                            "Access to Rental Cars and upcoming Chauffeur Service",
                            "Access to Flight Inventory of all Global Carriers and 140 Low Cost Carriers",
                            <>
                              Access to Member Referral Rewards Program <br />•
                              $25 for each Premium Member
                              <br />• $50 for each Premium Plus Member
                            </>,
                          ]}
                          heading2={
                            <>
                              $24.95 <span>/ per month</span>
                            </>
                          }
                          heading3="($299.40 total at checkout*)"
                          para="*Credit/Debit Card required for Sign Up and
                            Bookings."
                          link={`membership-premium-plus-checkout?${queryString}`}
                          text="Choose Plan"
                          btnArrow={<RightArrowWhite />}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="faq-wrapper">
                  <div className="section-title">
                    <h2>Here are Some Answers to Common Questions</h2>
                  </div>
                  <div className="accordion">
                    <div className="toggle-more-accordions">
                      {getFaqs(faqs)}
                    </div>
                  </div>

                  {/* more and less button below accordian */}

                  {faqs.length > 5 && (
                    <div className="read-more-btn-wrap">
                      {!isExpandAccordian ? (
                        <button
                          onClick={() => {
                            setIsExpandAccoridan(true);
                          }}
                          className="read-more-btn"
                          type="button"
                        >
                          more...
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setIsExpandAccoridan(false);
                          }}
                          className="read-more-btn"
                          type="button"
                        >
                          less...
                        </button>
                      )}
                    </div>
                  )}
                </div>
                <UpgradeNowWrapper
                  ScrollIntoCard={ScrollIntoCard}
                  isScrollInternal={true}
                  className="upgrade-now-wrapper start-saving-wrapper"
                  heading="Start Saving Today"
                  para1="Become a Tripello Premium Member and enjoy unlimited access to unparalled rates on Flights, Hotels, Cars and Vacation Homes."
                />
              </div>
            </Scrollbars>
          </section>

          <FooterWithMediaIcon className="white-icon" />
        </div>
      </div>
      {openModal && (
        <VideoModal
          openModal={openModal}
          closeModal={handleOpenCloseVideoModal}
          videoSrc={videoSrc}
        />
      )}
    </>
  );
}

export default PremiumPlusSignUp;
