export const AffiliateInvitesCommissionList = [
	{
		title: 'Total Commission',
		description: 'Amount of all earned commissions combined to date. Total Amount Spent at Tripello Amount that an Affiliate has spent from his earned commissions on the Tripello platform itself. (Buying Travel, Affiliate Renewal, etc.).',
	},
	{
		title: 'Total Commission Paid Out',
		description: 'Amount of all Commissions paid out to the Affiliate.',
	},
	{
		title: 'Total Commission Pending',
		description: 'This is the amount of pending Commissions due to Members having booked but not travelled and/or payout dates have not been reached.',
	},
	{
		title: 'Total Freemium Member Sign-Up',
		description: 'Amount of all Freemium Members signed up under an Affiliate’s Freemium Invitation link.',
	},
	{
		title: 'Total Premium Member Sign-Up',
		description: 'Amount of all Freemium Members signed up under an Affiliate’s Freemium Invitation link.',
	},
	{
		title: 'Freemium to Premium Conversion %',
		description: 'Amount in % of all Freemium Members that and Affiliate has signed up and that have upgrade to Premium Member. This does not include the direct sign up of Premium Members.',
	},
	{
		title: 'Total Affiliate Sign-Up',
		description: 'Amount of all Affiliates signed up under an Affiliate’s Affiliate Invitation link.',
	},
	{
		title2: 'Commissions',
	},
	{
		title: 'Freemium Member Travel',
		description: 'All commissions earned from the amount the Freemium Member spent on Travel.',
	},
	{
		title: 'Member to Member Referrals',
		description: 'All commissions earner from Member to Member referrals.',
	},
	{
		title: 'Premium Member Sales',
		description: 'All commissions earned from signing up Premium Members',
	},
	{
		title: 'Premium Plus Member Sales',
		description: 'All commissions earned from signing up Premium Plus Members',
	},
	{
		title: 'Affiliate Sales',
		description: 'All commissions earned from signing up Affiliates',
	},
];
