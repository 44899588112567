import React, { useState, useEffect } from "react";
import InputField from "./FormComponent/InputField";
import SimpleInputField from "./FormComponent/SimpleInputField";
import Checkbox from "./FormComponent/Checkbox";
import { handleMaskedInput } from "../Util/util";
import moment from "moment";

// Billing Details Section
function BillingDetails(props) {
  const {

    formikProps,
    cardHeadingLabel,
    numberCustomChange,

    cardHolderName,
    cardHolderNameLabel,
    cardHolderNamePlaceholder,

    cardImage,
    credit_number,
    cardNumberPlaceholder,

    mmyy,
    mmyyPlaceholder,

    security_code,
    cvvPlaceholder,

    memberNumber,
    memberNumberLabel,

    billingDetails,
    checkedBoxValue,
    handleCheckbox,

    walletAmount,
    walletAmountLabel,
    walletAmountPlaceholder,

    amountAdded,
    amountAddedLabel,
    amountAddedPlaceholder,

    remainingAmount,
    remainingAmountLabel,
    remainingAmountPlaceholder,
    styleValue,
    isMemberUpgradePage


  } = props;

  const { values, errors, touched, handleChange, handleBlur } = formikProps;

  const [mmyyError, setMmyyError] = useState(false)

  // useEffect(() => {
  //  if(mmyyError){
  //   formikProps.setErrors({ mmyy: "Invalid YY" });
  //  }
  // }, [mmyyError])


  return (
    <>
      <div className="sectionTitle">
        <h2>Billing Details</h2>
      </div>

      <div className="row">
        <InputField
          name={cardHolderName}
          type="text"
          label={cardHolderNameLabel}
          placeholder={cardHolderNamePlaceholder}
          value={values.cardHolderName}
          error={errors.cardHolderName}
          // cardError={errors.cardHolderName}
          touched={touched.cardHolderName}
          onChange={handleChange}
          onBlur={handleBlur}
          className="cardHolder"
        />
        <div className="col formGroup cardDetails">
          <h5>{cardHeadingLabel}</h5>
          <div className="cardWrapper">
            <div className="cardImage">
              <img src={cardImage} alt="credit-card" />
            </div>
            <SimpleInputField
              name={credit_number}
              placeholder={"Card Number"}
              type="text"
              value={values.credit_number}
              touched={touched.credit_number}
              cardError={errors.credit_number}
              onChange={(e) => {
                if (e.target.value.length <= 16) {
                  numberCustomChange(formikProps, credit_number, e.target.value);
                }
              }}
              formikProps={formikProps}
              onBlur={handleBlur}
            />
            <SimpleInputField
              name={mmyy}
              type="text"
              placeholder={mmyyPlaceholder}
              value={values.mmyy}
              cardError={errors.mmyy}
              touched={touched.mmyy}
              onChange={(e) => {
                const maskedValue = handleMaskedInput(e.target.value);
                if (e.target.value.length > 5 || !maskedValue) return
                if (maskedValue?.error) {
                  // formikProps.setErrors({ mmyy: "Invalid YY" });
                } else {
                  formikProps.setFieldValue("mmyy", maskedValue?.value);
                }
              
              }}
              onBlur={handleBlur}
              formikProps={formikProps}
              className="mm-yy"
            />
            <SimpleInputField
              name={security_code}
              type="number"
              placeholder={cvvPlaceholder}
              value={values.security_code}
              cardError={errors.security_code}
              touched={touched.security_code}
              onChange={(e) => {
                if (e.target.value.length > 4) return
                numberCustomChange(formikProps, security_code, e.target.value);
              }}
              formikProps={formikProps}
              onBlur={handleBlur}
              className="cvv"
            />
          </div>
           {errors && errors.credit_number && touched.credit_number &&<p className="error-messages">{errors.credit_number}</p>}
        </div>
      </div>
    </>
  );
}

export default BillingDetails;
