import React from "react";
import Modal from "react-modal";
import closeIcon from '../../Assets/img/close-blue-icon.png';
import { useNavigate } from "react-router-dom";


function PriceCheckModel({ openModal, closeModal, text, icon,type,routeFlag }) {
    const navigate = useNavigate();
    const handelRouteGoBack = () =>{
        if(routeFlag === "flight"){
            navigate("/flight-search-result")
        }else if(routeFlag === "hotel"){
            navigate("/hotel-search-result")
        }else if(routeFlag === "home"){
            navigate("/home-search-result")
        }
    }

    return (
        <Modal
            isOpen={openModal}
            ariaHideApp={false}
            className="popup popup-open popup-price-check"
        >
            <div className="popup-content">
                <div className="popup-title">
                    <button className="btn close" onClick={closeModal}>
                        <img src={closeIcon} alt="icon" />
                    </button>
                </div>
                <div className="content-box text-center">
                    { type === "up" && <div className="text-center check-icon">
                        <img src={icon} />
                    </div>}
                    { type === "down" && <div className="text-center check-icon check-icon-down">
                        <img src={icon} />
                    </div>}
                    {text}
                </div>
                <div className="button-group">
                    <button type="button" className="btn btn-back orange-btn" onClick={()=>handelRouteGoBack()}> Go back</button>
                    <button type="button" className="btn btn-blue " onClick={closeModal}>Continue</button>
                </div>
            </div>
        </Modal>
    );
}

export default PriceCheckModel;
