import React, { useEffect, useState } from "react";
import dollarIcon from "../../Assets/img/refferal.png";
import infoIcon from "../../Assets/img/info-icon.png";
import TableComponent from "../../Component/TableComponent";
import DateRangePickerComponent from "../../Component/DatePicker/DateRangePickerComponent";
import { Formik } from "formik";
import moment from "moment";
import Pagination from "../../Component/Pagiantion/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { marketingWebsiteAction } from "../../Stores/Actions/marketingWebsiteAction";


function TabletViewDisplay({ modalOpen, data, setValues, values, walletData, setHideNavbar }) {

  const [isTabActive, setIsTabActive] = useState("wallet");
  const [isDateResponsive, setDateResponsive] = useState(false);
  const [isResponsive, setResponsive] = useState(false);
  const [focused2, setFocused2] = useState(null);


  // Mobile Responsive

  const [isMobile, setisMobile] = useState(window.innerWidth < 767 ? true : false);

  const updateSize = () => {
    if (window.innerWidth < 992) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  }

  useEffect(() => {
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);


  const dispatch = useDispatch();

  const showDateResponsive = (clickedInput) => {
    setDateResponsive(clickedInput);
    setFocused2("startDate")
  };

  const handleDateClick = () => {
    if(focused2 === "endDate"){
      setDateResponsive(!isDateResponsive);
    }
  };


  const columns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "dollaricon",
        Cell: ({ row }) => {
          return (
            <div className="col icon">
              <img src={dollarIcon} alt="alt" />
            </div>
          );
        },
      },
      {
        Header: "",
        accessor: "paymentID",
        Cell: ({ row }) => (
          <div className="col titleSection">
            <h3>{row.original.paymentID}</h3>
            <h4>{row.original.dateTime}</h4>
          </div>
        ),
      },
      {
        Header: "",
        accessor: "amount",
        Cell: ({ row }) => {
          return (
            <div className="col priceSection">
              <h5 className="greenText amount-info">
                <button className="btn btn-amount" onClick={() => {
                  if (row.original.type == "paypal_transfer") {
                    modalOpen()
                    dispatch(marketingWebsiteAction.getTransactionDetailsAction(row?.original?.transectionId))
                  }
                }} >
                  ${row?.original?.amount}
                  {row.original.type == "paypal_transfer" &&
                    <img src={infoIcon} alt="image" />
                  }
                </button>
              </h5>
              <h6>{row.original.payPalID}</h6>
            </div>
          );
        },
      },
    ],
    []
  );


  const toggleTab = (field) => {
    setIsTabActive(field);
    setValues({...values, type: field});
  }
  return (
    <div className="walletWrapper tabletViewDisplayBlock">
      <div className="row">
        <div className="col rightontent">
          <div className="card2">
            <div className="contentWrapper">
              <div className="dropdownSearchWrapper">
              <div className="headingTablLink">
                <h2 onClick={() => toggleTab("wallet")} className={`${isTabActive === "wallet" ? "active" : ""}`}>Wallet Transactions</h2>
                <h2 onClick={() => toggleTab("payout")} className={`${isTabActive === "payout" ? "active" : ""}`}>Payout Transactions</h2>
              </div>
                <div className="filter-wrapper">
                  <div className="dateWrapper">
                    <h6>Filter by Date</h6>

                    {isMobile &&
                      <div className="dateBox h-100 d-flex w-100">
                        <div className="toDate d-flex align-items-center" onClick={() => {showDateResponsive("startDate");setHideNavbar(true)}}>
                          <p className="m-0">{
                            values.start_date
                              ? moment(values.start_date).format("DD/MM/YYYY")
                              : "Start Date"
                          }</p>
                        </div>
                        <div className="fromDate d-flex align-items-center" onClick={() => {showDateResponsive("endDate");setHideNavbar(true)}}>
                          <p className="m-0">{values.end_date
                            ? moment(values.end_date).format("DD/MM/YYYY")
                            : "End Date"}</p>
                        </div>
                      </div>
                    }


                    {isDateResponsive && (
                      <div className="inputStyle showDropdown">

                        <div className="date-picker-popup ">
                          <p to="#" className="closePopup">
                            <span
                              className="icon-close"
                              onClick={() => {handleDateClick(false);showDateResponsive(false);setHideNavbar(false)}}
                            />
                          </p>
                          <div className="Date-picker-range">
                            <DateRangePickerComponent
                              isDateResponsive={isDateResponsive}
                              name="dates"
                              startDate={moment(values.start_date)}
                              endDate={values.end_date ? moment(values.end_date) : ""}
                              startDateId="startDate"
                              endDateId="endDate"
                              focusedInput={focused2}
                              focused2={focused2}
                              setFocused2={setFocused2}
                              // onFocusChange={(focusedInput) => {
                              //   setFocused2(focusedInput);
                              // }}
                              isRequired
                              icon="icon-invitation"
                              orientation="vertical"
                              openDirection="down"
                              startDatePlaceholderText="Check-in"
                              endDatePlaceholderText="Check-out"
                              onDatesChange={({ startDate, endDate }) => {
                                setValues({
                                  ...values,
                                  start_date: startDate,
                                  end_date: focused2==="startDate" ? "" : endDate,
                                });
                                handleDateClick(false);
                                setHideNavbar(false)
                              }}
                              isOutsideRange={(day) => {
                                if (focused2 === "startDate")
                                  return (
                                    day.isAfter(moment())
                                  );
                                if (focused2 == "endDate")
                                  return (
                                    day.isAfter(moment())
                                  );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    {!isMobile &&
                      <DateRangePickerComponent
                        name="dates"
                        startDate={moment(values?.start_date)}
                        endDate={moment(values?.end_date)}
                        startDateId="startDate"
                        endDateId="endDate"
                        focusedInput={focused2}
                        focused2={focused2}
                        setFocused2={setFocused2}
                        // onFocusChange={(focusedInput) => {
                        //   setFocused2(focusedInput);
                        // }}
                        isRequired
                        icon="icon-invitation"
                        orientation="horizontal"
                        openDirection="down"
                        startDatePlaceholderText="Start Date"
                        endDatePlaceholderText="End Date"
                        onDatesChange={({ startDate, endDate }) => {
                          setValues({
                            ...values,
                            start_date: startDate,
                            end_date: focused2==="startDate" ? null : endDate,
                          });
                        }}
                        isOutsideRange={(day) => {
                          if (focused2 === "startDate")
                            return (
                              day.isAfter(moment())
                            );
                          if (focused2 == "endDate")
                            return (
                              day.isAfter(moment())
                            );
                        }}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>



            <div className="rewardsInfo">
              <TableComponent
                data={data || []}
                columns={columns}
                className="row no-gutters"
              />
              {!data?.length &&
                <div className="data-not-found"><h2>Data not found</h2></div>
              }
            </div>
          </div>
          {
            walletData?.data?.pagination?.total_pages > 1 &&
            <>
              <div className="paginationWrapper">
                <Pagination
                  onPageChange={(e) => setValues({ ...values, page_number: e.selected + 1 })}
                  pageCount={walletData?.data?.pagination?.total_pages}
                  forcePage={walletData?.data?.pagination?.page ? Number(walletData?.data?.pagination?.page) - 1 : 0}
                />
              </div>
            </>
          }
        </div>
      </div>
    </div>
  );
}

export default TabletViewDisplay;