import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Button from "../../Component/FormComponent/Button";
function InputField(props) {
  const {
    name,
    value,
    onChange,
    onBlur,
    placeholder,
    label,
    className,
    type,
    error,
    touched,
    memberNumberClassName,
    inputClass,
    isPromoAppliedMsg,
    cardError,
    labelClassName,
    disabled,
    onKeyUp,
    eyeIcon,
  } = props;

  // Input field without icon

  let commonClassName;
  if (memberNumberClassName && !`col formGroup ${className}`) {
    commonClassName = memberNumberClassName;
  } else if (inputClass) {
    commonClassName = inputClass;
  } else if (`col formGroup ${className}`) {
    commonClassName = `col formGroup ${className}`;
  }
  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
    } else {
      setPasswordType("password")
    }
  }
  return (
    <div className={commonClassName}>
      {label && <h5 className={labelClassName}>{label}</h5>}
      <div className={eyeIcon ? 'inputPasswordWrap' : ' '}>
        <input
          type={eyeIcon ? passwordType : type}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          className={
            cardError && touched ? `formControl error-messages` : `formControl`
          }
          disabled={disabled}
          onKeyUp={onKeyUp}
        />
      {eyeIcon && <><Button type="button" className="btn-eye btn-style-removed" onClick={togglePassword}>{passwordType === "password" ? <AiOutlineEye color="#1d6ec9" /> : <AiOutlineEyeInvisible color="#1d6ec9" />}</Button></>}
      </div>
      {error && touched && <div className="error-messages">{error}</div>}
      {!error && touched && isPromoAppliedMsg && (
        <div className="success-messages">{isPromoAppliedMsg}</div>
      )}
    </div>
  );
}

export default InputField;
