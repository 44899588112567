import { supportConstant } from "../Constants/supportConstant";

const initialState = {
  issuesListData: null,
  autoCompleteData: [],
  DetailsModalData: null,
};

const supportReducer = (state = initialState, action) => {
  switch (action.type) {
    //  Get list of issues
    case supportConstant.GET_SUPPORT_ISSUES_LIST_REQUESTED:
      return {
        ...state,
        issuesListData: null,
        autoCompleteData: [],
      };
    case supportConstant.GET_SUPPORT_ISSUES_LIST_SUCCESS:
      return {
        ...state,
        issuesListData: action.response.data,
      };
    case supportConstant.GET_SUPPORT_ISSUES_LIST_FAILURE:
      return {
        ...state,
        issuesListData: null,
      };

    // Get Auto pop up  list

    // case supportConstant.GET_SUPPORT_AUTO_LIST_REQUESTED:
    //   return {
    //     ...state,
    //     autoCompleteData: [],
    //   };
    // case supportConstant.GET_SUPPORT_AUTO_LIST_SUCCESS:
    //   return {
    //     ...state,
    //     autoCompleteData: action.response?.data?.data,
    //   };
    // case supportConstant.GET_SUPPORT_AUTO_LIST_FAILURE:
    //   return {
    //     ...state,
    //     autoCompleteData: [],
    //   };

    // Submitted From data

    case supportConstant.SUPPORT_FROM_SUBMITTED_REQUESTED:
      return {
        ...state,
        DetailsModalData: null,
      };
    case supportConstant.SUPPORT_FROM_SUBMITTED_SUCCESS:
      return {
        ...state,
        DetailsModalData: action.response,
      };
    case supportConstant.SUPPORT_FROM_SUBMITTED_FAILURE:
      return {
        ...state,
        DetailsModalData: null,
      };

    default:
      return state;
  }
};

export default supportReducer;
