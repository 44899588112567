import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PinImg from "../../Assets/img/location-gray.png";
import StarImg from "../../Assets/img/yellow-star.png";
import { utilitiesImg } from "../../Data/hotelMockData";
import { isEmpty } from "lodash";
import { getCookie } from "../../Util/util";
import infoImg from "../../Assets/img/info-circle.svg";
import GreyStarImg from "../../Assets/img/star.png";
import { commafy } from '../../Util/util';
function HomeDetails({
  name,
  rating,
  amenities,
  address,
  comparePrice,
  final_price,
  savings_percentage,
  savings_amount,
  no_of_nights,
  net_rate,
  save_more_final_price,
  priceDetails,
  rates,
  isMember,
  isDetailPage,
  is_refundable,
  no_of_rooms,
  tax,
  sales_tax,
  mandatory_fee,
  id,
  per_night_without_tax,
  priceDetail,
  per_night,
  per_night_savings_amount,
  showUpdate,
  currencySymbol,
  voucher_credit,
  save_more,
  save_more_percentage,
  currency,
}) {
  const user = JSON.parse(window.localStorage.getItem("user"));
  const [isPremium, setIsPremium] = useState(null);
  function starprint() {
    let star = [];
    let darkStar = 5 - rating;
    for (let i = 0; i < rating; i++) {
      star.push(<img src={StarImg} alt="yellow-star" key={`${i}_rating1`} />);
    }
    for (let i = 0; i < darkStar; i++) {
      star.push(
        <img
          src={GreyStarImg}
          alt="yellow-star"
          key={`${i}_rating2`}
          width="24"
        />
      );
    }
    return star;
  }
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  });

  
  useEffect(() => {
    setIsPremium(getCookie("is_premium") === "true");
    // setIsPremium(false);
  }, []);
  const [prices, setPrices] = useState({});

  const premiumAndFremiumPrices = {
    roomsMultiNights: net_rate,
    tax: tax,
    total: final_price,
    saving_percentage: savings_percentage,
    savings_amount: voucher_credit,
    // night
    night_roomsMultiNights: (net_rate / no_of_nights).toFixed(2),
    night_tax: (tax / no_of_nights).toFixed(2),
    night_total: (final_price / no_of_nights).toFixed(2),
    night_saving_percentage: savings_percentage,
    night_savings_amount: (parseFloat(voucher_credit) / no_of_nights).toFixed(
      2
    ),
  };
  const premiumPrices = {
    roomsMultiNights: net_rate,
    tax: tax,
    total: final_price,
    saving_percentage: savings_percentage,
    savings_amount: savings_amount,
    // night
    night_roomsMultiNights: (net_rate / no_of_nights).toFixed(2),
    night_tax: (tax / no_of_nights).toFixed(2),
    night_total: (final_price / no_of_nights).toFixed(2),
    night_saving_percentage: savings_percentage,
    night_savings_amount: per_night_savings_amount,
  };

  const premiumForFremium = {
    roomsMultiNights: save_more_final_price - tax,
    tax: tax,
    total: save_more_final_price,
    saving_percentage: save_more_percentage,
    savings_amount: save_more,
    // night
    night_roomsMultiNights: (
      (save_more_final_price - tax) /
      no_of_nights
    ).toFixed(2),
    night_tax: (tax / no_of_nights).toFixed(2),
    night_total: (save_more_final_price / no_of_nights).toFixed(2),
    night_saving_percentage: save_more_percentage,
    night_savings_amount: (save_more / no_of_nights).toFixed(2),
  };
  useEffect(() => {
    if (isPremium == true) {
      setPrices(premiumPrices);
    } else if (isPremium == false) {
      setPrices(premiumAndFremiumPrices);
    }
    if (showUpdate) setPrices(premiumForFremium);
  }, [showUpdate, isPremium]);
 
  return (
    <div className="cardContentWrap h-100">
      <div className="innerCnt dflexColm">
        <div className="title-wrap">
          <Link to={`/home-detail/${id}`}>
            <h3 className="cartTitle">{name}</h3>
          </Link>
          <div className="topSection dflex justifyStart">
            <div className="rating"> {starprint()}</div>
            <div className="ratingComment font14">
              <span className="commentCount">{rating}</span>
              Very Good
            </div>
          </div>
        </div>
        <ul className="bulletList">
          {isEmpty(amenities) ? (
            <li>Wi-Fi</li>
          ) : (
            amenities.map((ele, index) => {
              if (index < 4)
                return (
                  <li
                    key={`${id}_amenity_${index}`}
                    id={`${id}_amenity_${index}`}
                  >
                    {ele}
                  </li>
                );
            })
          )}
        </ul>
        <div className="mobileViewWrapper">
          {utilitiesImg &&
            utilitiesImg.map((ele, index) => {
              return (
                <img src={ele} alt="icons" key={`${id}_${index}_utilImg`} />
              );
            })}
          <span>+4</span>
        </div>
        <div className="btmSection font14">
          <div className="location">
            <img src={PinImg} alt="icon" />
            {address}
          </div>
          <div className="price-wrapper">
            <div className="compare-price-wrap">
              <div className="comparePrice">

                {comparePrice.length > 0 &&
                  <>
                    <span className="graph"></span>
                    <span className="comparePriceText">
                      Compare Price <i className="fa fa-angle-down"></i>
                    </span>
                    <div className="comparePriceContent">
                      <div className="comparePrice-flex">

                        <div className="col">
                          {!isEmpty(comparePrice) &&
                            comparePrice.map((ele, index) => {
                              if (index <= 3)
                                return (
                                  <div
                                    className="dflex"
                                    key={`${id}_${index}_compPrice`}
                                  >
                                    <span className="key">{ele.label}</span>
                                    <span className="value">{currencySymbol}{commafy((ele.value).replace(currencySymbol,""))}</span>
                                    {/* <span className="value">  {formatter.format(ele.value.substring(1))}</span> */}
                                  </div>
                                ); 
                            })}
                        </div>

                        {/* <div className="col">
                            {comparePrice &&
                              comparePrice.map((ele, index) => {
                                if (index > 3)
                                  return (
                                    <div
                                      className="dflex"
                                      key={`${id}_${index}_compPrice2`}
                                    >
                                      <span className="key">{ele.siteName}</span>
                                      <span className="value">{currencySymbol}{ele.sitePrice}</span>
                                    </div>
                                  );
                              })}
                          </div> */}

                      </div>
                    </div>
                  </>
                }

              </div>

              {priceDetail === "total" && (
                <div className="priceDetailsWrapper">
                  <div className="priceDetails d-flex align-items-center">
                    <h4>
                      {" "}
                      <img src={infoImg} alt="icon" /> Price Details
                    </h4>
                    <i className="fa fa-angle-down"></i>

                    <div className="pricedetail-dropdown">
                      <div className="dropdown-box">
                        <div
                          className="dropdown-content"
                          style={{
                            borderBottom:
                              prices?.savings_amount > 0 ? "" : "none",
                          }}
                        >
                          {true && (
                            <div className="note">
                              <p>Free cancellation</p>
                            </div>
                          )}
                          <div className="dropdown-info">
                            <h3>Price Details </h3>
                            <h4>
                              {no_of_rooms} Room{no_of_rooms > 1 ? "s" : ""} x{" "}
                              {no_of_nights} Night{no_of_nights > 1 ? "s" : ""}
                              <span>
                                {currencySymbol}
                                {commafy(prices.roomsMultiNights)}

                              </span>
                            </h4>
                            {(prices?.tax !== "0.00")  && <h4>
                              Taxes and Fees
                              <span>
                                {currencySymbol}
                                {commafy(prices.tax)}

                              </span>
                            </h4>}
                            <div className="total-amount">
                              <h4>
                                <strong className="total-text">Total:</strong>
                                <span>
                                  {currencySymbol}
                                  {commafy(prices.total)}
                                </span>
                              </h4>
                            </div>
                            {prices.savings_amount > 0 && (
                              <div className="saving-box">
                                <h4>
                                  Savings ({prices.saving_percentage}%):
                                  <span>
                                    {currencySymbol}
                                    {commafy(prices.savings_amount)}
                                  </span>
                                </h4>
                              </div>
                            )}
                          </div>
                          {mandatory_fee && mandatory_fee.length > 0 && (
                            <div className="resortFeessection">
                              <h3>
                                {" "}
                                <span className="circle">
                                  <img
                                    src="img/profile/warning.svg"
                                    alt="icon"
                                    className="mCS_img_loaded"
                                  />
                                </span>
                                <strong>
                                  Resort Fees per Room:
                                  {mandatory_fee.currency}
                                  {commafy(mandatory_fee[0])}
                                </strong>
                              </h3>
                              <p>
                                (<strong>NOT</strong> included in the price, due
                                at the Hotel)
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {priceDetail === "night" && (
                <div className="priceDetailsWrapper">
                  <div className="priceDetails d-flex align-items-center">
                    <h4>
                      {" "}
                      <img src={infoImg} alt="icon" /> Price Details
                    </h4>
                    <i className="fa fa-angle-down"></i>

                    <div className="pricedetail-dropdown">
                      <div className="dropdown-box">
                        <div
                          className="dropdown-content"
                          style={{
                            borderBottom:
                              prices?.night_savings_amount > 0 ? "" : "none",
                          }}
                        >
                          {true && (
                            <div className="note">
                              <p>Free cancellation</p>
                            </div>
                          )}
                          <div className="dropdown-info">
                            <h3>Price Details </h3>
                            <h4>
                              {no_of_rooms} Room{no_of_rooms > 1 ? "s" : ""} x 1
                              Night
                              <span>{currencySymbol}{prices.night_roomsMultiNights}</span>
                            </h4>
                            {(prices.night_tax !== 0 || prices.night_tax !== "0.00") && <h4>
                              Taxes and Fees
                              <span>
                                {currencySymbol}
                                {commafy(prices.night_tax)}
                              </span>
                            </h4>}
                            <div className="total-amount">
                              <h4>
                                <strong className="total-text">Total:</strong>
                                <span>
                                  {currencySymbol}
                                  {commafy(prices.night_total)}
                                </span>
                              </h4>
                            </div>
                            {prices.night_savings_amount > 0 && (
                              <div className="saving-box">
                                <h4>
                                  Savings ({prices.saving_percentage}%):
                                  <span>
                                    {formatter.format(
                                      prices.night_savings_amount
                                    )}
                                  </span>
                                </h4>
                              </div>
                            )}
                          </div>
                          {mandatory_fee && mandatory_fee.length > 0 && (
                            <div className="resortFeessection">
                              <h3>
                                {" "}
                                <span className="circle">
                                  <img
                                    src="img/profile/warning.svg"
                                    alt="icon"
                                    className="mCS_img_loaded"
                                  />
                                </span>
                                <strong>
                                  Resort Fees per Room:
                                  {mandatory_fee.currency}
                                  {commafy(mandatory_fee[0])}
                                </strong>
                              </h3>
                              <p>
                                (<strong>NOT</strong> included in the price, due
                                at the Hotel)
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeDetails;
