

import React from 'react';

function Column({ className, image1, image2, type, zipFile }) {
  return (
    <div className="col">
      <div className="content-wrapper">
        {className && <a className={className} href=""></a>}

        {type === "images" && <img src={image1} alt="add" />}

        {type === "videos" && <video src={image1} alt="video" />}
      </div>

      <div className="download-link">
        <h4>
          <a href={zipFile ? zipFile : image1} download>
            <img src={image2} alt="icon" />
            Download
          </a>
        </h4>
      </div>
    </div>
  );
}

export default Column;