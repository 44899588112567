import React from "react";

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'

function CustomPhoneInput(props) {
  const {
    inputProps,
    value,
    onChange,
    error,
    touched,
    onBlur,
    label,
    disabled,
    labelClassName,
  } = props;

  return (
    <>
    <h5 className={labelClassName}>{label}</h5>
    <PhoneInput 
      inputProps={inputProps}
      value={value}
      onChange={onChange}
      country={"us"}
      masks={{fr: '(...) ..-..-..', at: '(....) ...-....'}}
      onBlur={onBlur}
      disabled={disabled}
    />
    {error && touched && <div className="error-messages">{error}</div>} 
    </>
  );
}

export default CustomPhoneInput;
