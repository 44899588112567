import React from "react";
import { Link } from "react-router-dom";
import Checkbox from "./FormComponent/Checkbox";
import AffiliatePDF from "../Assets/pdf/network-conditions.pdf";

//Terms Checkbox

function AffiliateTermsCheckbox(props) {
  const { value, onChange, name, onBlur, touched, error } = props;

  return (
    <>
      <div className="checkBoxContent mt-10">
        <label className="checkBoxContentWrap">
          <Checkbox
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
          />
          <p className={touched && error ? "color-red" : ""}>
            By clicking «Pay Now», I agree to the{" "}
            <Link to={AffiliatePDF} target="_blank">Tripello Network Affilate Agreement.</Link>
          </p>
        </label>
      </div>
    </>
  );
}

export default AffiliateTermsCheckbox;
