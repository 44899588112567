import React from "react";

function MobileImgSlider({ src }) {
  return (
    <div className="slider-images">
      <div className="slider-content"></div>
      <img src={src} alt="slider-images" />
    </div>
  );
}

export default MobileImgSlider;
