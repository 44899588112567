import React, { useState } from "react";
import HotelDetails from "../../../Component/HomeCard/HomeDetails";
import HotelImageSection from "../../../Component/HomeCard/HomeImageSection";
import HotelPriceSection from "../../../Component/HomeCard/HomePriceSection";
import DefaultImage from "../../../Assets/img/placeholder-image.png";

function SingleHomeCard({
  origin_price,
  thumbnail_image_url,
  name,
  rating,
  amenities,
  address,
  price_comparision,
  rates,
  isMember,
  no_of_nights,
  no_of_rooms,
  currencyCode,
  currencySymbol,
  id,
}) {
  const [priceDetail, setPriceDetail] = useState("total");
  const [showUpdate, setShowUpdate] = useState(false);

  return (
    <div className="card2" key={id}>
      <div className="row no-gutters">
        <HotelImageSection
          id={id}
          image={thumbnail_image_url || DefaultImage }
          {...rates.packages[0]}
        />
        <div className="cardContent col">
          <HotelDetails
            name={name}
            rating={rating}
            amenities={amenities}
            address={address}
            comparePrice={price_comparision}
            {...rates.packages[0]}
            no_of_nights={no_of_nights}
            no_of_rooms={no_of_rooms}
            isMember={isMember}
            currency={currencyCode}
            id={id}
            priceDetail={priceDetail}
            showUpdate={showUpdate}
            currencySymbol={currencySymbol}
          />
        </div>

        <div className="infoBox col col-width">
          <HotelPriceSection
            {...rates.packages[0]}
            no_of_nights={no_of_nights}
            no_of_rooms={no_of_rooms}
            voucher_credit={rates.packages[0].voucher_credit}
            isMember={isMember}
            currencyCode={currencyCode}
            setPriceDetail={setPriceDetail}
            setShowUpdate={setShowUpdate}
            showUpdate={showUpdate}
            id={id}
          />
        </div>
      </div>
    </div>
  );
}

export default SingleHomeCard;
