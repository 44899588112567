

import React from 'react';
import Column from './Column';
import Pagination from './Pagination';

function CommissionContentVideo({heading, downloadImg, image1, image2, image3, image4, image5, image6}) {

	const imageArrayList1 = [
		{image:image1},
		{image:image2},
		{image:image3}
	]

	const imageArrayList2 =[
		{image:image4},
		{image:image5},
		{image:image6},
	]

	return (
		<>
			<div className="commission-content-info-inner commission-content-videos">
				<div className="heading">
					<div className="heading-info">
						<h3>{heading}</h3>
					</div>
				</div>
				<div className="image-container">

					<div className='row'>
						{imageArrayList1.map((item, index) => {
							return(
								<Column image1={item.image} image2={downloadImg} className="popup-link" key={index}/>
							)
					})}
					</div>
					<div className='row'>
						{imageArrayList2.map((item, index) => {
							return(
								<Column image1={item.image} image2={downloadImg} className="popup-link" key={index}/>
							)
					})}
					</div>
				</div>
			</div>
			<Pagination/>
		</>
	);
}

export default CommissionContentVideo;