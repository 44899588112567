import React from "react";

const Button = (props) => {
  return (
    <button
      className={`${props.className} ${props.disabled ? "disabled" : ""}`}
      onClick={props.onClick}
      onSubmit={props.onSubmit}
      disabled={props.disable}
      type={props.type}
      style={props.style ? props.style : {}}
    >
      {props.children} 
    </button> 
  );  
};

export default Button;
