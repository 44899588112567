import React, { useState } from "react";
import HeaderNavbar from "../../Component/HeaderNavbar";
import TabList from "./TabList";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import { CarFaqsList } from "../../main/CustomConstants/CarFaqsList";
import CarFaqWrapper from "./CarFaqWrapper";
import Button from "./Button";
import Scrollbars from "react-custom-scrollbars";

function CarFaqs() {
  // State for expanding faqs list
  const [isExpandAccordian, setIsExpandAccoridan] = useState(false);
  // Opening and closing faq
  const [openList, setOpenList] = useState(0);

  // Handling open and closing faq list on click
  const handleOpenCloseList = (id) => {
    setOpenList(openList !== id ? id : null);
  };

  const carFaqs = (getCarFaqs) => {
    const accordianList = [];

    //faq list getting pushed into an array
    getCarFaqs.forEach((faq, index) => {
      if (isExpandAccordian) {
        accordianList.push(
          <CarFaqWrapper
            openList={openList}
            handleOpenCloseList={handleOpenCloseList}
            faq={faq}
            index={index}
            key={index}
          />
        );
      } else if (index < 5) {
        accordianList.push(
          <CarFaqWrapper
            openList={openList}
            handleOpenCloseList={handleOpenCloseList}
            faq={faq}
            index={index}
            key={index}
          />
        );
      }
    });

    return accordianList;
  };

  return (
    <div className="siteBg FaqPageWrap">
      <div className="pageWrap faq-scroll">
        <div className="fixedHeader">
          <HeaderNavbar  useBlueIcon={true}/>
        </div>
        <section className="mainContent">
          <Scrollbars
            autoHeight={true}
            autoHeightMin={120}
            autoHeightMax={`calc(100%)`}
            className="pageInternalScroll"
          >
            <div className="container pageInternalScrollTopPadding">
              <div className="row no-gutters  rightContent-bottom">
                <TabList />
                <div className="col rightContent">
                  <div className="faqWrapper card2">
                    <div className="toggle-more-accordions">
                      {carFaqs(CarFaqsList)}
                    </div>
                  </div>
                  {/* gettig more and less button */}
                  {CarFaqsList.length > 5 && (
                    <Button
                      isExpandAccordian={isExpandAccordian}
                      setIsExpandAccoridan={setIsExpandAccoridan}
                    />
                  )}
                  {/* gettig more and less button end here */}
                </div>
              </div>
            </div>
          </Scrollbars>
        </section>
        <FooterWithMediaIcon
          className="white-icon"
          iconClassName="icon-white"
        />
      </div>
    </div>
  );
}

export default CarFaqs;
