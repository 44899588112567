import React from "react";

function SimpleInputField(props) {
  const {
    type,
    onChange,
    onBlur,
    value,
    error,
    touched,
    placeholder,
    name,
    label,
    className,
    cardError
  } = props;

  // Input field

  return (
    <>
      <label className="labelStyle">{label}</label>
      <input
        type={type}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        placeholder={placeholder}
        name={name}
        className={cardError && touched ? `formControl error-messages ${className}` : `formControl ${className}`}
      />
      {error && touched && <div className="error-messages">{error}</div>}
    </>
  );
}

export default SimpleInputField;
