import React from 'react'

const CondosTab = (isSearchView) => {
  return (
    <>
      {!isSearchView?.isSearchView &&
        <div className="landing-info-data-wrap">
          <div className="landing-info-data">
            <h3>
              <span>Book from over 1.5 million Vacation Rentals directly.</span>
              <span>Property types include Cabins, Lake Rentals, Condos, Beach Houses,</span>
              <span>Bungalows, Villas and more.</span>
            </h3>
          </div>
        </div>
      }

      {isSearchView?.isSearchView &&
        <>
          <div className='coming-soon-wrap'>Coming Soon...</div>
        </>
      }
    </>
  )
}

export default CondosTab