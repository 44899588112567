import React, { useState, useEffect } from "react";
import moment from "moment";
import PortraitModeModel from "../Component/PortraitModeModel";
import useCurrentDevice from "../Stores/Utils/useCurrentDevice";

export default function Footer({ copyright }) {
  const [portraitViewModal, setportraitViewModal] = useState(window.outerHeight > window.outerWidth && window.outerWidth > 767);
  let portrait = window.matchMedia("(orientation: portrait)");

  // const { isMobile } = useCurrentDevice();

  const [isMobile, setIsMobile] = useState(false)

  portrait.addEventListener("change", function (e) {

    if (window.innerWidth < 768) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }

    if (e.matches) {
      // if (!window.localStorage.getItem("view")) {
        setportraitViewModal(true);
        // window.localStorage.setItem("view", true);
      // }

    } else {
      setportraitViewModal(false)
      // window.localStorage.removeItem("view")
    }
  })

  const handleResize = () =>{
    if (window.innerWidth < 768) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [])
  

  const handleOpenClosePortraitModal = () => {
    setportraitViewModal(false);
  };
  return (
    //footer Section
    <>
      {isMobile === false && portraitViewModal &&
        <>
          <PortraitModeModel
            portraitViewModal={portraitViewModal}
            closeModal={handleOpenClosePortraitModal}
          />
        </>
      }
      <div className="footCopyWrap">
        <p className="footCopy">
          Copyright &copy; {moment().year()} Tripello Inc. All Rights Reserved.
        </p>
        <p className="footCopy footer-new-links">
          Reg. U.S. Pat & Tm Off.
        </p>
        <p className="footCopy footer-new-links">
          Tripello Inc. Las Vegas, NV 89147
        </p>
      </div>
    </>
  );
}
