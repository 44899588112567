import * as Yup from "yup";

//Freemium sign up form validation

export const FreemiumSignUpValidation = () => {
  return Yup.object().shape({
    firstName: Yup.string("")
      .matches(/^[aA-zZ\s]+$/, "Must only have letters [A-Z a-z].")
      .required("Required"),
    lastName: Yup.string("")
      .matches(/^[aA-zZ\s]+$/, "Must only have letters [A-Z a-z].")
      .required("Required"),
    email: Yup.string("")
      .email("Enter a valid email address")
      .required("Required"),
    password: Yup.string("")
      .min(8, "Must be 8 characters or more") 
      .required("Required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Please confirm password"),
    terms: Yup.boolean().oneOf([true], "Required"),
    country : Yup.object().required("Required")
  });
};
