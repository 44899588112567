import React from "react";
import Modal from "react-modal";
import closeIcon from '../../Assets/img/close-blue-icon.png';


function BasicModal({ openModal, closeModal, text , icon }) {
    return (
        <Modal
            isOpen={openModal}
            ariaHideApp={false}
            className="popup popup-open popup-email-success-sent"
        >
            <div className="popup-content">
                <div className="popup-title">
                    <button className="btn close" onClick={closeModal}>
                        <img src={closeIcon} alt="icon" />
                    </button>
                </div>
                <div className="content-box">
                    <div className="text-center check-icon">
                        <img src={icon} />
                    </div>
                   {text}
                </div>
            </div>
        </Modal>
    );
}

export default BasicModal;
