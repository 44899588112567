import React from 'react';
import Modal from 'react-modal';
import Videojs from "../Component/VideoJs/Custom-videojs"
import TripelloHomepageVideo from '../Assets/video/home/new-tripello-homepage-explainer.mp4'
import closeButton from '../Assets/img/lightbox-close.png'
import posterImg from '../Assets/img/network/video-section-bg.png'

function VideoModal({ openModal, closeModal,videoSrc, autoPlay}) {
  const videoJsOptions = {
    autoplay: !autoPlay ? true : false,
    controls: true,
    loop: !autoPlay ? true : false,
    playsInline:true,
 
    sources: [
      {
        src: videoSrc ? videoSrc : TripelloHomepageVideo,
        type: 'video/mp4',
      },
    ],
  };
  return (
    <Modal isOpen={openModal} ariaHideApp={false} className='popup magic-video-section-popup popup-open first-video-popup'>
      <div className="popup-content home-video">
        <div className="popup-title">
          <button className="btn close" onClick={closeModal}>
            <img src={closeButton} alt="icon" />
          </button>
        </div>
        <div className="content-box">
          <div className="content-video-box">
          <Videojs {...videoJsOptions} />
            {/* <video
            id="popup-video"
              autoPlay
              preload="auto"
              controls="true"
              playsInline="playsInline"
              loop
              className="video-js magic-video-popup-player"
              poster={posterImg}
            >
              <source src={tripelloHomepageVideo} type="video/mp4" />
              Your browser does not support HTML5 video.
            </video> */}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default VideoModal