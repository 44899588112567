import React from 'react'
import Modal from "react-modal";

const SupportPageModal = ({ closeModal, afterOpenModal, open, message, isSuccessView }) => {

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };


    return (
        <>
            <Modal
                isOpen={open}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <button onClick={closeModal}>close</button>
                <h2>Hello</h2>
                <p>{message}</p>
            </Modal>
        </>
    )
}

export default SupportPageModal