import React, { useState } from "react";
import HeaderNavbar from "../../Component/HeaderNavbar";
import TabMenu from "./TabMenu";
import PremiumMemberInvites from "./PremiumMemberInvites";
import Link from "./Link";
import Email from "./Email";
import QrCode from "./QrCode";
import TabLayout from "./TabLayout";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import DetailsInfoModal from "./DetailsInfoModal";
import infoIcon from "../../Assets/img/info-icon-white.png";
import memberInviteImg from "../../Assets/img/member-invite-heading.png";
import linkImg from "../../Assets/img/link.png";
import contentCopyImg from "../../Assets/img/content-copy.png";
import qrCodeIcon from "../../Assets/img/qr-code-icon.png";
import fileDownloadImage from "../../Assets/img/file-download.png";
import appleWalletImage from "../../Assets/img/Apple-Wallet.png";
import gPayImage from "../../Assets/img/G-Pay.png";
import emailIcon from "../../Assets/img/email-icon.png";
import qrImage from "../../Assets/img/qr-code-image.png";
import { getPassesLink } from "../../Util/util";
import { useSelector } from "react-redux";
import Scrollbars from "react-custom-scrollbars";

function MemberInvites() {
  // State for Modal
  const [openModal, setOpenModal] = useState(false);
  const emailError = useSelector((state) => state.cardsReducer.error);
  const emailStatus = useSelector((state) => state.cardsReducer.emailStatus);
 
  // For opening and closing  modal
  const handleOpenCloseModal = () => {
    if (!openModal) {
      setOpenModal(!openModal);
    } else {
      setOpenModal(false);
    }
  };

  // user data
  const user = JSON.parse(window.localStorage.getItem("user"));

  const currentHost = window.location.origin;
  const FremiumUrlLinkText = `https://www.tripello.com/new-freemium-signup?ref=${user?.email}&fname=${user?.first_name}&lname=${user?.last_name}&template=freemium`;
  const PremiumUrlLinkText = `https://www.tripello.com/premium-plus-signup?ref=${user?.email}&fname=${user?.first_name}&lname=${user?.last_name}&template=premium`;

  const SocialCardComponant = ({ urlLinkText, userType }) => {
    return (
      <>
        <Link
          para1={`Share your individual ${userType} Invitation link. Copy the link and paste into your personal email, send it via SMS or share it through your favorite Social Media Channels`}
          contentCopyImg={contentCopyImg}
          heading2="Click to Copy Link"
          para2="Click on the icon to take you to your favorite Social Media Platform to post your link."
          urlLinkText={urlLinkText}
          className="sharingIconsList"
        />
      </>
    );
  };
  const EmailCardComponant = ({ urlLinkText, userType }) => {
    return (
      <>
        <Email
          para2={`Enter your recipients contact information and we will directly send an email with your individual ${userType === "premium" ? "Premium" : "Freemium"} invitation link.`}
          para="By sending the invite directly you agree to the Terms and Conditions"
          currentUserId={JSON.parse(window.localStorage.getItem("user")).user_id}
          affiliateLink={urlLinkText}
          userType={userType}
          emailError={
            emailError?.userType == userType ? emailError?.message : null
          }
          emailStatus={
            emailStatus?.userType == userType ? emailStatus?.message : null
          }
        />
      </>
    );
  };
  const QrCardComponant = ({ urlLinkText, userType }) => {
    
    let passType = ""
    if(userType === "Freemium"){
      passType = "freemium"
    }else if(userType === "Premium"){
      passType = "premium"
    }
    return (
      <>
        <QrCode
          para={
            `Share your individual ${userType} Invitation QR Code directly from this page or download it and share it offline.`
          }
          qrImage={qrImage}
          fileDownloadImage={fileDownloadImage}
          appleLink={getPassesLink(passType, "apple")}
          googleLink={getPassesLink(passType, "google")}
          heading="Download QR Code (.PNG)"
          appleWalletImage={appleWalletImage}
          gPayImage={gPayImage}
          linkText={urlLinkText}
        />
      </>
    );
  };

  return (
    <div className="siteBg  marketingDashboard membersCheckoutPageWrap membersRewardsPageWrap dashboard-pages">
      <div className="pageWrap pageWrapInner">
        <div className="fixedHeader">
          <HeaderNavbar useBlueIcon={true}/>
        </div>
        <section className="mainContent">
          <Scrollbars
            autoHide
            autoHeight
            autoHeightMin={120}
            autoHeightMax={`calc(100%)`}
            className="pageInternalScroll"
          >
            <div className="container affiliate-invites-wrapper common-wrapper">
              <TabMenu
                modalOpen={handleOpenCloseModal}
                infoIcon={infoIcon}
                className="btn details-btn"
              />

              <PremiumMemberInvites
                // className="rewardsSection"
                heading="Freemium and Premium Member Invites"
                para={
                  <>
                    <p>
                      {" "}
                      As Tripello Affiliates you have your own personalized
                      Invitation Links and QR Codes that you can share in
                      Person, on Social Media or via Email.
                    </p>
                    <p>
                      {" "}
                      You can target prospects with the «Try before you Buy»
                      mindset through your individual Freemium Invite link, or
                      if you are confident that your prospect are ready to sign
                      up immediately, use the Premium Invite link.
                    </p>
                  </>
                }
                memberInviteImg={memberInviteImg}
              />

              {/* fremium invite start */}

              <div className="section-title">
                <h2>Freemium Member Invites</h2>
              </div>
              <div className="note-wrapper">
                <p>
                  Choose this link and QR Code if you are targeting prospects
                  interested in the Tripello Freemium Membership.
                </p>
              </div>
              <div className="sharingWrapper sharingWrapperSpace desktopViewDisplayBlock">
                <div className="row">
                  <div className="col">
                    <div className="card">
                      <div className="linkIcon">
                        <img src={linkImg} alt="icon" />
                        <h3>{Link} Link</h3>
                      </div>
                      <SocialCardComponant urlLinkText={FremiumUrlLinkText} userType="Freemium" />
                    </div>
                  </div>
                  <div className="col">
                    <div className="card">
                      <div className="linkIcon">
                        <img src={emailIcon} alt="icon" />
                        <h3>{Link} Email</h3>
                      </div>
                      <EmailCardComponant
                        urlLinkText={FremiumUrlLinkText}
                        userType="freemium"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="card">
                      <div className="linkIcon">
                        <img src={qrCodeIcon} alt="icon" />
                        <h3>{Link} QR</h3>
                      </div>
                      <QrCardComponant urlLinkText={FremiumUrlLinkText} userType="Freemium" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mobileSharingWrapper mobileSharingWrapper1 sharingWrapper tabletViewDisplayBlock">
                <div className="card">
                  <TabLayout
                    SocialCardComponant={
                      <SocialCardComponant urlLinkText={FremiumUrlLinkText} userType="Freemium"/>
                    }
                    EmailCardComponant={
                      <EmailCardComponant
                        urlLinkText={FremiumUrlLinkText}
                        userType="freemium"
                      />
                    }
                    QrCardComponant={
                      <QrCardComponant urlLinkText={FremiumUrlLinkText} userType="Freemium"/>
                    }
                  />
                </div>
              </div>

              {/* fremium invite end */}
              <div className="premium-wrap">
                <div className="section-title">
                  {" "}
                  {/* premium invite  */}
                  <h2>Premium Member Invites</h2>
                </div>
                <div className="note-wrapper">
                  <p>
                    Choose this link and QR Code if you are targeting prospects
                    interested in the Tripello Premium Membership.
                  </p>
                </div>
                <div className="sharingWrapper sharingWrapperSpace desktopViewDisplayBlock">
                  <div className="row">
                    <div className="col">
                      <div className="card">
                        <div className="linkIcon">
                          <img src={linkImg} alt="icon" />
                          <h3>{Link} Link</h3>
                        </div>
                        <SocialCardComponant urlLinkText={PremiumUrlLinkText} userType="Premium"/>
                      </div>
                    </div>
                    <div className="col">
                      <div className="card">
                        <div className="linkIcon">
                          <img src={emailIcon} alt="icon" />
                          <h3>{Link} Email</h3>
                        </div>
                        <EmailCardComponant
                          userType="premium"
                          urlLinkText={PremiumUrlLinkText}
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="card">
                        <div className="linkIcon">
                          <img src={qrCodeIcon} alt="icon" />
                          <h3>{Link} QR</h3>
                        </div>
                        <QrCardComponant urlLinkText={PremiumUrlLinkText} userType="Premium" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mobileSharingWrapper mobileSharingWrapper1 sharingWrapper tabletViewDisplayBlock">
                <div className="card">
                  <TabLayout
                    SocialCardComponant={
                      <SocialCardComponant urlLinkText={PremiumUrlLinkText}  userType="Premium"/>
                    }
                    EmailCardComponant={
                      <EmailCardComponant
                        urlLinkText={PremiumUrlLinkText}
                        userType="premium"
                      />

                    }
                    QrCardComponant={
                      <QrCardComponant urlLinkText={PremiumUrlLinkText} userType="Premium" />
                    }
                  />
                </div>
              </div>
            </div>
          </Scrollbars>
        </section>

        <FooterWithMediaIcon
          className="white-icon"
          iconClassName="icon-white"
        />
      </div>
      {openModal && (
        <DetailsInfoModal
          openModal={openModal}
          closeModal={handleOpenCloseModal}
        />
      )}
    
    </div>
  );
}

export default MemberInvites;