

import React from 'react' 

function TableRowData({item}) {

  return (
  
    <div className="row"> 
        {
            item && item.map((ele,index)=>{ 
                return(
                    <div className="col" key={index}>
                    <h4 className={`desktop-hide ${ele.title === "STATUS" ? 'status-col' : ''}`}>{ele.title}</h4>
                    <h5 className={`${ele.title === "COMMISSION" ? 'light-green-text' : ''}`}> {ele.title === "COMMISSION" ? "$" : ""}{ele.value} </h5>
                    </div>
                 )
            })
        }     
    </div>
    
   
  )
}
  
export default TableRowData