import React, { useEffect } from 'react';

const TaboolaPixel = () => {
  useEffect(() => {
    window._tfa = window._tfa || [];
    window._tfa.push({ notify: 'event', name: 'page_view', id: 1602818 });

    (function (t, f, a, x) {
      if (!document.getElementById(x)) {
        t.async = 1;
        t.src = a;
        t.id = x;
        f.parentNode.insertBefore(t, f);
      }
    })(
      document.createElement('script'),
      document.getElementsByTagName('script')[0],
      '//cdn.taboola.com/libtrc/unip/1602818/tfa.js',
      'tb_tfa_script'
    );
  }, []);

  return null; // This component doesn't render any visible content
};

export default TaboolaPixel;
