export const cardsConstant = {
  AFFILIATE_DASHBOARD_SUMMARY: "AFFILIATE_DASHBOARD_SUMMARY",
  AFFILIATE_DASHBOARD_SUMMARY_SUCCESS: "AFFILIATE_DASHBOARD_SUMMARY_SUCCESS",
  AFFILIATE_DASHBOARD_SUMMARY_FAILURE: "AFFILIATE_DASHBOARD_SUMMARY_FAILURE",

  ASSIGN_CARDS_REQUEST: "ASSIGN_CARDS_REQUEST",
  ASSIGN_CARDS_SUCCESS: "ASSIGN_CARDS_SUCCESS",
  ASSIGN_CARDS_FAILURE: "ASSIGN_CARDS_FAILURE",

  ASSIGN_CARDS_RESET: "ASSIGN_CARDS_RESET"
};
