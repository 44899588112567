import { formatCarAutoCompleteData } from "./util";
import { autoComplete } from "../../api/commonService";
import { commonConstants } from "../Constants/commonConstants";
import { autoCompleteConstants } from "../Constants/siteConstants/autocompleteConstants";
import { commonService } from "../../api/commonService";

export const commonActions = {
  showLoader,
  hideLoader,
  hotelSearchAutoComplete,
  flightFromSearchAutoComplete,
  flightToSearchAutoComplete,
  loaderText,
  newLoader,
  carSearchAutoComplete,
  flightAutoCompleteReset,
  getGuestListAction,
  getGeoLocationBasedOnIpAddressAction,
};

function showLoader() {
  return (dispatch) => {
    dispatch(request(commonConstants.SHOW_LOADER));
  };
  function request() {
    return { type: commonConstants.SHOW_LOADER };
  }
}
function hideLoader() {
  return (dispatch) => {
    dispatch(request(commonConstants.HIDE_LOADER));
  };
}
function loaderText(text) {
  return (dispatch) => {
    dispatch(success(commonConstants.SHOW_LOADER_TEXT, text));
  };
}

function newLoader(text) {
  return (dispatch) => {
    dispatch(success(commonConstants.NEW_LOADER, text));
  };
}

function hotelSearchAutoComplete(payload) {
  return (dispatch) => {
    dispatch(request(autoCompleteConstants.AUTO_COMPLETE_REQUEST));
    autoComplete.getAutoCompleteHotelSearch(payload).then(
      (response) => {
        dispatch(success(autoCompleteConstants.AUTO_COMPLETE_RESULT, response));
      },
      (error) => {
        dispatch(failure(autoCompleteConstants.AUTO_COMPLETE_FAILURE, error));
      }
    );
  };
}
function flightFromSearchAutoComplete(payload) {
  return (dispatch) => {
    dispatch(request(autoCompleteConstants.AUTO_COMPLETE_FROM_REQUEST));
    autoComplete.getAutoCompleteFlightSearch(payload).then(
      (response) => {
        dispatch(
          success(autoCompleteConstants.AUTO_COMPLETE_FROM_SUCCESS, response)
        );
      },
      (error) => {
        dispatch(failure(autoCompleteConstants.AUTO_COMPLETE_FAILURE, error));
      }
    );
  };
}

function flightToSearchAutoComplete(payload) {
  return (dispatch) => {
    dispatch(request(autoCompleteConstants.AUTO_COMPLETE_TO_REQUEST));
    autoComplete.getAutoCompleteFlightSearch(payload).then(
      (response) => {
        dispatch(
          success(autoCompleteConstants.AUTO_COMPLETE_TO_SUCCESS, response)
        );
      },
      (error) => {
        dispatch(failure(autoCompleteConstants.AUTO_COMPLETE_FAILURE, error));
      }
    );
  };
}

function carSearchAutoComplete(payload) {
  return (dispatch) => {
    dispatch(request(autoCompleteConstants.AUTO_COMPLETE_REQUEST));
    autoComplete.getAutoCompleteCarSearch(payload).then(
      (response) => {
        dispatch(
          success(autoCompleteConstants.CARS_AUTO_COMPLETE_RESULT, {
            ...response,
            data: formatCarAutoCompleteData({
              airport_data:
                response.data?.getCarAutoComplete?.results?.airport_data,
              city_data: response.data?.getCarAutoComplete?.results?.city_data,
            }),
          })
        );
      },
      (error) => {
        dispatch(failure(autoCompleteConstants.AUTO_COMPLETE_FAILURE, error));
      }
    );
  };
}

function flightAutoCompleteReset() {
  return (dispatch) => {
    dispatch(request(autoCompleteConstants.AUTO_COMPLETE_RESET_REQUEST));
  };
}

function getGuestListAction(payload) {
  return (dispatch) => {
    dispatch(request(commonConstants.GET_GUEST_LIST_REQUEST));
    commonService.getGuestList(payload).then(
      (response) => {
        var guestList = [];
        if (response?.data?.data) {
          response.data.data.map((ele) => {
            guestList.push({
              value: ele.id,
              label: `${ele.fname} ${ele.lname}`,
            });
          });
          response.data.guestList = guestList;
        }
        dispatch(success(commonConstants.GET_GUEST_LIST_SUCCESS, response));
      },
      (error) => {
        dispatch(failure(commonConstants.GET_GUEST_LIST_FAILURE, error));
      }
    );
  };
}

function getGeoLocationBasedOnIpAddressAction() {
  return () => {
    commonService.getGeoLocationBasedOnIpAddress().then((response) => {
      window.localStorage.setItem(
        "currentGeoLocation",
        JSON.stringify(
          response.data.country_code ? response.data.country_code : "US"
        )
      );
    });
  };
}

function request(action, payload) {
  return { type: action, payload };
}

function success(action, response) {
  return { type: action, response };
}

function failure(action, error) {
  return { type: action, error };
}
