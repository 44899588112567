import * as Yup from "yup";

export const resetPwdValidation = () => {
  return Yup.object().shape({
    newPassword: Yup.string("")
    .min(8).required("Required"),
    confirmPassword: Yup.string("")
      .oneOf([Yup.ref("newPassword"), null], "Passwords do not match")
      .required("Required"),
    email: Yup.string("").required("Required")
  });
};
