import React from 'react'

function MoreLessButton({isExpandAccordian, setIsExpandAccoridan}) {
  return (
    <div className="read-more-btn-wrap">
        {!isExpandAccordian ? (
        <button
            onClick={() => setIsExpandAccoridan(true)}
            className="read-more-btn"
            type="button"
        >
            more...
        </button>
        ) : (
        <button
            onClick={() => setIsExpandAccoridan(false)}
            className="read-more-btn"
            type="button"
        >
            less...
        </button>
        )}
    </div>
  )
}

export default MoreLessButton