import React, { useEffect, useState } from "react";
import HotelRoomTab from "../HotelRoomTab";
import RoomContainer from "../../../Component/Home/RoomContainer";
import { homeActions } from "../../../Stores/Actions";
import { useDispatch, useSelector } from "react-redux";

function Rooms({ hotel_id,   currencySymbol, currencyCode , Countdown  , CountDownForMobile }) {
  const dispatch = useDispatch();
  const storedPayload = window.localStorage.getItem("homePayload")
    ? JSON.parse(window.localStorage.getItem("homePayload"))
    : JSON.parse(window.localStorage.getItem("mapPayload"));
  const user = JSON.parse(window.localStorage.getItem("user"));
  const hotelRooms = useSelector((state) => state.hotelReducer.hotelRoom);

  const [selectedTab, setSelectedTab] = useState(0);
  
  const currencyAndLanguage =
    window.localStorage.getItem("currencyAndLanguage") &&
    JSON.parse(window.localStorage.getItem("currencyAndLanguage"));

  const currency =
    currencyAndLanguage?.currencyLanguageReducer?.data?.currency || {};

  const initialPayload = {
    rooms: storedPayload?.rooms,
    region_id: storedPayload?.region_id,
    check_in_date: storedPayload?.check_in_date,
    check_out_date: storedPayload?.check_out_date,
    children_count: 0,
    city_name: storedPayload?.city_name, 
    currency: currency.isoCode,
    home: storedPayload?.home,
    hotel_id: hotel_id,
    locale: storedPayload?.locale,
    onlyLocale: storedPayload?.onlyLocale,
    // optional: "amenities,moreDetails,policy,moreRatings",
    // preventCache: 0.9299991871126359,
    search_type: storedPayload?.search_type,
    source_market: storedPayload?.source_market,
    token: window.localStorage.getItem("accessToken"),
    type: storedPayload?.search_type,
    nationality: storedPayload?.nationality,
    use_wallets:
      user.type_id === process.env.REACT_APP_FREMIUM_USER_TYPE_ID ? 1 : 0,
  };

  

  useEffect(() => { 
    if(!hotelRooms)return 
    if(selectedTab != hotelRooms?.rates?.listing?.tab_active ){
      setSelectedTab(hotelRooms?.rates?.listing?.tab_active) 
    }
  }, [hotelRooms])

  const refundableRooms = hotelRooms?.rates?.listing?.refundable || []
  const nonRefundableRooms = hotelRooms?.rates?.listing?.non_refundable || []

  useEffect(() => {
    dispatch(homeActions.getHotelRooms(initialPayload, hotel_id));
    return () => {
      dispatch(homeActions.resetHotelRooms());
    };
  }, []);


  const changeTab = (e, tab) => {
    e.preventDefault();
    setSelectedTab(tab);
  };
  return (
    <>
      <div id="rooms"  className="selectRoomTabs">
        <div className="sectionTitle">
          <h2>Select Your Room</h2>
          
            {CountDownForMobile}
            {Countdown}
        
        </div>
        <div className="refundToggle d-flex justify-content-between">
          <HotelRoomTab
            changeTab={changeTab}
            selectedTab={selectedTab}
            refundableRooms={refundableRooms}
            nonRefundableRooms={nonRefundableRooms}
            currencySymbol={currencySymbol}
            currencyCode={currencyCode}
          />
        </div>
      </div>
      <div className="selectRoomContentMain">
        {selectedTab === 1 && !nonRefundableRooms.length && (
          <div className="refundable-empty-box">
            <h4>No Non-Refundable Rooms Found </h4>
          </div>
        )}
        <div className="selectRoomContent">
          {selectedTab === 1 && (
            <div id="refundable-rooms" className="new-card-section activeTab">
              {nonRefundableRooms.length
                ? nonRefundableRooms.map((ele, index) => {
                  return (
                    <>
                       <RoomContainer
                        {...ele} 
                        containerIndex={index}
                        group_name = {ele?.group_name}
                        index={`${index}_room`}
                        key={`${index}_refun_room`}
                        hotel_id={hotel_id}
                        currencySymbol={currencySymbol}
                        currencyCode={currencyCode}
                        isRefundable={false} 
                      />  
                    </>
                  );
                })
                : null}
            </div>
          )}
          {selectedTab === 0 && (
            <div
              id="non-refundable-rooms"
              className="new-card-section activeTab"
            >
              {refundableRooms.length
                ? refundableRooms?.map((ele, index) => {
                  return (
                    <>
                     <RoomContainer
                      {...ele}
                      index={`${index}_non_ref_room`}
                      key={`${index}_non_ref_room`}
                      hotel_id={hotel_id}
                      currencySymbol={currencySymbol}
                      currencyCode={currencyCode}
                      isRefundable={true}
                      containerIndex={index} 
                      
                    /> 
                    </>
                  );
                })
                : null}
            </div>
          )}
        </div>
        {selectedTab === 0 && !refundableRooms.length && (
          <div className="refundable-empty-box">
            <h4>No Refundable Rooms Found</h4>
          </div>
        )}
      </div>
    </>
  );
}
export default Rooms;
