import { serialize } from "../Util/util";
import http from "./http";

function searchCarService(payload) {
  const queryParams = serialize(payload);
  return http
    .get(
      `${process.env.REACT_APP_BASE_URL_CARS}/car/getResultsV3?${queryParams}`
    )
    .then((response) => response);
}

function getCarContract(payload) {
  const queryParams = serialize(payload);
  return http
    .get(
      `${process.env.REACT_APP_BASE_URL_CARS}/car/getContractRequest?${queryParams}`
    )
    .then((response) => response);
}

function bookCarService(payload, formData) {
  const queryParams = serialize(payload);
  return http
    .post(
      `${process.env.REACT_APP_BASE_URL_CARS}/car/getBookRequest?${queryParams}`,
      formData
    )
    .then((response) => response);
}

function retriveBooking(payload) {
  return http
    .post(
      `${process.env.REACT_APP_BASE_URL_CARS}/car/getLookUp`,
      payload
    )
    .then((response) => response);
}

function validateZip(zip) {
  return http.get(
    `${process.env.REACT_APP_BASE_URL_HOTEL}/zip-details?zip=${zip}`
  );
}
// create car booking service for backoffice 
function createCarBooking(payload) {
  return http
    .post(
      `${process.env.REACT_APP_CAR_BACKOFFICE_URL}/createBooking`,
      payload
    )
    .then((response) => response);
}


// create car booking service for backoffice 
function getCarBooking(payload) {
  return http
    .get(
      `${process.env.REACT_APP_CAR_BACKOFFICE_URL}/details/${payload?.id}`
    )
    .then((response) => response);
}

export const carsService = {
  searchCarService,
  getCarContract,
  bookCarService,
  validateZip,
  retriveBooking,
  createCarBooking,
  getCarBooking
};
