import React, { useEffect, useState } from 'react'
import { commafy } from '../../Util/util';
function Extras({
  updatePrice,
  currencySymbol,
  boards,
  containerIndex
}) {
  const [extras, setExtras] = useState(boards[0]?.key);

  const handlepriceUpdate = (field) => {
    setExtras(field);
    updatePrice(field);
  };
  // const [disableArray, setDisabledArray] = useState([]);
  // const objectArray = [{ label: "Room Only", id: "room_only" }, { label: "Breakfast", id: "breakfast" }, { label: "Half Board", id: "half_board" }, { label: "Full Board", id: "full_board" }, { label: "All Inclusive", id: "all_inclusive" }];


  // useEffect(() => {
  //   setDisabledArray([])
  //   const tempObj = objectArray.filter(object1 => {
  //     return !boards.some(object2 => {
  //       return object1.id === object2.key;
  //     });
  //   });
  //   setDisabledArray(tempObj)

  // }, [])


  return (
    <div className="content-wrap">
      <div className="toggle-link-wrap">
        <div className="extras">
          <div className="extra-title">
            <h4>Extras</h4>
          </div>

          {boards?.map((ele, index) => {
            return <>
              <div className="row">
                <div className="col extras-left-ontent">
                  <div className="custom-radio">
                    <label className="form-check-label" htmlFor={`${index}_room`}>
                      <input
                        className="form-check-input"
                        type="radio"
                        name={`${containerIndex}${index}_col`}
                        id={`${containerIndex}${index}_room`}
                        checked={extras === ele?.key}
                        onChange={() => handlepriceUpdate(ele?.key)}
                      /> 
                      {((ele.name).toLowerCase()).replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}
                    </label>
                  </div>
                </div>
                <div className="col extras-right-ontent">
                  <h6>+ {currencySymbol}{commafy(ele?.price_difference)}</h6>
                </div>
              </div>
            </>
          })}

         {/* {disableArray?.map((ele,index) => {
            return <>
              <div className="row">
                <div className="col extras-left-ontent">
                  <div className="custom-radio">
                    <label className="form-check-label disabled" htmlFor={`${index}_room`}>
                      <input
                        className="form-check-input"
                        type="radio"
                        // name={`${index}_col`}
                        // id={`${index}_room`}
                        checked={false}
                        disabled={true} 
                      />
                      {ele.label}
                    </label>
                  </div>
                </div>
                <div className="col extras-right-ontent">
                  <h6>-</h6>
                </div>
              </div>
            </>

          })}   */}
 
        </div>
      </div>
    </div>
  );
}

export default Extras