import * as Yup from "yup";

// Validation for affiliate invites/member invites

export const validation = () =>
  Yup.object().shape({
    firstName: Yup.string("")
      .matches(/^[aA-zZ\s]+$/, "Must only have alphabet letters")
      .required("Required"),
    lastName: Yup.string("")
      .matches(/^[aA-zZ\s]+$/, "Must only have alphabet letters")
      .required("Required"),
    email: Yup.string("")
      .email("Enter a valid email address.")
      .required("Required"),
  });
