 

import http from "./http";
export const marketingSiteService = {
    getWallletTransactionService,
    getTransactionDetailsService
}

function getWallletTransactionService(walletId,payload) { 
    return http.post(`${process.env.REACT_APP_WALLET_BASE_URL}/api/v2/wallet/${walletId}/${payload.type == "wallet" ? `transactions` : `payouts`}`,
    {
      ...payload,
      params : {
        use_wallet_api_key : true,
      }
    }
    )
    .then((res) => res);
  }

function getTransactionDetailsService(transactionID) { 
    return http.get(`${process.env.REACT_APP_WALLET_BASE_URL}/api/v2/${transactionID}/payouts/${JSON.parse(window.localStorage.getItem("user"))?.user_wallet.id}` , 
    {
      params : {
        use_wallet_api_key : true,
      }
    }
    )
    .then((res) => res);
  }