export const supportConstant = {
    SUPPORT_FROM_SUBMITTED_REQUESTED: "SUPPORT_FROM_SUBMITTED_REQUESTED",
    SUPPORT_FROM_SUBMITTED_SUCCESS: "SUPPORT_FROM_SUBMITTED_SUCCESS",
    SUPPORT_FROM_SUBMITTED_FAILURE: "SUPPORT_FROM_SUBMITTED_FAILURE",
  
    GET_SUPPORT_ISSUES_LIST_REQUESTED: "GET_SUPPORT_ISSUES_LIST_REQUESTED",
    GET_SUPPORT_ISSUES_LIST_SUCCESS: "GET_SUPPORT_ISSUES_LIST_SUCCESS",
    GET_SUPPORT_ISSUES_LIST_FAILURE: "GET_SUPPORT_ISSUES_LIST_FAILURE",
  
    GET_SUPPORT_AUTO_LIST_REQUESTED: "GET_SUPPORT_AUTO_LIST_REQUESTED",
    GET_SUPPORT_AUTO_LIST_SUCCESS: "GET_SUPPORT_AUTO_LIST_SUCCESS",
    GET_SUPPORT_AUTO_LIST_FAILURE: "GET_SUPPORT_AUTO_LIST_FAILURE",
    
  };