
import React, { useState } from "react";
import helpIcon from "../Assets/img/membership-upgrade/help-icon.png";


function AccordianOne(props) {
  const { heading, content, handleOpenClose, openedAccordian, id } = props;
  
  return (
    <div className={id === openedAccordian ? 'accordBody opened' : 'accordBody' }>
      <button
        onClick={() => handleOpenClose(id)}
        className={id === openedAccordian ? 'accordHead active-btn' : 'accordHead'}
      >
        <img src={helpIcon} />
        {heading}
      </button>
      <div className={id === openedAccordian ? 'accordCnt active-accordCnt' : 'accordCnt'}>
      {/* <div className="accordCnt" style={id === openedAccordian ? {display: 'block'} : {display : 'none'}}> */}
        <div className="accordCntSpace">
          <p>{content}</p>
        </div>
      </div>
    </div>
  );
}



export default AccordianOne;
