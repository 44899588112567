import moment from "moment";
import Select from "react-select";

const MonthYearPicker = ({ month, onMonthSelect, onYearSelect },selectFromFuture, disableMontYear) => {

  let i;
  let years = [];

  if(selectFromFuture){
  for (i = moment().year(); i < (moment().year() + 100); i++) {
    years.push({ value: i, label: i });
  }
  }else{
    for (i = moment().year(); i >= moment().year() - 100; i--) {
      years.push({ value: i, label: i });
    }
  }
  return (
    <div className="custom-month-year-wrapper">
      <div className="month-selecter">
        <Select
          value={{label: month.format("MMM"), value: month.month()}}
          isSearchable={false}
          onChange={(e) => {
            {
              onMonthSelect(month, e.value)
            }
          }}
          isDisabled={disableMontYear}
          options={moment.months().map((label, value) => {
            return { value: value, label: label }
          })}
        >
        </Select>
      </div>
      <div className="year-selecter">
        <Select
          value={{label: month.format("YYYY"), value: month.year()}}
          isSearchable={false}
          onChange={(e) => onYearSelect(month, e.value)}
          options={years}
        >
        </Select>
      </div>
    </div>
  );
};

export default MonthYearPicker;
