import React from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import Button from "./FormComponent/Button";
import blueIcon from "../Assets/img/close-blue-icon.png";
import overviewPdfIcon from "../Assets/img/home/OverviewPdfIcon.png";
import companyPdf from "../Assets/affiliate-home/Tripello-Company-Overview-V1.0-2022.pdf";
import overviewVideoIcon from "../Assets/img/home/OverviewVideoIcon.png";
import presentationVideo from "../Assets/video/home/tripello-company-resentation.mp4";

function ModalHome({ openModal, closeModal }) {
  return (
    //opening Modal onclick

    <Modal
      isOpen={openModal}
      ariaHideApp={false}
      className="popup magic-section-popup magic-home-section-popup"
    >
      <div className="popup-content">
        <div className="popup-title">
          <Button
            onClick={closeModal}
            className="btn close"
            imageSrc={blueIcon}
          />
        </div>
        <div className="content-box">
          <div className="colCard">
            <div className="cardStyle">
              <Link className="link" target="_blank" to={companyPdf}></Link>
              <div className="cardImg">
                <div className="cardImg-wrap">
                  <img src={overviewPdfIcon} alt="image" />
                </div>
              </div>
              <div className="cardText">
                <h5>
                  <Link target="_blank" to={companyPdf}>
                    <strong>Tripello Company Overview </strong> (PDF)
                  </Link>
                </h5>
              </div>
            </div>
            <div className="cardStyle">
              <Link
                className="magic-popup-video-play link"
                to={presentationVideo}
                target="_blank"
              ></Link>
              <div className="cardImg">
                <div className="cardImg-wrap">
                  <img src={overviewVideoIcon} alt="image" />
                  {/* <video
                    id="magic-video-popup-player"
                    className="video-player"
                    poster={overviewVideoIcon}
                    autobuffer="true"
                  >
                    <source src={presentationVideo} type="video/mp4" />
                    Your browser does not support HTML5 video.
                  </video> */}
                </div>
              </div>
              <div className="cardText">
                <h5>
                  <Link
                    className="magic-popup-video-play"
                    to={presentationVideo}
                    target="_blank"
                  >
                    <strong>Tripello Company Overview </strong>
                    (VIDEO)
                  </Link>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalHome;
