import React, { useState } from "react";
import Button from "../FormComponent/Button";
import { has, pull, isEmpty } from "lodash";

function HotelNeighbourhoods({
  title,
  keys,
  filterOptions,
  filters,
  setFilters,
  searchHotel,
  appliedFilters,
  setAppliedFilters,
  mobileCategory,
  setOpenFilterTag,
  openFilterTag,
  openFilterTagMob,
  setOpenFilterTagMob,
  homePayload,
  handleClearNeighbourhoods,
  setHideFilter,
  setMobileFilter,
  setHideHeader
}) {
  const [showFilter, setshowFilter] = useState(false);
  localStorage.setItem("preserveNeighbourhood", JSON.stringify(filterOptions));
  // console.log(filterOptions)
  function handleFilterCheck(isChecked, value) {
    var hotelPayload = {}
    if(homePayload){
      hotelPayload = JSON.parse(localStorage.getItem("homePayload"));
    }else {
      hotelPayload = JSON.parse(localStorage.getItem("hotelPayload"));
    }
    if(!localStorage.getItem("hotelParentLocation")){
      localStorage.setItem("hotelParentLocation", JSON.stringify({label: hotelPayload.city_name, value: hotelPayload.region_id}));
    }
    
    hotelPayload.region_id = value.value;
    hotelPayload.city_name = value.label;
    
    if(homePayload){
      localStorage.setItem("homePayload", JSON.stringify(hotelPayload));
    }else {
      localStorage.setItem("hotelPayload", JSON.stringify(hotelPayload));
    }
    localStorage.setItem("neighbourhoodsSelection", JSON.stringify(value));
    localStorage.removeItem("hotel_search_key")
    searchHotel(1, null, true, null, false, value);
    if (mobileCategory) {
      setHideFilter(false);
      setMobileFilter(false);
      setHideHeader(false);
    }
  }

  const selectedNeighbourhood = JSON.parse(localStorage.getItem('neighbourhoodsSelection'))
  return (
    <>
      <div
        className={mobileCategory ? "flterOption" : "cardHead dflex"}
        onClick={() => setshowFilter(!showFilter)}
      >
        {mobileCategory ? <span>{title}</span> : <h6>{title}</h6>}
        <span className={`fa fa-angle-${showFilter ? "up" : "down"}`}></span>
      </div>
      <div className={`cardBody checkbox-card ${!showFilter ? "d-none" : ""}`}>
        {filterOptions &&
          filterOptions.map((ele, index) => {
            return (
              <label className="customRadio" key={`${index}_category`}>
                <input
                  type="radio"
                  name="categories"
                  checked={
                    selectedNeighbourhood?.value == ele.value
                      ? "checked"
                      : false
                  }
                  value={ele.value}
                  onChange={(e) =>{
                    handleFilterCheck(e.target.checked, ele)
                  }}
                />
                <span>
                  {ele.label}
                </span>
              </label>
            );
          })}
        {mobileCategory ? (
          " "
        ) : (
          <Button
            className="btnBorder btnTopMargin"
            type="button"
            onClick={handleClearNeighbourhoods}
          >
            Clear
          </Button>
        )}
        {/* <Button
          className="btnBorder btnTopMargin"
          type="button"
          onClick={handleApply}
        >
          Apply
        </Button> */}
      </div>
    </>
  );
}
export default HotelNeighbourhoods;
