function CarFeature({ feature, icon, available }) {
  if (!available) return <></>;
  return (
    <div className="lightGrayTag">
      <i className={icon} /> {feature}
    </div>
  );
}

export default CarFeature;
