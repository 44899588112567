import React from "react";

function HotelTheme({ title, themes, theme }) {
  return (
    <>
      <h4>{title}:</h4>
      <div className="row no-gutters">
        {themes &&
          themes.map((ele, index) => {
            if (index<3) {
              return (
                <div className="col" key={`${index}_theme`}>
                  <h3>
                    <img src={theme.find(e => e.id === ele.id)?.image || theme[0].image} alt="icon" />
                  </h3>
                  <h5>{ele.name}</h5>
                </div>
              );
            }
          })}
      </div>
    </>
  );
}

export default HotelTheme;
