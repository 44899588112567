
// Custom deboucing function
export const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

export const timeConvert = (num) => {
  let hours = num / 60;
  let rhours = Math.floor(hours);
  let minutes = (hours - rhours) * 60;
  let rminutes = Math.round(minutes);
  if(rhours.length === 1) {
    rhours = "0" + rhours
  }
  if(rminutes.length === 1) {
    rminutes = "0" + rminutes
  }
  return rhours + "h " + rminutes + "m";
};

export const flightTripType = (value) => {
  if(value === "roundTrip"){
    return "Round Trip"
  }else if(value === "oneWay"){
    return "Oneway Trip"
  }else if(value === "multiCity"){
    return "MultiCity Trip"
  }
};

export const cabinClassOptions = [
  { label: "Economy", value: "Y", classValue: "Y", name: "economy" },
  { label: "Premium Economy", value: "S", classValue: "S", name: "premiumEconomy" },
  { label: "Business", value: "C", classValue: "C", name: "business" },
  { label: "Premium Business", value: "J", classValue: "J", },
  { label: "First Class", value: "F", classValue: "F", name: "first_class" },
  { label: "Premium First", value: "P", classValue: "P" },
];