import React from "react";
import Modal from "react-modal";
import CloseBtn from "../../Assets/img/close-blue-icon.png";
import AttentionImg from "../../Assets/img/profile/warning.svg";
function AddGuestInfoModal({
  showAddUserInitModal,
  setShowAddUserInitModal,
  setShowAddUserFinalModal,
}) {
  return (
    <Modal
      isOpen={showAddUserInitModal}
      ariaHideApp={false}
      className={`popup attention-popup ${
        showAddUserInitModal ? "d-block" : ""
      }`}
    >
      <div className="popup-content">
        <div className="popup-title">
          <div className="text-icon">
            <div className="text-style">
              <h3>Attention</h3>
            </div>
            <div className="text-icon-flex img-style">
              <img src={AttentionImg} alt="attention-alert" />
            </div>
          </div>

          <button
            className="btn close"
            onClick={() => setShowAddUserInitModal(false)}
          >
            <img src={CloseBtn} alt="icon" />
          </button>
        </div>
        <div className="popup-content-box attention-info">
          <h3>
            We are glad to see that you are adding a{" "}
            <strong>NEW Authorized User.</strong>
          </h3>
          <h3>
            Please <strong>BE AWARE</strong> that you can only assign a Name to
            an Authorized User slot <strong>ONCE.</strong> Once added, the Users
            Name
            <strong> CANNOT</strong> be changed again or deleted.
          </h3>

          <h3>
            You will still be able to change the Users Contact information.
          </h3>
        </div>
        <div className="button-wrap">
          <button
            type="button"
            className="btn blue-btn"
            onClick={() => {
              setShowAddUserFinalModal(true);
              setShowAddUserInitModal(false);
            }}
          >
            OK
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default AddGuestInfoModal;
