import React from "react";
import ReactPaginate from "react-paginate";

function Pagination({ onPageChange, pageCount, forcePage }) {
  return (
    <ReactPaginate
      nextLabel={"Next"}
      previousLabel={"Prev"}
      breakLabel={"..."}
      pageCount={pageCount} //total_pages
      marginPagesDisplayed={1}
      pageRangeDisplayed={4}
      onPageChange={onPageChange}
      containerClassName={"pagination"}
      pageClassName={"pagi active"}
      previousClassName={"pagiPrevNext"}
      nextClassName={"pagiPrevNext"}
      breakClassName={"pagi"}
      activeClassName={"pagiActive"}
      disabledClassName={"pagiDisabled"}
      forcePage={forcePage}
    />
  );
}

export default Pagination;
