export const carsConstants = {
  SEARCH_CARS_REQUEST: "SEARCH_CARS_REQUEST",
  SEARCH_CARS_SUCCESS: "SEARCH_CARS_SUCCESS",
  SEARCH_CARS_FAILURE: "SEARCH_CARS_FAILURE",

  CARS_CONTRACT_REQUEST: "CARS_CONTRACT_REQUEST",
  CARS_CONTRACT_SUCCESS: "CARS_CONTRACT_SUCCESS",
  CARS_CONTRACT_FAILURE: "CARS_CONTRACT_FAILURE",

  BOOK_CAR_REQUEST: "BOOK_CAR_REQUEST",
  BOOK_CAR_SUCCESS: "BOOK_CAR_SUCCESS",
  BOOK_CAR_FAILURE: "BOOK_CAR_FAILURE",

  VALIDATE_ZIP_REQUEST: "VALIDATE_ZIP",
  VALIDATE_ZIP_SUCCESS: "VALIDATE_ZIP_SUCCESS",
  VALIDATE_ZIP_FAILURE: "VALIDATE_ZIP_FAILURE",
  
  RETRIVE_BOOKING_REQUEST: "RETRIVE_BOOKING_REQUEST",
  RETRIVE_BOOKING_SUCCESS: "RETRIVE_BOOKING_SUCCESS",
  RETRIVE_BOOKING_FAILURE: "RETRIVE_BOOKING_FAILURE",


  CAR_BOOKING_REQUEST_BACKOFFICE:"CAR_BOOKING_REQUEST_BACKOFFICE",
  CAR_BOOKING_BOOKING_SUCCESS_BACKOFFICE:"CAR_BOOKING_BOOKING_SUCCESS_BACKOFFICE",
  CAR_BOOKING_BOOKING_FAILURE_BACKOFFICE:"CAR_BOOKING_BOOKING_FAILURE_BACKOFFICE",
  
  GET_BOOKING_REQUEST_BACKOFFICE:"GET_BOOKING_REQUEST_BACKOFFICE",
  GET_BOOKING_BOOKING_SUCCESS_BACKOFFICE:"GET_BOOKING_BOOKING_SUCCESS_BACKOFFICE",
  GET_BOOKING_BOOKING_FAILURE_BACKOFFICE:"GET_BOOKING_BOOKING_FAILURE_BACKOFFICE",
  
};
