import React, { useState } from "react";
import HeaderNavbar from "../../Component/HeaderNavbar";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import Scrollbars from "react-custom-scrollbars";
import AcademyTab from "../Tabs/AcademyTab";

function Tutorial() {
  return (
    <div className="siteBg academy-wrapper FaqPageWrap Terms-wrapper">
      <div className="pageWrap faq-scroll">
        <div className="fixedHeader">
          <HeaderNavbar useBlueIcon={true}/>
        </div>
      </div>
      <section className="mainContent">
        <Scrollbars
          autoHeight={true}
          autoHeightMin={120}
          autoHeightMax={`calc(100%)`}
          className="pageInternalScroll"
        >
          <div className="container pageInternalScrollTopPadding">
            <div className="row no-gutters rightContent-bottom">
              <AcademyTab name={"tutorial"} />
              <div className="col rightContent">
                <div className="welcome-text-wrap">
                  <h1>COMING SOON!!!</h1>
                  </div>
              </div>
            </div>
          </div>
        </Scrollbars>
      </section>
      <FooterWithMediaIcon className="white-icon" iconClassName="icon-white" />
    </div>
  );
}

export default Tutorial;
