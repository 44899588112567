 
export const marketingSiteConstants = {
    GET_WALLET_TRANSACTION_REQUEST: "GET_WALLET_TRANSACTION_REQUEST",
    GET_WALLET_TRANSACTION_SUCCESS: "GET_WALLET_TRANSACTION_SUCCESS",
    GET_WALLET_TRANSACTION_FAILURE: "GET_WALLET_TRANSACTION_FAILURE",

    GET_WALLET_TRANSACTION_DETAILS_SUCCESS:"GET_WALLET_TRANSACTION_DETAILS_SUCCESS",
    GET_WALLET_TRANSACTION_DETAILS_REQUEST : "GET_WALLET_TRANSACTION_DETAILS_REQUEST",
    GET_WALLET_TRANSACTION_DETAILS_FAILURE : "GET_WALLET_TRANSACTION_DETAILS_FAILURE"


}