import http from "./http";

async function affiliateSignupService(payload) { 
  const res = await http
    .post(`${process.env.REACT_APP_BASE_URL_USER}/api/v1/users`, payload);
  return res;
}

async function getUserByMemberIdService(id) { 
  const res = await http
    .get(`${process.env.REACT_APP_BASE_URL_USER}/api/v1/get-user/${id}`, {
      params: {use_base_url: true}
    });
  return res;
}

async function affiliateUpgradeMembershipService(payload) { 
  const res = await http
    .post(`${process.env.REACT_APP_BASE_URL_USER}/api/v1/upgrade-user/v2`, {
      ...payload,
      params: {}
    });
  return res;
}

export const affiliateService = {
  affiliateSignupService,
  affiliateUpgradeMembershipService,
  getUserByMemberIdService
};
