import React from "react";
import TableHeading from "./TableHeading";
import TableRowData from "./TableRowData";
import { useDispatch, useSelector } from "react-redux";

function CommissionTable({ selectedTab }) {
  const commisionSummary = useSelector(
    (state) => state.cardsReducer.commissionSummary
  );

  const tableHeading2 = [
    {
      type: "freemium_member_travel",
      headings: [
        { label: "FREEMIUM MEMBER NO", id: "member_number" },
        { label: "BOOKING DATE", id: "booking_date" },
        { label: "TRAVEL DATE", id: "travel_date" },
        { label: "TRAVEL SAVINGS", id: "travel_saving" },
        { label: "COMMISSION", id: "commission" },
        { label: "STATUS", id: "status" },
      ],
    },
    {
      type: "member_to_member_referrals",
      headings: [
        { label: "REFERRING MEMBER NO", id: "referring_member_number" },
        { label: "NEW MEMBER NO", id: "new_member_number" },
        { label: "ACTIVATION DATE", id: "activation_date" },
        { label: "COMMISSION", id: "commission" },
        { label: "STATUS", id: "status" },
      ],
    },
    {
      type: "premium_member_sales",
      headings: [
        { label: "PREMIUM MEMBER NO", id: "member_number" },
        { label: "ACTIVATION DATE", id: "activation_date" },
        { label: "COMMISSION", id: "commission" },
        { label: "STATUS", id: "status" },
      ],
    },
    {
      type: "premium_plus_member_sales",
      headings: [
        { label: "PREMIUM PLUS MEMBER NO", id: "member_number" },
        { label: "ACTIVATION DATE", id: "activation_date" },
        { label: "COMMISSION", id: "commission" },
        { label: "STATUS", id: "status" },
      ],
    },
    {
      type: "affiliate_member_sales",
      headings: [
        { label: "AFFILIATE NO", id: "member_number" },
        { label: "ACTIVATION DATE", id: "activation_date" },
        { label: "COMMISSION", id: "commission" },
        { label: "STATUS", id: "status" },
      ],
    },
  ];

  const finalHeading = tableHeading2?.filter((ele) => {
    return ele.type == selectedTab?.commission_type;
  });

  const res = [];
  commisionSummary?.data?.results?.map((ele) => {
    const temp = [];
    finalHeading[0].headings.map((key, index) => {
      if (ele[key.id]) {
        temp.push({ title: key.label, value: ele[key.id] });
      }else {
        temp.push({ title: key.label, value: "-" });
      }
    });
    res.push(temp);
  });
  return (
    <div className="content-wrapper">
      <TableHeading tableHeading={finalHeading[0]?.headings || []} />
      {res?.map((item, index) => {
        return <TableRowData key={index} item={item} />;
      })}

      {commisionSummary?.data?.results?.length == 0 && res?.length == 0 && (
        <>
          <div className="data-not-found">
            <h2>Data not found</h2>
          </div>
        </>
      )}
    </div>
  );
}

export default CommissionTable;