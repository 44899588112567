export const premiumCheckoutInitialValues = {
    title: "",
    firstName: "",
    lastName: "",
    login_name: "",
    email: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
    nationality: "",
    addressOne: "",
    addressTwo: "",
    city: "",
    state: "",
    zipCode: "",
    promoCode: "",
    hasPromo: false,
    terms: false,
    timezone: "Australia/Melbourne",
    //card
    cardHolderName: "",
    security_code : "", 
    exp_month : "",
    exp_year : "",
    billing_zip : "",
    credit_number:"",
    billing_country:"",
    mmyy : ""
  };

  // export const newUserPremiumCheckoutInitialValues = {
  //   nationality: "",
  //   addressOne: "",
  //   addressTwo: "",
  //   city: "",
  //   state: "",
  //   zipCode: "",
  //   promoCode: "",
  //   hasPromo: false,
  //   terms: false,
  //   timezone: "Australia/Melbourne",
  //   //card
  //   cardHolderName: "",
  //   security_code : "", 
  //   exp_month : "",
  //   exp_year : "",
  //   billing_zip : "",
  //   credit_number:"",
  //   billing_country:"",
  //   mmyy : ""
  // };

  