import React, { useState } from "react";
import Button from "./Button";
import { BiMinus } from "react-icons/bi";
import { BsPlus } from "react-icons/bs";

const Counter = ({ initialCount, type, updateRoom, index, CounterNumber }) => {

  const [count, setCount] = useState(initialCount ? initialCount : 0);
  const increment = (count) => {
    setCount(count + 1);
    updateRoom(count + 1, type, index);
  };

  const decrement = (count) => {
    if (count > 0) {
      setCount(count - 1);
      updateRoom(count - 1, type, index);
    }
  };

  return (
    <div className="counter-btn-group">
      <Button
        type="button"
        className="btn-minus"
        disabled={(type === "adultCount" && count < 2) || (type !== "adultCount" && count < 1)}
        onClick={() => {
          if ((type === "adultCount" && count > 1) || (type !== "adultCount" && count > 0)) {
            decrement(count);
          }
        }
        }
      >
        <BiMinus />
      </Button>
      <h6>{count}</h6>

      <Button
        type="button"
        className="btn-plus"
        onClick={() => {
          if ((type === "infantCount" && count < CounterNumber[0].initialValue) || type !== "infantCount" && count < 8) {
            increment(count)
          }
        }}

        disabled={!((type === "infantCount" && count < CounterNumber[0].initialValue) || type !== "infantCount" && count < 8)}
      >
        <BsPlus />
      </Button>
    </div>
  );
};

export default Counter;
