import { combineReducers } from "redux";
import authReducer from "./authReducer";
import commonReducer from "./commonReducer";
import currencyLanguageReducer from "./languageCurrency";
import hotelReducer from "./hotelReducer";
import flightReducer from "./flightReducer";
import carsReducer from "./carsReducer";
import cardsReducer from "./cardsReducer";
import affiliateReducer from "./affiliateReducer";
import marketingSiteReducer from "./marketingSiteReducer";
import supportReducer from "./supportReducer";

const reducers = (state, action) => {
  switch (action.type) {
    default: {
      const combinedReducer = combineReducers({
        authReducer,
        commonReducer,
        currencyLanguageReducer,
        hotelReducer,
        flightReducer,
        carsReducer,
        cardsReducer,
        affiliateReducer,
        marketingSiteReducer,
        supportReducer
      });
      return combinedReducer(state, action);
    }
  }
};
export default reducers;
