import moment from "moment";
import Select from "react-select";

const MonthPicker = ({ month, onMonthSelect, onYearSelect },selectFromFuture, disableMontYear) => {
  return (
    <div className="custom-month-year-wrapper">
      <div className="month-selecter">
        <Select
          value={{label: month.format("MMMM"), value: month.month()}}
          isSearchable={false}
          isDisabled={disableMontYear}
          onChange={(e) => {
            {
              onMonthSelect(month, e.value)
            }
          }}
          options={moment.months().map((label, value) => {
            return { value: value, label: label }
          })}
        >
        </Select>
      </div>
    </div>
  );
};

export default MonthPicker;
