import http from "./http";

function dashboardSummary(id,payload,selectedTab) {
  return http.post(
    selectedTab  == "freemium_member_travel" ? `${process.env.REACT_APP_WALLET_BASE_URL}/api/wallet/${id}/booking-commissions` : `${process.env.REACT_APP_BASE_URL_USER}/api/v1/referrals/${id}` ,
    {
      ...payload,
      params: { use_wallet_api_key : selectedTab  == "freemium_member_travel" ? true : false },
    }
  );
}

function assignCard(data) {
  return http.post(
    `${process.env.REACT_APP_BASE_URL_CARDS}/api/send-affiliate-link`,
    {
      ...data,
      params: { use_cards_api_key: true },
    }
  );
}

export const cardsService = { dashboardSummary, assignCard };