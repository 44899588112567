import React from "react";
import { has } from "lodash";
import { useSelector } from "react-redux";
function FlightFilterTag({
  filters,
  clearFilter,
  searchFlight, 
}) {

  const flightSearchData = useSelector(
    (state) => state.flightReducer?.flightSearchData,
  );

  const currentfilters = flightSearchData?.request?.filters;

  const handleRemoveFilter = (type) => {
    const newFilters = filters;
    
    if (type === "time") {
      delete newFilters.outboundDepatureTime;
      delete newFilters.outboundArrivalTime;
      delete newFilters.inboundDepatureTime;
      delete newFilters.inboundArrivalTime;
      clearFilter({ ...newFilters });
    }else{
      delete newFilters[type]; 
      clearFilter({ ...newFilters });
    }
    searchFlight(newFilters);
  };



  return (
    <div className="filter-display">
      <div className="filter-btn">
        {currentfilters?.price && (
          <div className="filter-name">
            Price
            <button
              className="icon-close"
              onClick={() => {
                handleRemoveFilter("price");
              }}
            ></button>
          </div>
        )}
        {(currentfilters?.stops && currentfilters?.stops[0] != null)&&
          <div className="filter-name">
            Stops
            <button
              className="icon-close"
              onClick={() => {
                handleRemoveFilter("stops");
              }}
            ></button>
          </div>
        }

        {(currentfilters?.airlines?.length < flightSearchData?.filtersAvailable?.airlines?.length) && (
          <div className="filter-name">
            Airlines
            <button
              className="icon-close"
              onClick={() => { 
                handleRemoveFilter("airlines");
              }}
            ></button>
          </div>
        )}

        {( currentfilters?.alliances) && (
          <div className="filter-name">
            Alliances
            <button
              className="icon-close"
              onClick={() => {
                handleRemoveFilter("alliances"); 
              }}
            ></button>
          </div>
        )}

        {currentfilters?.connections && (
          <div className="filter-name">
            Connecting Airports
            <button
              className="icon-close"
              onClick={() => {
                handleRemoveFilter("connections");
              }}
            ></button>
          </div>
        )}
        { (currentfilters?.outboundDepatureTime || currentfilters?.outboundArrivalTime || currentfilters?.inboundDepatureTime || currentfilters?.inboundArrivalTime )  && (
          <div className="filter-name">
            Time
            <button
              className="icon-close"
              onClick={() => {
                handleRemoveFilter("time"); 
              }}
            ></button>
          </div>
        )}
        {/* {has(filters, "arrival_time") && openArrivalTag && (
          <div className="filter-name">
            Arrival Time
            <button
              className="icon-close"
              onClick={() => {
                handleRemoveFilter("arrival_time");
              }}
            ></button>
          </div>
        )}
        {has(filters, "departure_time") && openDepartureTag && (
          <div className="filter-name">
            Departure Time
            <button
              className="icon-close"
              onClick={() => {
                handleRemoveFilter("departure_time");
              }}
            ></button>
          </div>
        )} */}
        {currentfilters?.duration && (
          <div className="filter-name">
            Duration
            <button
              className="icon-close"
              onClick={() => {
                handleRemoveFilter("duration");
              }}
            ></button>
          </div>
        )}
        {currentfilters?.emissions && currentfilters?.emissions[0]== "Low" && (
          <div className="filter-name">
            Emissions
            <button
              className="icon-close"
              onClick={() => {
                handleRemoveFilter("emissions");
              }}
            ></button>
          </div>
        )}
      </div>
    </div>
  );
}

export default FlightFilterTag;
