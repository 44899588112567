import React from "react"; 


function CommissionBox({ item, className, isFirstEle, value }) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return (
    <div className={`col border-bottom ${className ? className : ""}`}>
      <div className="content">
        <h6>
          {" "}
          {item.title} <br /> {item.subtitle}{" "}
        </h6>
        {isFirstEle ? (
          <h2> {value} </h2>
        ) : (
          <h5> {value} </h5>
        )}
      </div>
    </div>
  );
}

export default CommissionBox;