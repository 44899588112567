import React from "react";

// Premium member inivites
function PremiumMemberInvites({
  className,
  para,
  heading,
  memberInviteImg,
  para1,
}) {
  return (
    <div className={" premium-members-invites " + className}>
      <div className="row no-gutters">
        <div className="col leftContent">
          <div className="container">
            <div className="content-title">
              <h1>{heading}</h1>
              <div className="image desktop-hide">
                <img src={memberInviteImg} alt="icon" />
              </div>
            </div>
            <p>{para}</p>
            {para1 && <p>{para1}</p>}
          </div>
        </div>
        <div className="col rightContent">
          <div className="imgContent">
            <div className="image">
              <img src={memberInviteImg} alt="icon" />
            </div>
            <div className="content  right-content">
              <p>Get up to</p>
              <h2>$50</h2>
              <p>For each signed Premium Member</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PremiumMemberInvites;