import React, { useEffect, useState } from "react";
import Button from "../FormComponent/Button";
import InputRange from "react-input-range";
import { has } from "lodash";
import { numFormatter } from "../../Util/util";
import { useSelector } from "react-redux";

function PriceRange({
  title,
  minPrice,
  maxPrice,
  setFilters,
  filters,
  searchHotel,
  mobileCategory,
  priceRef,
}) {
  const [showFilter, setshowFilter] = useState(false);
  const [rangeVal, setRangeVal] = useState({
    min: 0,
    max: 1,
  });

  const hotelSearchData = useSelector((state) => state.hotelReducer.hotelSearchData);

  useEffect(() => {
    if (has(filters, "max_price") && has(filters, "min_price")) {
      setRangeVal({ min: filters.min_price, max: filters.max_price });
    }else{
    if(!hotelSearchData) return 
      const minMax = hotelSearchData?.filters_available?.filter((filtertype)=> filtertype.name === "price") 
      if(minMax?.length > 0){
        setRangeVal({ min: minMax[0]?.options[0]?.value, max: minMax[0]?.options[1]?.value });
      }
    }
    // else {
    //   setRangeVal({ min: Number(minPrice), max: Number(maxPrice) });
    // }
    // if (showFilter) {
    //   setRangeVal({ min: Number(minPrice), max: Number(maxPrice) });
    // }
  }, [hotelSearchData]);


  // useEffect(() => {
  //   if(!hotelSearchData) return 
  //   const minMax = hotelSearchData?.filters_available?.filter((filtertype)=> filtertype.name === "price") 
  //   if(minMax?.length > 0){
  //     setRangeVal({ min: minMax[0]?.options[0]?.value, max: minMax[0]?.options[1]?.value });
  //   }
  // }, [hotelSearchData])


  function handleRangeChange(value) {
    setRangeVal({ min: value.min, max: value.max });
    setFilters({ ...filters, min_price: value.min, max_price: value.max });
  }

  function handleResetRange() {
    if (has(filters, "min_price") || has(filters, "max_price")) {
      setRangeVal({ min: Number(minPrice), max: Number(maxPrice) });
      const newFilters = filters;
      delete newFilters.min_price;
      delete newFilters.max_price;
      setFilters({ ...newFilters });
      // searchHotel(null, null, true);  
      let hotelFilter = JSON.parse(window.localStorage.getItem("hotelFilter")) && JSON.parse(window.localStorage.getItem("hotelFilter"))
      let newHotelFilter = { ...hotelFilter };
      delete newHotelFilter.min_price
      delete newHotelFilter.max_price
      setFilters({ ...newHotelFilter });
      window.localStorage.setItem("hotelFilter", JSON.stringify(newHotelFilter));
      searchHotel(1)
    }
  }

  function handleSearchByPrice() {
    searchHotel(1);
  }

  const currencyAndLanguage =
    (window.localStorage.getItem("currencyAndLanguage") &&
      JSON.parse(window.localStorage.getItem("currencyAndLanguage"))) ||
    {};

  const currencySymbol =
    currencyAndLanguage?.currencyLanguageReducer?.data?.currency?.symbol || "$";

  return (
    <>
      <div
        className={mobileCategory ? "flterOption" : "cardHead dflex"}
        onClick={() => setshowFilter(!showFilter)}
      >
        {mobileCategory ? <span>{title}</span> : <h6>{title}</h6>}
        <span className={`fa fa-angle-${showFilter ? "up" : "down"}`}></span>
      </div>
      <div className={`priceRange ${!showFilter ? "d-none" : ""}`}>
        <h4>
          {currencySymbol}
          {numFormatter(rangeVal.min)}
        </h4>
        <h4>
          {currencySymbol}
          {numFormatter(rangeVal.max)}
        </h4>
      </div>
      <div className={`cardBody  ${!showFilter ? "d-none" : ""}`}>
        <div className="inputRange">
          <InputRange
            maxValue={Number(maxPrice)}
            minValue={Number(minPrice)}
            value={rangeVal}
            onChange={(value) => handleRangeChange(value)}
            formatLabel={(e) => { }} //don't remove this it is for removing lable from the range input
            ref={priceRef}
            setRangeVal={setRangeVal}
          />
        </div>
        <div className="priceBtn">
          <Button
            className="btnBorder"
            type="button"
            onClick={handleSearchByPrice}
          >
            Apply
          </Button>
          {mobileCategory ? (
            ""
          ) : (
            <Button
              className="linkColor font14 cancelBtn"
              type="button"
              onClick={handleResetRange}
            >
              Cancel
            </Button>
          )}
        </div>
      </div>
    </>
  );
}

export default PriceRange;
