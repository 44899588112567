import React from "react";
import DateNTime from "./DateNTime";
import InfoBox from "./InfoBox";
import PriceDetails from "./PriceDetails";
import moment from "moment";

function CarDetails({item}) {
  console.log("item",item)

  // car details
  return (
    <div className="card2 carsCard">
      <div className="dflex alignFlexStart">
        <div className="iconWrap">
          <span className="icon-shape">
            <span className="path1"></span>
            <span className="path2"></span>
          </span>
        </div>
        <div className="cardContent">
          <h3 className="cartTitle">{item?.booking_json?.car_info?.example}</h3>
          <div className="dflex stretch">
            <div className="innerCnt">
              <div className="topSection dflex justifyStart copyText">
               {item?.booking_json?.car_info?.description}
              </div>
             {item?.booking_json?.car_info?.mileage === "unlimited" && <div className="colorText font14">
                <i className="icon-speedometer"></i> Unlimited Mileage
              </div>}
              <div className="cardCol no-gutters">
                <DateNTime
                  iconName={"icon-invitation"}
                  label={"Pick-up:"}
                  date={moment(item?.booking_json?.pickup_date).format("DD-MM-YYYY")}
                  time={item?.booking_json?.pickup_time}
                />
                <DateNTime
                  iconName={"icon-invitation"}
                  label={"Drop-off:"}
                  date={moment(item?.booking_json?.dropoff_date).format("DD-MM-YYYY")}
                  time={item?.booking_json?.dropoff_time}
                />
              </div>
              <div className="locationWrap">
                <div className="smlText startPoint">
                  <i className="icon-location"></i>{item?.booking_json?.pickup_address}
                </div>
                <div className="smlText endPoint">
                  <i className="icon-location"></i>{item?.booking_json?.dropoff_address}
                </div>
              </div>
            </div>
            <div className="infoBox">
              <div className="dflex mb-10 bookingIdWrap">
                <span className="font14 lightText">Booking ID:</span>
                <span className="font14 darkText">{item?.booking_json?.booking_id}</span>
              </div>
              <div className="keyValueWrap">
                <div>
                  <InfoBox
                    divClassName={"mb-5"}
                    iconName="icon-group font12"
                    detail1={"People:"}
                    detail2={`${item?.booking_json?.car_info?.passengers} people`}
                  />
                  <InfoBox
                    divClassName={"mb-5"}
                    iconName="icon-transmission"
                    detail1={"Transmission: "}
                    detail2={item?.booking_json?.car_info?.automatic_transmission ? "Automatic" : "Manual"}
                  />
                  <InfoBox
                    iconName={"icon-icon3 font17"}
                    detail1="Conditioning:"
                    detail={item?.booking_json?.car_info?.air_conditioning}
                    iconName2="icon-tick2 font12 mobileHide"
                  />
                </div>
                <PriceDetails
                  details={"Total:"}
                  price={item?.price}
                  currency={item?.currency}
                  text="View & Manage"
                  nextPage={`/cars-booking-confirmation/${item?.id}`}
                  // nextPage={`/cars-booking-confirmation`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CarDetails;
