import React, { useEffect, useState } from "react";
import CheckBoxFilter from "../../Component/Filters/CheckBoxFilter";
import SortBy from "../../Component/Filters/SortBy";
import Button from "../../Component/FormComponent/Button";
import { carsSortOptions } from "../../Stores/Constants/siteConstants/dropdownConstants";

function CarsListingFilters({
  carsSortingFunction,
  carCompaniesOptions,
  carFeaturesOptions,
  vehicleTypeOptions,
  setSortingType,
  setAllFilters,
  resetFilters,
  allFilters,
  openVehicleTag,
  setOpenVehicleTag,
  openCarTag,
  setOpenCarTag,
  openCarFeatureTag,
  setOpenCarFeatureTag,
  selectedFilter,
  setSelectedFilter,
  setShowMobileFilter,
  showMobileFilter
}) {
  const [showMore, setShowMore] = useState(false);


  const applyFilters = () => {
    setAllFilters(selectedFilter);
  };

  const clearFilters = (field) => {
    if(!field){
      setSelectedFilter({
        "Vehicle Type": [],
        "Car Features": [],
        "Car Companies": [],
      });
      resetFilters();
      setShowMobileFilter(false);
    }else if(field === "Vehicle Type"){
      setOpenVehicleTag((prev) => !prev);
      setSelectedFilter({ ...selectedFilter, "Vehicle Type": [] });
      setAllFilters({ ...selectedFilter, "Vehicle Type": [] });
    }else if(field === "Car Features"){
      setOpenCarFeatureTag((prev) => !prev);
      setSelectedFilter({...selectedFilter, ["Car Features"]: []});
      setAllFilters({...selectedFilter, ["Car Features"]: []});
    }else if(field === "Car Companies"){
      setOpenCarTag((prev) => !prev);
      setSelectedFilter({...selectedFilter, ["Car Companies"]: []});
      setAllFilters({...selectedFilter, ["Car Companies"]: []});
    }
    
    
  };

  return (
    <>
      <Button
        onClick={() => setShowMobileFilter(true)}
        className="mobileFilterBtn mobileShow"
      >
        <i className="fa fa-sliders" /> Filter &amp; Sorting
      </Button>
      <div
        className={`mobileSortFilter mobilePopup showPopup ${
          showMobileFilter ? "d-block" : ""
        }`}
      >
        <div className="mobileShow">
          <Button
            className={`clearFilter ${showMobileFilter ? "" : ""}`}
            onClick={() => clearFilters()}
            type="button"
          >
            Clear
          </Button>
          <Button
            className="closePopup closePopup2"
            onClick={() => setShowMobileFilter(false)}
            type="button"
          >
            <span className="icon-close" />
          </Button>
          <div className="applyBtnMobile">
            <Button
              className="btn"
              onClick={() => {
                setShowMobileFilter(false);
                applyFilters();
                setShowMore(false);
              }}
            >
              Apply
            </Button>
          </div>
        </div>

        <div className="sortFilter">
          <div className="vehicle-filter">
            <h4 className="mobilePopTitle filterTitle mobileShow">Filter by</h4>

            {/* Vehicle Types filter dropdown */}
            <CheckBoxFilter
              label="Vehicle Type"
              filterOptions={vehicleTypeOptions}
              allFilters={allFilters}
              setAllFilters={setAllFilters}
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
              applyFilters={applyFilters}
              showMore={showMore}
              setShowMore={setShowMore}
              openTag={openVehicleTag}
              setOpenTag={setOpenVehicleTag}
              clearFilters={clearFilters}
            />

            {/* Car Companies filter dropdown */}
            <CheckBoxFilter
              label="Car Companies"
              filterOptions={carCompaniesOptions}
              allFilters={allFilters}
              setAllFilters={setAllFilters}
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
              applyFilters={applyFilters}
              showMore={showMore}
              setShowMore={setShowMore}
              openTag={openCarTag}
              setOpenTag={setOpenCarTag}
              clearFilters={clearFilters}
            />

            {/* Car features filter dropdown */}
            <CheckBoxFilter
              label="Car Features"
              filterOptions={carFeaturesOptions}
              allFilters={allFilters}
              setAllFilters={setAllFilters}
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
              applyFilters={applyFilters}
              showMore={showMore}
              setShowMore={setShowMore}
              openTag={openCarFeatureTag}
              setOpenTag={setOpenCarFeatureTag}
              clearFilters={clearFilters}
            />
          </div>

          {/* Short By filter */}
          <div className="sortby-mobile">
            <h4 className="mobilePopTitle">Sort by</h4>
            <SortBy
              label="Sort by"
              setSortingType={setSortingType}
              sortOptions={carsSortOptions}
              carsSortingFunction={carsSortingFunction}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default CarsListingFilters;
