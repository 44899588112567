import React from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import CloseBtn from "../../Assets/img/close-blue-icon.png";
import { authActions, commonActions } from "../../Stores/Actions";


function ProfileCancellation({ isOpen, handleCancellationModal }) {
  
  const dispatch = useDispatch();

  const logout = async () => {
     window.localStorage.clear();
      dispatch(commonActions.loaderText("Logging out..."));
      await dispatch(authActions.logoutRequest());
  }

  return (
    <Modal
      isOpen={isOpen}
      ariaHideApp={false}
      className={`popup profile-cancellation-popup ${isOpen?"d-block":""}`}
    >
      <div className="popup-content">
        <div className="popup-title">
          <h3>Membership Cancellation</h3>
          <button 
            className="btn close close-btn"
            onClick={() => {
              handleCancellationModal(false);
              // loggin out here
              logout();
            }}
          >
            <img src={CloseBtn} alt="icon" />
          </button>
        </div>
        <div className="popup-content-box">
          <h3>
            We are sorry to see you go. Your cancellation is being processed and
            your Membership will be downgraded to a Freemium Membership. You can
            always come back again and upgrade later. We will sent you an email
            once your Cancellation is completed.
          </h3>
        </div>
      </div>
    </Modal>
  );
}

export default ProfileCancellation;
