// Array list of hotel Faqs
export const HotelFaqList = [
	{
		id: 0,
		title: 'What is the minimum age required to check-in?',
		description: 'You must be at least 21 years old to book a hotel through Tripello.',
	},
	{
		id: 1,
		title: 'When can I check-in to my Room?',
		description: 'Check-in times are listed on your reservation confirmation. The option to check-in early or late is dependent on the hotel\'s policies and availability. You may contact your hotel directly to inquire about early or late check-in.',
		description2: 'Please keep in mind that while most reservations are guaranteed for late arrival, failure to arrive on the first day of your reservation (before midnight hotel time on your check-in date) may prompt the hotel to cancel your reservation if you have a multiple night reservation. Contact your hotel to let them know you will check-in late.',
		description3: 'You will find your hotel’s contact information on your itinerary. You can find your itinerary by going to Bookings in the Tripello Portal.',
	},
	{id: 2,
		title: 'What if my flight does not go as scheduled?',
		description: 'If you are going to be later than expected, please contact your hotel directly to put a hold on your room.',
	},
	{
		id: 3,
		title: 'What if I have a Special Request?',
		description: 'Throughout your booking experience, there are many opportunities to review your hotel\'s amenities. Be sure to confirm that your hotel satisfies your needs prior to confirming your reservation. If you have any questions about the amenities, please contact your hotel directly.',
		description2: 'If you need a crib or other special bedding for your infant or child, or other special requests, please contact the hotel directly and arrange for these items prior to arrival. Each hotel has their own policy on limitations, availability, and costs. If there are specific requests, such as pet-friendly, we recommend that you purchase a hotel where all of those specific amenities are displayed prior to the checkout page.',
		description3: 'Depending on the reservation you make, you may have the option to request a specific bed type prior to booking. On the hotel description page, where all available purchase rates are listed, you may be able to select a specific bed type associated with a specific rate.',
		description4: 'Requests for other special needs (including preferences for smoking or non-smoking rooms) cannot be guaranteed, but can be requested through your hotel after your reservation is confirmed.',
		description5: 'Special requests are at the discretion of the hotel and subject to availability. Keep in mind that if available, there may be an extra charge payable directly to the hotel. Your confirmed hotels phone number is listed on your confirmation page.',
	},
	{
		id: 4,
		title: 'Can I cancel or change my Hotel Reservation?',
		description: 'Your ability to change or cancel your hotel reservation depends on the type of reservation you have.',
		description2: 'Please review the hotel’s cancellation policy carefully to determine if changes and cancellations are allowed. If you haven’t booked yet, we recommend reviewing this policy carefully before booking.',
		headingLine1: 'How do I make a Change to the Name on my Reservation?',
		headingLine2: 'Unfortunately, you cannot change the reservation name after purchase.',
		headingLine3: 'How can I add a Name to my Reservation?',
		headingLine4: 'Please contact your confirmed hotel to make note of the additional name on the reservation.'
        
	},
	{
		id: 5,
		title: 'What do I need to bring to the hotel for check-in?',
		description: ' You will need the following when checking in to the hotel:',
		list1: 'Government-issued photo identification such as driver\'s license or passport. Other valid photo ID may be acceptable depending on the hotel.',
		list2: 'Hotel confirmation number, which can be found on your itinerary. You can find your itinerary by going to Bookings on the Tripello Portal.',
		list3: ' Valid credit card to guarantee the reservation and for incidental charges or fees that may be incurred during your hotel stay. These charges may be mandatory such as resort fees or optional as in parking, phone calls or minibar charges which are not included in your price per night. If you do not have a credit card, some properties may allow you to leave a cash deposit for incidentals. If you plan to leave a cash deposit, please call your confirmed hotel to determine if they allow it and what the required amount is.',
	},
	{
		id: 6,
		title: 'How do I find my Tripello itinerary?',
		description: 'To view & print your itinerary:',
		list1: 'Login into the Tripello Portal at www.Tripello.com',
		list2: 'Click Bookings on the top of the page.',
		list3: 'Here you will be able to view, print or email your itinerary.',
	},

];
