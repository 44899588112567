import React from "react";
import { has } from "lodash";
function FilterDisplay({
  filters,
  clearFilter,
  searchHotel,
  priceRef,
  priceRefMob,
  setStarRemove,
  starRemove,
  openCategoryTag,
  setOpenCategoryTag,
  setOpenAmenityTag,
  openAmenityTag,
  openDistanceTag,
  setOpenDistanceTag
}) {
  const handleRemoveFilter = (type) => {
    const newFilters = filters;
    if (type === "min_price") {
      if (priceRef.current)
        priceRef.current.props.setRangeVal({
          min: priceRef.current.props.minValue,
          max: priceRef.current.props.maxValue,
        });
      if (priceRefMob.current)
        priceRefMob.current.props.setRangeVal({
          min: priceRefMob.current.props.minValue,
          max: priceRefMob.current.props.maxValue,
        });
      delete newFilters[type];
      delete newFilters.max_price;
      clearFilter({ ...newFilters });
    }
    if (type === "category_ids") {
      delete newFilters[type];
      clearFilter({ ...newFilters });
    }
    if (type === "amenities") {
      delete newFilters[type];
      clearFilter({ ...newFilters });
    }
    if (type === "rating") {
      delete newFilters[type];
      clearFilter({ ...newFilters });
      setStarRemove(!starRemove);
    } else {
      delete newFilters[type];
      clearFilter({ ...newFilters });
    }
    searchHotel(newFilters);
  };
  return (
    <div className="filter-display">
      <div className="filter-btn">
        {has(filters, "id") && (
          <div className="filter-name">
            Hotel Name
            <button
              className="icon-close"
              onClick={() => handleRemoveFilter("id")}
            ></button>
          </div>
        )}

        {(has(filters, "max_price") || has(filters, "max_price")) && (
          <div className="filter-name">
            Price
            <button
              className="icon-close"
              onClick={() => handleRemoveFilter("min_price")}
            ></button>
          </div>
        )}

        {has(filters, "rating") && (
          <div className="filter-name">
            Star Ratings
            <button
              className="icon-close"
              onClick={() => handleRemoveFilter("rating")}
            ></button>
          </div>
        )}
        {has(filters, "distance") && openDistanceTag && (
          <div className="filter-name">
            Distance
            <button
              className="icon-close"
              onClick={() => {
                handleRemoveFilter("distance");
                setOpenDistanceTag(!openDistanceTag);
              }}
            ></button>
          </div>
        )}
        {has(filters, "category_ids") && openCategoryTag && (
          <div className="filter-name">
            Categories
            <button
              className="icon-close"
              onClick={() => {
                handleRemoveFilter("category_ids");
                setOpenCategoryTag(!openCategoryTag);
              }}
            ></button>
          </div>
        )}

        {has(filters, "amenities") && openAmenityTag && (
          <div className="filter-name">
            Amenities
            <button
              className="icon-close"
              onClick={() => {
                handleRemoveFilter("amenities");
                setOpenAmenityTag(!openAmenityTag);
              }}
            ></button>
          </div>
        )}
      </div>
    </div>
  );
}

export default FilterDisplay;
