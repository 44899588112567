import * as Yup from "yup";

export const loginValidation = () => {
  return Yup.object().shape({
    email: Yup.string("")
      .required("Required"),
    password: Yup.string("")
      .required("Required")
      // .matches(
      //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/,
      //   "Password must Contain 6 Characters"
      // ),
  });
};
