import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LogoWhite from "../../Component/FormComponent/LogoWhite";
import { resetPwdValidation } from "../../Component/Validation/resetPwdValidation";
import IconInputField from "../../Component/FormComponent/IconInputField";
import Button from "../../Component/FormComponent/Button";
import Icon from "../../Component/FormComponent/Icon";
import Loader from "../../Component/Loader/Loader";
//import LoginBgVideo from "../../Component/LoginBgVideo";
import Footer from "../../Component/Footer";
import logo from "../../Assets/img/logo.png";
import { authActions } from "../../Stores/Actions/authActions";
import { commonActions } from "../../Stores/Actions/commonActions";
import { getLocationParams } from "../../Util/util";
import { useNavigate } from "react-router-dom";

function ResetPassword() {
  const locationSearch = getLocationParams();
  const navigate = useNavigate();
  // initial values
  const resetPasswordDetails = useSelector(
    (state) => state.authReducer.resetPasswordDetails
  );
  const loading = useSelector(
    (state) => state.authReducer.loading
  );
  const serverErrors = useSelector((state) => state.authReducer.errors);
  const [successMsg, setSuccessMsg] = useState(null);

  const dispatch = useDispatch();
  const initialValues = {
    email: "",
    newPassword: "",
    confirmPassword: "",
  };

  // useEffect(() => {
  //   if (resetPasswordDetails?.data?.status == 200 ) {
  //     setSuccessMsg("You have successfully reset your password. Please login with your new password.")
  //     // navigate("/login");
  //   }
  // }, [resetPasswordDetails])

  const handleSubmit = (value) => {
    const payload = {
      email: value.email.trim(),
      password: value.newPassword.trim(),
      password_confirmation: value.confirmPassword.trim(),
      tenant_id: process.env.REACT_APP_TENANT_ID,
      token: locationSearch?.token,
    };
    dispatch(commonActions.loaderText("Loading..."));
    dispatch(authActions.resetPasswordRequest(payload)).then((res) => {
      if (res.status == 200) {
        setSuccessMsg("You have successfully reset your password. Please login with your new password.")
      }
    })
  };

  const renderForm = (formikProps) => {
    const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
      formikProps;
    return (
      <form onSubmit={handleSubmit}>
        <Loader showLoader={loading} />
        <div className="pageBg forgotPasswordWrap">
          <div className="pageBoxWrap pageCenterCnt pageForgotPassword pageResetPassword min-height-100vh">
            <Link className="logo" to="/">
              <LogoWhite src={logo} />
            </Link>

            <div className="boxBg">
              {!successMsg &&
                <>
                  <div className="textCnt">
                    <h6>Reset Password</h6>
                  </div>
                  <div className="input-text-wrapper">
                    <IconInputField
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={values.email}
                      onChange={handleChange}
                      iconName="icon-lock"
                      className="iconWrap"
                      onBlur={handleBlur}
                      errors={errors.email}
                      touched={touched.email}
                      autoComplete="email"
                    />
                  </div>
                  <div className="input-text-wrapper">
                    <IconInputField
                      type="password"
                      name="newPassword"
                      placeholder="New Password"
                      value={values.newPassword}
                      onChange={handleChange}
                      iconName="icon-lock"
                      className="iconWrap"
                      onBlur={handleBlur}
                      errors={errors.newPassword}
                      touched={touched.newPassword}
                      autoComplete="new-password"
                      eyeIcon={true}
                    />
                  </div>
                  <div className="input-text-wrapper">
                    <IconInputField
                      type="password"
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      value={values.confirmPassword}
                      onChange={handleChange}
                      iconName="icon-lock"
                      className="iconWrap mb-0"
                      onBlur={handleBlur}
                      errors={errors.confirmPassword || serverErrors?.message}
                      touched={touched.confirmPassword}
                      autoComplete="confirm-password"
                      serverErrors={serverErrors?.message}
                      eyeIcon={true}

                    />
                  </div>
                </>
              }

              <div className="dflex text-center">
                {!successMsg ?
                  <>
                    <Link className="linkStyle" to="/login">
                      <Icon className="iconWrap" iconName="fa fa-angle-left" />
                      Back
                    </Link>
                    <Button text="Submit" type="submit" className="btn" />
                  </>
                  :
                  <>
                    <p className="successMessage mb-20">{successMsg}</p>

                    <Link to="/login" className="btn" > Login </Link>
                  </>
                }
              </div>
            </div>
            {serverErrors && <div className="input-text-wrapper" />}
            <Footer />
          </div>
        </div>
      </form>
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={resetPwdValidation()}
    >
      {renderForm}
    </Formik>
  );
}

export default ResetPassword;
