import * as Yup from "yup";
 
export const AffiliateUpgradeValidation = () => {
  const phoneRegExp = /^[0-9\b]+$/;
  const zipCodeRegExp = /^([a-zA-Z0-9]){3,10}$/;
  const cvvRegExp = /^[0-9]{3,4}$/;
  const cardNumberRegExp = /^[0-9]{14,17}$/;
  const mmyyFormatRegExp = /([0-9]{2})\/([0-9]{2})/;

  // Validation for affiliate premium membership

  return Yup.object().shape({
    title : Yup.string().required("Title is required"),
    firstName: Yup.string("")
      .matches(/^[aA-zZ\s]+$/, "Must only have alphabet letters")
      .required("First Name is required"),
    lastName: Yup.string("")
      .matches(/^[aA-zZ\s]+$/, "Must only have alphabet letters")
      .required("Required"),
    email: Yup.string("")
      .email("Enter a valid email address.")
      .required("Email is required"),
    paypalEmail: Yup.string("").email("Enter a valid email address."),
      // .required("Email is required"),
    confirmPaypalEmail: Yup.string("")
      .oneOf([Yup.ref("paypalEmail"), null], "Email must match"),
      // .required("Please confirm Email"),
    phoneNumber: Yup.string("").min(3,"Invalid phone number"),
      // .required("Phone number is required"),
    nationality: Yup.string("").required("Required"),
    addressOne: Yup.string("").required("Required"), 
    city: Yup.string("").required("Required"),
    // state: Yup.string("").when("stateAvailable", {
    //   is: true,
    //   then: Yup.string().required("Required")
    // }),
    state:Yup.string(""),
    // zipCode: Yup.string("").min(4).max(10)
    //   .required("Required"),
    // zipCode: Yup.string('').required('Required').matches(/^([a-zA-Z0-9]){3,10}$/,"Invaild zip code"),
    zipCode: Yup.string("").required("Required").min(3, "Invalid zip code").max(10),
  
   
   
    // password: Yup.string("")
    //   .min(8, "Must be 8 characters or more")
    //   .required("Password Required"),
    // confirmPassword: Yup.string()
    //   .oneOf([Yup.ref("password"), null], "Passwords must match")
    //   .required("Please confirm password"),
    
      terms : Yup.boolean().oneOf([true],'Required') ,

    //card

    cardHolderName: Yup.string("").required("Required"),
    credit_number: Yup.string("")
      .matches(cardNumberRegExp, "Length should between 14 and 17")
      .required("Required"),
    mmyy: Yup.string()
      .max(5)
      .required(),
    security_code: Yup.string("")
      .required("Required").min(3).max(4),
  });
};
