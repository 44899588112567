import * as Yup from 'yup';

export const NewUserPremiumCheckoutValidation = () => {
  const zipCodeRegExp = /^([a-zA-Z0-9]){3,10}$/;
  const cvvRegExp = /^[0-9]{3,4}$/;
  const cardNumberRegExp = /^[0-9]{14,17}$/;
  const mmyyFormatRegExp = /([0-9]{2})\/([0-9]{2})/;

  // Validation for membership premium checkout

  return Yup.object().shape({
    nationality: Yup.string('').required('Required'),
    phoneNumber:Yup.string('').required('Required').nullable().min(3,"Invalid phone number"),
    addressOne: Yup.string('').required('Required'),
    addressTwo: Yup.string(''),
    city: Yup.string('').required('Required'),
    // state: Yup.string("").when("stateAvailable", {
    //   is: true,
    //   then: Yup.string().required("Required")
    // }),
    state:Yup.string(""),
    zipCode: Yup.string('').required('Required').matches(/^([a-zA-Z0-9]){3,10}$/,"Invaild zip code"),
      promoCode : Yup.string('').when("hasPromo", {
        is: true,
        then: Yup.string().required("Promo/Gift Code is Required ")
      }), 
    terms : Yup.boolean().oneOf([true],'Required'),
    
      //card
    
    cardHolderName: Yup.string('').when("hasPromo", {
      is: false,
      then: Yup.string('').required('Required').matches(/^[aA-zZ\s]+$/, 'Must only have alphabet letters')
    }),
    security_code: Yup.string('').when("hasPromo", {
      is: false,
      then: Yup.string('').required('Required').matches(cvvRegExp, 'Enter a valid security code').max(4),
    }),
    exp_month: Yup.string('').when("hasPromo", {
      is: false,
      then: Yup.string(''),
    }),
    exp_year: Yup.string('').when("hasPromo", {
      is: false,
      then: Yup.string(''),
    }),
    billing_zip: Yup.string('').when("hasPromo", {
      is: false,
      then: Yup.string(''),
    }),
    credit_number: Yup.string('').when("hasPromo", {
      is: false,
      then: Yup.string('').required('Required').min(15).max(16).matches(cardNumberRegExp, 'Enter a valid Card Number.'),
    }),
    billing_country: Yup.string('').when("hasPromo", {
      is: false,
      then: Yup.string(''),
    }),
    mmyy: Yup.string('').when("hasPromo", {
      is: false,
      then: Yup.string('').required('Required').min(5),
    }),

  });
};


 