import * as Yup from "yup";

export const ProfileValidation = () => {
  const zipCodeRegExp = /^([a-zA-Z0-9]){3,10}$/;
  // Validation for membership premium checkout

  return Yup.object().shape({
    title: Yup.string("").required("required").nullable(),
    firstName: Yup.string("")
      .matches(/^[aA-zZ\s]+$/, "Must only have alphabet letters")
      .required("Required"),
    lastName: Yup.string("")
      .matches(/^[aA-zZ\s]+$/, "Must only have alphabet letters")
      .required("Required"),
    phoneInputValue: Yup.string("").min(3,"Invalid phone number")
      //   .matches(phoneRegExp, 'Enter a valid number.'),
      // .length(10, 'Enter 10 digit phone nubmer')
      .required("Required"),
    country: Yup.string("").required("Required"),
    address_line_1: Yup.string("").required("Required").max(100, "Address must be at most 100 characters"),
    address_line_2: Yup.string('').max(100, "Address must be at most 100 characters"),
    city: Yup.string("").required("Required").max(50, "City must be at most 50 characters"),
    // state: Yup.string("").when("stateAvailable", {
    //   is: true,
    //   then: Yup.string().required("Required")
    // }),
    state:Yup.string(""),
    // zip: Yup.string("")
    //   .required("Required")
    //   .matches(zipCodeRegExp, "Enter valid zip code"),
      zip: Yup.string("").required("Required").min(3, "Invalid zip code").max(10),

  });
};

export const updatePasswordValidation = () => {
  return Yup.object().shape({
    old_password: Yup.string("")
      .min(8, "Password is too short - should be 8 Characters minimum.")
      .max(16, "Password is too long")
      .required("Please enter valid password"),
    password: Yup.string("")

      .min(8, "Password is too short - should be 8 Characters minimum.")
      .max(16, "Password is too long")
      .required("Please enter valid password"),
    password_confirmation: Yup.string("")

      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .min(8, "Password is too short - should be 8 Characters minimum.")
      .max(16, "Password is too long")
      .required("Please enter valid password"),
  });
};

export const addUserValidation = () => {

  const passportRegex = /^(?!^0+$)[a-zA-Z0-9]{3,20}$/
  return Yup.object().shape({
    title: Yup.string("").required("Required"),
    firstName: Yup.string("")
      .matches(/^[aA-zZ\s]+$/, "Must only have alphabet letters")
      .required("Required"),
    lastName: Yup.string("")
      .matches(/^[aA-zZ\s]+$/, "Must only have alphabet letters")
      .required("Required"),
    gender: Yup.string("").required("Required").nullable(),
    phone: Yup.string("").required("Required"),
    email: Yup.string("").email("Enter a valid email address.").nullable(),
    birthday: Yup.string("").required("Required").nullable(),

    // email:Yup.string("").nullable().test("email", "Enter a valid email address", (value) => {   // if the passport is not empty the this field should be match regex of passport number // in callback function  return false === show error
    //   if (val?.length > 0) {  //if address exist then apply min max else not
    //     return Yup.string("").email("Enter a valid email address")
    //   } else {
    //     return Yup.string().notRequired();
    //   }
    // }),

    passportNo: Yup.string("").nullable().test("passportNo", "Required", (value) => {   // if the passport is not empty the this field should be match regex of passport number // in callback function  return false === show error
      if (value && !value.match(passportRegex)) {
        return false
      } else {
        return true
      }
    }),
    
    expiryDate: Yup.string().nullable().when("passportNo", (val, schema) => {
      if (val?.length > 0) {  //if address exist then apply min max else not
        return Yup.string("").required("Required").min(5, "Invalid Expiry Date")
      } else {
        return Yup.string().notRequired();
      }
    }),

    country: Yup.string().when("passportNo", (val, schema) => {
      if (val?.length > 0) {  //if address exist then apply min max else not
        return Yup.string("").required("Required")
      } else {
        return Yup.string().notRequired().nullable();
      }
    }),

  });
};
