import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import ResultsOnPage from '../../Component/Pagiantion/ResultsOnPage';
import DesktopViewDisplay from './DesktopViewDisplay'
import TabletViewDisplay from './TabletViewDisplay'
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { marketingWebsiteAction } from "../../Stores/Actions/marketingWebsiteAction";
import Pagination from "../../Component/Pagiantion/Pagination";

function PayoutTransactions({ modalOpen, setHideNavbar }) {
  const dispatch = useDispatch();
  const walletData = useSelector(
    (state) => state.marketingSiteReducer?.walletData
  );

  // Mobile Responsive

  const [isMobile, setisMobile] = useState(window.innerWidth > 992 ? false : true);

  const updateSize = () => {
    if (window.innerWidth < 992) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  }

  useEffect(() => {
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const [values, setValues] = useState(
    {
      start_date: moment().subtract(1, 'months').format("YYYY-MM-DD"),
      end_date:  moment().format("YYYY-MM-DD"),
      page_number: 1,
      per_page: 15,
      type: "wallet"
    }); // initial values

  const getWalletData = (start_date, end_date, page_number, per_page, type) => {
    const payload = {
      type: type,
      filter: {
        date: {
          start_date: start_date ?  moment(start_date).format("YYYY-MM-DD") : null,
          end_date :end_date ? moment(end_date).format("YYYY-MM-DD") : null,
        }, 
      },
      paging: {
        per_page,
        page_number
      }
    }
    dispatch(marketingWebsiteAction.getWallletTransactionAction(JSON.parse(window.localStorage.getItem("user")).user_wallet.id, payload))
  }
 
  useEffect(() => {
    if(!values?.end_date) return
    getWalletData(values.start_date, values.end_date, values.page_number, values.per_page, values.type)
  }, [values])


  const walletResult = walletData?.data?.result?.map((ele) => {
    return {
      amount: ele.amount,
      dateTime: ele.date_time,
      dollaricon: "",
      payPalID: ele.comment,
      paymentID: ele.description,
      type: ele.type,
      transectionId : ele?.id,
      operation : ele?.operation
    }
  })

  const payoutResult = walletData?.data?.results?.map((ele) => {
    return {
      amount: ele.amount,
      dateTime: ele.datetime,
      dollaricon: "",
      payPalID: ele.transfer_id,
      paymentID: ele.description,
      type: "paypal_transfer",
      transectionId : ele?.transfer_id.replace("PayPal ID:",""),
      operation : "credit"
    }
  })

  return (
    <>
      {
        !isMobile && <DesktopViewDisplay modalOpen={modalOpen} data={ values.type === "wallet" ? ( walletResult || []) : (payoutResult || []) } values={values} setValues={setValues} />
      }
      <div className="paginationWrapper desktopViewDisplayBlock">
        {
          walletData?.data?.pagination?.total_pages > 1 &&
          <>
            <Pagination
              onPageChange={(e)=>setValues({...values, page_number: e.selected + 1})}
              pageCount={walletData?.data?.pagination?.total_pages}
              forcePage={walletData?.data?.pagination?.page ? Number(walletData?.data?.pagination?.page) - 1 : 0}
            />

            <ResultsOnPage
              className="results hide-for-mobile"
              callback={(a,b)=>setValues({...values, per_page: b})}
              defaultValue={15}
            />
          </>
        }
     
      </div>
      {
        isMobile && <TabletViewDisplay setHideNavbar={setHideNavbar} walletData={walletData ? walletData : []} modalOpen={modalOpen} data={values.type === "wallet" ?  walletResult : payoutResult } values={values} setValues={setValues} />
      }

    </>
  )
}

export default PayoutTransactions