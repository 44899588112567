import * as Yup from "yup";

export const CarSearchValidation = () => {

  return Yup.object().shape({
    pickUpLocation: Yup.array("").required("This field is required").test(
      "pickUpLocation",
      "This field is required.",
      (pickUpLocation) => pickUpLocation?.length > 0
    ),
    showDropOffPoint: Yup.boolean(),
    dropOffLocation: Yup.array("")
      .when('showDropOffPoint', (showDropOffPoint, field) => showDropOffPoint ? field.test(
        "pickUpLocation",
        "This field is required.",
        (dropOffLocation) => dropOffLocation?.length > 0
      ) : field),
    pickUpDate: Yup.string("")
      .required("This field is required")
      .nullable(true),
    dropOffDate: Yup.string("")
      .required("This field is required")
      .nullable(true),
  });
};
