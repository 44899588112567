import React from "react";
import { has } from "lodash";

function CarFilterDisplay({
  openVehicleTag,
  openCarTag,
  openCarFeatureTag,
  filters,
  clearFilter,
  setOpenVehicleTag,
  setOpenCarTag,
  setOpenCarFeatureTag,
  selectedFilter,
  setSelectedFilter,
}) {
  const handleRemoveFilter = (type) => {
    let newFilters = filters;
    if (type === "Vehicle Type") {
      clearFilter({ ...newFilters, "Vehicle Type": [] });
      setOpenVehicleTag((prev) => !prev);
      setSelectedFilter({ ...selectedFilter, "Vehicle Type": [] });
    }
    if (type === "Car Companies") {
      clearFilter({ ...newFilters, "Car Companies": [] });
      setOpenCarTag((prev) => !prev);
      setSelectedFilter({ ...selectedFilter, "Car Companies": [] });
    }
    if (type === "Car Features") {
      clearFilter({ ...newFilters, "Car Features": [] });
      setOpenCarFeatureTag((prev) => !prev);
      setSelectedFilter({ ...selectedFilter, "Car Features": [] });
    }
  };

  return (
    <div className="filter-display">
      <div className="filter-btn">
        {has(filters, "Vehicle Type") && openVehicleTag && (
          <div className="filter-name">
            Vehicle Type
            <button
              className="icon-close"
              onClick={() => handleRemoveFilter("Vehicle Type")}
            ></button>
          </div>
        )}
        {has(filters, "Vehicle Type") && openCarTag && (
          <div className="filter-name">
            Car Companies
            <button
              className="icon-close"
              onClick={() => handleRemoveFilter("Car Companies")}
            ></button>
          </div>
        )}
        {has(filters, "Car Features") && openCarFeatureTag && (
          <div className="filter-name">
            Car Features
            <button
              className="icon-close"
              onClick={() => handleRemoveFilter("Car Features")}
            ></button>
          </div>
        )}
      </div>
    </div>
  );
}

export default CarFilterDisplay;
