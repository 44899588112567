import React , {useEffect} from "react";
import { useSelector } from "react-redux";
import CommissionBox from "../../Component/Dashboard/CommissionBox";
import { isEmpty, has } from "lodash";
import {commafy} from '../../Util/util';

import {
  tottalCommission,
  totalCommissionPaidOut,
  totalCommissionPending,
  freemiumMemberShip,
  premiumMembership,
  plusMembership,
  premiumConversion,
  affiliateMember,
} from "../../Data/AffiliateDashboardMockData";

function TotalCommissionBox() {
  const commisionSummary = useSelector(
    (state) => state.cardsReducer.commissionSummary
  );

const result = commisionSummary?.data?.dashboard_details
 

  return (
    <div className="commission-content-wrapper">
      <div className="row no-gutters">
        <CommissionBox
          item={tottalCommission}
          isFirstEle={true}
          value={`$${result?.total_commission}`}
        />

        <CommissionBox
          item={totalCommissionPaidOut}
          className="col border-bottom border-left" 
          value={`$${commisionSummary?.data?.dashboard_details?.total_commission_paid_out}`}
        />

        <CommissionBox
          item={totalCommissionPending}
          className="col border-bottom border-left" 
          value={`$${result?.total_commission_pending}`}
        />

        <CommissionBox
          item={freemiumMemberShip}
          className="col border-bottom border-left"
          value={result?.total_freemium_membership_signup}
        />
      </div>
      <div className="row no-gutters">
        <CommissionBox item={premiumMembership} 
          value={result?.total_premium_membership_signup}
        
        />

        <CommissionBox
          item={plusMembership}
          className="col border-bottom border-left"
          value={result?.total_premium_plus_membership_signup}

        />

        <CommissionBox
          item={premiumConversion}
          className="col border-bottom border-left" 
          value={`${result?.freemium_to_premium_conversion}%`}


        />

        <CommissionBox
          item={affiliateMember}
          className="col border-bottom border-left"
          value={result?.total_affiliate_membership_signup}

        />
      </div>
    </div>
  );
}

export default TotalCommissionBox;