import * as Yup from "yup";

export const CarCheckoutValidation = () => {
  const phoneRegExp = /^[0-9\b]+$/;
  const zipCodeRegExp = /^([a-zA-Z0-9]){3,10}$/;
  const passportRegex = /^(?!^0+$)[a-zA-Z0-9]{3,20}$/
  // Validation for affiliate premium membership

  return Yup.object().shape({
    title: Yup.string("")
      .required("Required"),
    firstName: Yup.string("")
      .matches(/^[aA-zZ\s]+$/, "Must only have alphabet letters")
      .required("Required"),
    lastName: Yup.string("")
      .matches(/^[aA-zZ\s]+$/, "Must only have alphabet letters")
      .required("Required"),
    email: Yup.string("")
      .email("Enter a valid email address.")
      .required("Required"),
    phoneNumber: Yup.string("").min(3,"Invalid phone number") 
      .required("Required"),
    addressLine1: Yup.string("").required("Required"), 
    city: Yup.string("").required("Required"),
    state: Yup.string("").required("Required"),
    // zipCode: Yup.string("")
    //   .matches(zipCodeRegExp, "Enter valid zip code")
    //   .required("Required"),
    // zipCode: Yup.string("").min(4).max(10).required("Required"),
    // zipCode: Yup.string('').required('Required').matches(/^([a-zA-Z0-9]){3,10}$/,"Invaild zip code"),
    zipCode: Yup.string("").required("Required").min(3, "Invalid zip code").max(10),
    
    nationality: Yup.string("").required("Required"),

    country: Yup.string("").required("Required"),
    flightNumber: Yup.string("").matches(passportRegex, "Enter valid passport number"),

    yesNo: Yup.string(""),
    // cardType: Yup.string("")
    //   .when('yesNo', (yesNo, field) => yesNo ==="Yes"?field.required("Required"):field),
    // cardHolderName: Yup.string("")
    //   .when('yesNo', (yesNo, field) => yesNo ==="Yes"?field.required("Required"):field), 
    // cardNumber: Yup.string("")
    //   .when('yesNo', (yesNo, field) => yesNo ==="Yes"?field.required("Required").min(15).max(16):field),
    // card_mmyy: Yup.string("")
    //   .when('yesNo', (yesNo, field) => yesNo ==="Yes"?field.required("Required").min(5):field),
    // securityCode: Yup.string("")
    //   .when('yesNo', (yesNo, field) => yesNo ==="Yes"?field.required("Required").min(3):field),
    cardType: Yup.string("").required("Required"),
    cardHolderName: Yup.string("").required("Required"),
    cardNumber: Yup.string("").required("Required"),
    card_mmyy: Yup.string("").required("Required"),
    securityCode: Yup.string("").required("Required"),

  });
};
