import React from 'react'
import Modal from 'react-modal'
import closeButton from '../Assets/img/close-blue-icon.png'
import logo from '../Assets/img/logo2.png'
function PortraitModeModel({portraitViewModal, closeModal}) {
  //handle open and close video modal
  
  return (
    <Modal isOpen={portraitViewModal}  ariaHideApp={false} className='popup ipad-portrait-popup'>
      <div className="popup-content">
        <div className="popup-title">
          <button className="btn close" onClick={closeModal}>
            <img src={closeButton} alt="icon" />
          </button>
        </div>
        <div className="content-box">
        <img src={logo} alt="logo"/>
        <h6>For the <strong>Best User Experience</strong> on our platform,<br /> please turn/tilt your device
          <strong>&nbsp;horizontally</strong><br /> into <strong>landscape mode.</strong>
          <br />Thank you.
        </h6>
      </div>
      </div>
    </Modal>
  )
}

export default PortraitModeModel
