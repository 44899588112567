import React, { useRef, useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Header from "../../Component/Header";
import Card from "../../Component/Card";
import Slider from "react-slick";
import HomepageSlider from "../../Component/HomepageSlider";
import MobileImgSlider from "../../Component/MobileImgSlider";
import downArrow from "../../Assets/img/network/down-arrrow.svg";
import Background from "../../Assets/img/tripello-difference.png";
import DifferenceBackground from "../../Assets/img/home/tripello-diffrenece.png";
import flightImage from "../../Assets/img/flightImg.png";
import hotelImg from "../../Assets/img/cardImg2.png";
import carImg from "../../Assets/img/cardImg3.png";
import cardImage from "../../Assets/img/cardImg4.png";
import Video from "../../Component/Video";
import plusImg from "../../Assets/img/plus-img.png";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import posterBg from "../../Assets/img/home/Homepage-Banner-Video.jpg";
import homeVideoDesktop from "../../Assets/video/home/new-tripello-homepage-top-video.mp4";
import homeVideoipad from "../../Assets/video/home/new-tripello-homepage-top-video-ipad5.mp4";
import homeVideowebm from "../../Assets/video/home/new-tripello-homepage-top-video.webm";
import homeVideoMobile from "../../Assets/video/home/new-tripello-homepage-top-video.webm";
import playButton from "../../Assets/img/network/play-button.png";
import featuredOnImg from "../../Assets/img/home/featured-on.png";
import Button from "../../Component/FormComponent/Button";
import AccordianOne from "../../Component/AccordianOne";
import { faqs } from "../../main/CustomConstants/Home";
import ModalHome from "../../Component/ModalHome";
import PlanInfoTwo from "../../Component/PlanInfoTwo";
import { TriptekDifference } from "./TriptekDifference";
import { TripelloWayWrapper } from "./TripelloWayWrapper";
import MoreLessButton from "../../Component/MoreLessButton";
import VideoModal from "../../Component/VideoModal";
import { Formik } from "formik";
import PortraitModeModel from "../../Component/PortraitModeModel";
import { Link, useNavigate } from "react-router-dom";
import { FreemiumSignUpValidation } from "../../Component/Validation/FreemiumSignUpValidation";
import LogoWhite from "../../Component/FormComponent/LogoWhite";
import logo from "../../Assets/img/logo.png";
import IconInputField from "../../Component/FormComponent/IconInputField";
import IconSelectField from "../../Component/FormComponent/IconSelectField";
import userIcon from "../../Assets/img/signup/user-icon.svg";
import emailIcon from "../../Assets/img/signup/email.svg";
import lockIcon from "../../Assets/img/signup/lock.svg";
import { ReactComponent as DownArrowSvg } from "../../Assets/img/down-arrow-svg.svg";
import DynamicPricing from "../../Assets/img/icons/dynamic-pricing.png";
import WalletSavings from "../../Assets/img/icons/wallet-savings.png";
import Percent0 from "../../Assets/img/icons/percent-0.png";

import { ReactComponent as PerfectOpportunity } from "../../Assets/img/home/join-free.svg";
import { ReactComponent as Structure } from "../../Assets/img/home/no-time.svg";
import { ReactComponent as OnboardingProcess } from "../../Assets/img/home/upgrade.svg";
import { ReactComponent as NounSafe } from "../../Assets/img/down-arrow-svg.svg";
import Checkbox from "../../Component/FormComponent/Checkbox";
import { authActions } from "../../Stores/Actions/authActions";
import { useDispatch, useSelector } from "react-redux";
let checkoutCountries = require("../../Util/static-service");
import { getLocationParams } from "../../Util/util";
import Scrollbars from "react-custom-scrollbars";
import DeskSlideOne from "../../Assets/img/slide-dsk/slide-img-1.png";
import DeskSlideTwo from "../../Assets/img/slide-dsk/slide-img-2.png";
import DeskSlideThree from "../../Assets/img/slide-dsk/slide-img-3.png";
import DeskSlideFour from "../../Assets/img/slide-dsk/slide-img-4.png";
import DeskSlideFive from "../../Assets/img/slide-dsk/slide-img-5.png";
import DeskSlideSix from "../../Assets/img/slide-dsk/slide-img-6.png";
import DeskSlideSeven from "../../Assets/img/slide-dsk/slide-img-7.png";
import DeskSlideEight from "../../Assets/img/slide-dsk/slide-img-8.png";
import DeskSlideNine from "../../Assets/img/slide-dsk/slide-img-9.png";
import DeskSlideTen from "../../Assets/img/slide-dsk/slide-img-10.png";
import DeskSlideEleven from "../../Assets/img/slide-dsk/slide-img-11.png";
import DeskSlideTwelve from "../../Assets/img/slide-dsk/slide-img-12.png";
import DeskSlideThirteen from "../../Assets/img/slide-dsk/slide-img-13.png";
import DeskSlideFourteen from "../../Assets/img/slide-dsk/slide-img-14.png";
import DeskSlideFifteen from "../../Assets/img/slide-dsk/slide-img-15.png";
import MobSlideOne from "../../Assets/img/slide-mob/mob-img-1.png";
import MobSlideTwo from "../../Assets/img/slide-mob/mob-img-2.png";
import MobSlideThree from "../../Assets/img/slide-mob/mob-img-3.png";
import MobSlideFour from "../../Assets/img/slide-mob/mob-img-4.png";
import MobSlideFive from "../../Assets/img/slide-mob/mob-img-5.png";
import MobSlideSix from "../../Assets/img/slide-mob/mob-img-6.png";
import MobSlideSeven from "../../Assets/img/slide-mob/mob-img-7.png";
import MobSlideEight from "../../Assets/img/slide-mob/mob-img-8.png";
import MobSlideNine from "../../Assets/img/slide-mob/mob-img-9.png";
import MobSlideTen from "../../Assets/img/slide-mob/mob-img-10.png";
import MobSlideEleven from "../../Assets/img/slide-mob/mob-img-11.png";
import MobSlideTwelve from "../../Assets/img/slide-mob/mob-img-12.png";
import MobSlideThirteen from "../../Assets/img/slide-mob/mob-img-13.png";
import MobSlideFourteen from "../../Assets/img/slide-mob/mob-img-14.png";
import MobSlideFifteen from "../../Assets/img/slide-mob/mob-img-15.png";
import AsSeenOnIcon from "../../Assets/img/icons/As-Seen-On.png";
import MetaPixel from "./MetaPixel"

function Home() {
  const scrollPage = useRef(null);
  const [isFaqExpanded, setIsFaqExpanded] = useState(false);
  const [openedAccordian, setOpenedAccordian] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [isCaptcha, setIsCaptcha] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locationSearch = getLocationParams();
  const formikRef = useRef();

  //state for opening video modal
  const [openModal, setOpenModal] = useState(false);
  const [portraitViewModal, setportraitViewModal] = useState(
    window.outerWidth > 767 && window.outerWidth < 1025 ? true : false
  );
  //state for show top sticky header on scroll
  const [showHeader, setShowHeader] = useState(false);

  // state for scroll button
  const [showScrollTopBtn, setShowScrollTopBtn] = useState(false);

  //getting scroll button while scrolling down
  useEffect(() => {
    scrollPage.current.addEventListener("scroll", handleScroll);
    window.addEventListener("scroll", onScrollFrame);
    window.addEventListener("resize", function (event) {
      if (window.outerWidth > 767 && window.outerWidth < 1025) {
        setportraitViewModal(true);
      } else {
        setportraitViewModal(false);
      }
    });
    return () => {
      if (scrollPage?.current) {
        scrollPage?.current?.removeEventListener("scroll", handleScroll);
        window.removeEventListener("scroll", onScrollFrame);

        window.removeEventListener("resize", function (event) {
          if (window.outerWidth > 767 && window.outerWidth < 1025) {
            setportraitViewModal(true);
          } else {
            setportraitViewModal(false);
          }
        });
      }
    };
  }, []);

  const onScrollFrame = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    if (winScroll > 5) {
      setHeaderSticky(true);
    } else {
      setHeaderSticky(false);
    }
  };
  //setting length after which sticky header and scrollToTop button show
  const handleScroll = () => {
    if (
      (scrollPage.current.scrollTop >= 4 && !showHeader) ||
      scrollPage.current.scrollTop >= 150
    ) {
      setShowHeader(true);
      setShowScrollTopBtn(true);
    }
    if (
      scrollPage.current.scrollTop < 4 ||
      scrollPage.current.scrollTop < 150
    ) {
      setShowHeader(false);
      setShowScrollTopBtn(false);
    }
  };
  // Array list two
  const arrayListTwo = [
    <>
      • <strong>No Time Limit</strong> - Freemium Membership
    </>,
    <>
      • Savings of <strong>up to 25%</strong> over other Online Travel Sites
    </>,
    <>
      • Access to <strong>1.5 Million Hotels and Resorts</strong> only
    </>,
    <>
      • Preview of Premium <strong>Member Pricing</strong> and up to 65%  <strong>Savings</strong>{" "}
    </>,
    <>
      • <strong>No Credit/Debit Card</strong> required at Sign-up
    </>,
  ];
  // slick-slider setting
  const settings = {
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    arrows: true,
    slidesToScroll: 1,
    dots: true,
    centerMode: true,
    centerPadding: "250px",
    speed: 500,
    autoplaySpeed: 4000,
    variableWidth: true,
    //adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerPadding: "150px",
        },
      },
      {
        breakpoint: 767,
        settings: {
          centerMode: false,
          variableWidth: false,
          arrows: false,
          centerPadding: "0px",
          mobileFirst: true
        },
      }
    ],
  };
  //jumping to top of the page on button click
  const scrollElementToTop = () => {
    scrollPage.current.scrollTop = 0;
  };

  //handle open and close accordian list
  const handleOpenClose = (id) => {
    if (id === openedAccordian) {
      setOpenedAccordian(null);
    } else {
      setOpenedAccordian(id);
    }
  };

  var sectionStyle = {
    backgroundImage: `url(${Background})`,
  };
  var sectionStyleTwo = {
    backgroundImage: `url(${DifferenceBackground})`,
  };
  //handle open and close video modal

  const handleOpenCloseVideoModal = () => {
    if (!openModal) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  };

  const handleOpenClosePortraitModal = () => {
    if (portraitViewModal) {
      setportraitViewModal(false);
    } else {
      setportraitViewModal(true);
    }
  };
  //Modal open and close
  const handleOpenCloseModal = () => {
    if (!modalOpen) {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
  };

  // scroll view function

  const scrollView = (e) => {
    let triptekDifference = document.querySelector("#triptek-difference");
    e.preventDefault();
    triptekDifference &&
      triptekDifference.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  // scroll view function end

  // Accordian list
  const getFaqs = (faqList) => {
    let faqListNodes = [];

    // render the whole list
    faqList.forEach((faq, index) => {
      if (isFaqExpanded) {
        faqListNodes.push(
          <AccordianOne
            id={faq.id}
            key={faq.title + index}
            heading={faq.title}
            content={faq.description}
            openedAccordian={openedAccordian}
            handleOpenClose={handleOpenClose}
          />
        );
      } else {
        // render list with limit of 5 items
        if (index < 5) {
          faqListNodes.push(
            <AccordianOne
              id={faq.id}
              key={faq.title + index}
              heading={faq.title}
              content={faq.description}
              openedAccordian={openedAccordian}
              handleOpenClose={handleOpenClose}
            />
          );
        }
      }
    });

    return faqListNodes;
  };
  const tripelloNetworkList = [
    {
      icon: <PerfectOpportunity />,
      title: "Join Free",
      text: <><strong>JOIN for FREE</strong>, explore our platform and see <strong>ALL Products and Member Pricing.</strong></>,
      link:"/new-freemium-signup",
    },
    {
      icon: <Structure />,
      title: "No Time Limit",
      text: <><strong>Freemium Members</strong> can book <strong>Hotels only,</strong> with Great Savings of up to 25%.</>,
      link:"",
    },
    {
      icon: <OnboardingProcess />,
      title: "Upgrade Anytime",
      text: <><strong>Premium Members</strong> have <strong>full Access</strong> and enjoy <strong>Savings up to 65%</strong> and more.</>,
      link:"",
    }
  ]
  /////////////

  const [isHeaderSticky, setHeaderSticky] = useState(false);

  const isUserExist = useSelector((state) => state.authReducer.isUserExist);

  const freemiumSignupData = useSelector(
    (state) => state.authReducer.freemiumSignupData
  );
  const [openForm, setOpenForm] = useState(false);
  const [user_id, setUser_id] = useState(null);

  let initialValues = {
    // form inital Values
    firstName: locationSearch?.first_name || "",
    lastName: locationSearch?.last_name || "",
    email: locationSearch?.email || "",
    password: "",
    confirmPassword: "",
    country: "",
    terms: false,
  };

  useEffect(() => {
    if (locationSearch?.ref && !user_id) {
      dispatch(authActions.isEmailExistRequest(locationSearch.ref)).then(
        (res) => {
          setUser_id(res?.data?.user_id);
        }
      );
    }
  }, []);

  const handleSubmit = (values) => {
    const payload = {
      first_name: values.firstName.trim(),
      last_name: values.lastName.trim(),
      email: values.email.trim(),
      password: values.password.trim(),
      country: values.country.value.trim(),
      sponsor_id: user_id,
    };

    dispatch(authActions.isEmailExistRequest(values.email)).then((response) => {
      if (response.status == 200) {
        return formikRef.current.setErrors({
          email:
            "You are already a member, please login or use a different email",
        });
      }

      if (response?.response?.status == 422) {
        return dispatch(authActions.freemiumSignupRequest(payload)).then(
          (res) => {
            if (res.status == 200) {
              navigate("/thankyou/request-to-confirm-email");
            }
          }
        );
      }
    });
  };
  const handleCustomChange = (field, value, formikProps) => {
    formikProps.setFieldValue(field, value);
  };

  const countryOptions = checkoutCountries.map((curr) => {
    return { value: curr.alpha2, label: curr.name };
  });

  const renderForm = (formikProps) => {
    const { values, errors, touched, handleSubmit, handleBlur, handleChange } =
      formikProps;
    const onCaptchaChange = (value) => {
      if (value !== null)
        setIsCaptcha(false);
      else
        setIsCaptcha(true);
    }
    return (
      <div className="form-wrapper">
        <form onSubmit={handleSubmit}>
          <IconInputField
            name="firstName"
            type="text"
            placeholder="First Name"
            src={userIcon}
            value={values.firstName}
            touched={touched.firstName}
            errors={errors.firstName}
            onBlur={handleBlur}
            onChange={handleChange}
            inputClassName="form-control"
          />
          <IconInputField
            name="lastName"
            type="text"
            placeholder="Last Name"
            src={userIcon}
            value={values.lastName}
            touched={touched.lastName}
            errors={errors.lastName}
            onBlur={handleBlur}
            onChange={handleChange}
            inputClassName="form-control"
          />
          <IconInputField
            name="email"
            type="email"
            placeholder="Your Email"
            src={emailIcon}
            value={values.email}
            touched={touched.email}
            errors={errors.email}
            onBlur={handleBlur}
            onChange={(e) =>
              handleCustomChange("email", e.target.value, formikProps)
            }
            inputClassName="form-control"
          />
          <IconInputField
            name="password"
            type="password"
            placeholder="Password"
            src={lockIcon}
            value={values.password}
            errors={errors.password}
            touched={touched.password}
            onBlur={handleBlur}
            onChange={handleChange}
            eyeIcon={true}
            inputClassName="form-control"
          />
          <IconInputField
            name="confirmPassword"
            type="password"
            placeholder="Confirm Password"
            src={lockIcon}
            value={values.confirmPassword}
            touched={touched.confirmPassword}
            errors={errors.confirmPassword}
            onBlur={handleBlur}
            onChange={handleChange}
            inputClassName="form-control"
            eyeIcon={true}
          />

          <IconSelectField
            name="country"
            placeholder="Country  "
            // src={lockIcon}
            value={values.country}
            touched={touched.country}
            errors={errors.country}
            onBlur={handleBlur}
            onChange={handleChange}
            inputClassName="form-control"
            options={countryOptions}
            formikProps={formikProps}
            iconName="icon-globe"
            className="country-code-wrap"
          />
          {/* Checkbox input */}
          <div className="checkBoxContent">
            <label className="customCheckbox">
              <Checkbox
                name="terms"
                value={values.terms}
                onChange={handleChange}
              />
              <p className={errors.terms && touched.terms && "color-red"}>
                I agree to the{" "}
                <Link target="_blank" to="/terms-conditions">Terms and Conditions </Link>
              </p>
            </label>
          </div>
          <div className="buttonWrap">
            <div className="content">
              <h4>Free</h4>
              <h6>(no time limit)</h6>
            </div>

            {/* Form Submit button  */}

            {/* <div className="button">
              <Button text="I'm In" type="submit" className="btn" />
            </div> */}
            <div className="google-recaptcha-wrap">
              {isCaptcha ? <ReCAPTCHA
                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                onChange={onCaptchaChange}
              /> : <>
                <div className="button">
                  <Button text="I'm In" type="submit" className="btn" />
                </div></>}
            </div>
          </div>
        </form>
      </div>
    );
  };

  return (
    <>
    <MetaPixel/>
      <div
        className={`siteBg freemiumsignupPageWrap newfreemiumsignupPageWrap ${isHeaderSticky && "header-sticky"
          }`}
      >
        <div className="pageWrap pageWrapInner">
          <section className="pageContent">
            <div className="pageInternalScrollRemove">
              <div className="freemiumsignupBanner">
                <div className="container">
                  <div
                    className={
                      openForm ? "signupWrapper openForm" : "signupWrapper"
                    }
                  >
                    <div className="fixedHeader">
                      <Header headerName={true} />
                    </div>

                    <div className="row">
                      <div className="col left-content">
                        <div className="left-content-wrapper">
                          {/* <h1>Welcome,</h1>
                          <h2>
                            {" "}
                            to a <strong>Unique</strong> and{" "}
                            <strong>New Way </strong>
                            of <strong>Booking Travel.</strong>
                          </h2> */}
                          <h1 className="font-weight-500"><strong>TRAVEL BOOKING REIMAGINED</strong></h1>
                          <h2>
                            {" "}
                            <strong>Wholesale/Net Travel Prices</strong> that are not{" "}
                            <br /> publically available. They can legally only be <br />
                            shown behind a login.
                          </h2>
                          <div className="way-wrapper">
                            <h5>
                              We say <strong>NO</strong> to:
                            </h5>
                            <ul>
                              <li>
                                <h6>Gimmicks</h6>
                              </li>
                              <li>
                                <h6>Advertisement</h6>
                              </li>
                              <li>
                                <h6>Buying Pressure</h6>
                              </li>
                              <li>
                                <h6>Risk</h6>
                              </li>
                              <li>
                                <h6>Obligations</h6>
                              </li>
                              <li>
                                <h6>Time-Limits</h6>
                              </li>
                              <li>
                                <h6>Up-Sales</h6>
                              </li>
                              <li>
                                <h6>Commissions</h6>
                              </li>
                              <li>
                                <h6>Booking Fees</h6>
                              </li>
                            </ul>
                          </div>
                          <h3>
                            Sign up <strong>FREE</strong> and{" "}
                            <strong>get ACCESS</strong> to:
                          </h3>
                          <div className="list-content">
                            {/* Array list two getting iterated here */}

                            <ul>
                              {arrayListTwo.map((listItem, i) => {
                                return <li key={i}>{listItem}</li>;
                              })}
                            </ul>
                          </div>
                          <div className="as-seen-icon-wrap">
                            <img src={AsSeenOnIcon} alt="icon" />
                          </div>
                          <div className="freemium-getstarted-btn">
                            <Button
                              className="btn"
                              type="button"
                              onClick={() => setOpenForm(true)}
                              text="Get Started"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col right-content">
                        <div className="right-box-content">
                          <div className="infoBox">
                            <div className="membership-content">
                              <div className="logo-image">
                                <LogoWhite src={logo} />
                              </div>
                              <div className="planName">Freemium Membership</div>
                            </div>
                          </div>
                          <Formik
                            onSubmit={handleSubmit}
                            innerRef={formikRef}
                            validationSchema={FreemiumSignUpValidation}
                            initialValues={initialValues}
                            enableReinitialize={true}
                          >
                            {renderForm}
                          </Formik>
                        </div>
                      </div>
                    </div>
                    <div className="scrollBtm">
                      <a
                        to="#scroll-section"
                        onClick={scrollView}
                        className="scroll-down"
                      >
                        <div className="mousey-wrap">
                          <div className="mousey">
                            <div className="scroller"></div>
                          </div>
                          <DownArrowSvg />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      {/* copy from home,js */}
      <div
        className={
          !showHeader
            ? `grayBg indexPageWrap  indexwrap-freemium`
            : "grayBg indexPageWrap header-sticky"
        }>
        <div ref={scrollPage} className="newfreemiumsignupPageWrap">
          <section
            id="triptek-difference"
            className="tripello-difference-wrapper"
            style={sectionStyleTwo}
          >
            <div className="container sideSpace centerAlign">
              {/* <TriptekDifference /> */}
              <div className="container">
                <h3 className="sctionTitle">The Tripello Difference</h3>
                <p>Wholesale/Net Travel Prices that are not publically available.<br/> They can legally only be shown behind a login.</p>
                <div className="content-wrapper">
                  <div className="col">
                    <div className="heading">
                      <i><img src={DynamicPricing} /></i> Dynamic Pricing
                    </div>
                    <div className="content">
                      <strong>Dynamic, Wholesale / Net Rates</strong> in Real Time, directly to you.
                    </div>
                  </div>
                  <div className="col">
                    <div className="heading">
                      <i><img src={Percent0} /></i> No Fees or Mark Up’s
                    </div>
                    <div className="content">
                      With our powerful Technology, book directly from thousands of Wholesale Travel Providers, <strong>YOU pay what WE pay!</strong>
                    </div>
                  </div>
                  <div className="col">
                    <div className="heading">
                      <i><img src={WalletSavings} /></i> Savings up to 65%
                    </div>
                    <div className="content">
                      More Savings, More Freedom, More Choices, <strong>Better Experiences.</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="container sectionSpace2 sideSpace triptek-inventory-wrapper">
            <h3 className="sctionTitle centerAlign">The Tripello Inventory</h3>
            <div className="colCard">
              <Card
                title={<h5>Global Flights</h5>}
                description="Big savings on Flights, especially in premium cabins, from all Global Carries and over 140 low-cost carriers from around the world."
                image={flightImage}
                colClassName="col"
                styleClassName="cardStyle"
                imgClassName="cardImg"
                textClassName="cardText"
                backgroundImage={true}
              />

              <Card
                title={<h5>Hotels & Resorts</h5>}
                description="Huge savings on over 1.5 million Hotels and Resorts worldwide, Including the most popular destinations."
                image={hotelImg}
                colClassName="col"
                styleClassName="cardStyle"
                imgClassName="cardImg"
                textClassName="cardText"
                backgroundImage={true}
              />
              <Card
                title={<h5>Rental Cars & Services</h5>}
                description="Great Deals on Rental Cars by the best and most trusted brands from around the world."
                image={carImg}
                colClassName="col"
                styleClassName="cardStyle"
                imgClassName="cardImg"
                textClassName="cardText"
                backgroundImage={true}
              />
              <Card
                title={<h5>Vacation Homes</h5>}
                description=" More than 1.5 Million bookable Vacation rentals incl. Condos, Cabins, Villas, Bungalows, Beach Houses and more."
                image={cardImage}
                colClassName="col"
                styleClassName="cardStyle"
                imgClassName="cardImg"
                textClassName="cardText"
                backgroundImage={true}
              />
            </div>
          </div>
          <div className="tripello-way-wrapper">
            <div className="container">
              <div className="section-title centerAlign">
                <h2 className="sctionTitle">The Tripello Way</h2>
                <h3>The <strong>first true</strong> subscription based Wholesale Travel Service.</h3>
              </div>
              <div className="cardStyle">
                <div className="card-box-wrap row">
                  {tripelloNetworkList.map((list, index) => {
                    return <>
                      <div className="col card-col">
                        <div className="cardBox">
                        {list.link && <a className="link-url" href={list.link}></a>}
                          <div className="img-wrap">
                        {list.link && <a className="link-url" href={list.link}></a>}
                          {list.icon}</div>
                          <h4>{list.title}</h4>
                          <p>{list.text}</p>
                        </div>
                      </div>
                    </>;
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="slide-show-desktop " style={sectionStyle}>
            <div className="slider-wrapper">
              <Slider className="desk-slider" {...settings}>
                <HomepageSlider src={DeskSlideOne} />
                <HomepageSlider src={DeskSlideTwo} />
                <HomepageSlider src={DeskSlideThree} />
                <HomepageSlider src={DeskSlideFour} />
                <HomepageSlider src={DeskSlideFive} />
                <HomepageSlider src={DeskSlideSix} />
                <HomepageSlider src={DeskSlideSeven} />
                <HomepageSlider src={DeskSlideEight} />
                {/*<HomepageSlider src={DeskSlideNine} />
                <HomepageSlider src={DeskSlideTen} />
                <HomepageSlider src={DeskSlideEleven} />
                <HomepageSlider src={DeskSlideTwelve} />
                <HomepageSlider src={DeskSlideThirteen} />
                <HomepageSlider src={DeskSlideFourteen} />
                <HomepageSlider src={DeskSlideFifteen} /> */}
              </Slider>
            </div>
          </div>

          <div className="slide-show-mobile" style={sectionStyle}>
            <div className="mobile-slider-wrapper">
              <Slider className="mobile-slider" {...settings}>
                <MobileImgSlider src={MobSlideOne} />
                <MobileImgSlider src={MobSlideTwo} />
                <MobileImgSlider src={MobSlideThree} />
                <MobileImgSlider src={MobSlideFour} />
                <MobileImgSlider src={MobSlideFive} />
                <MobileImgSlider src={MobSlideSix} />
                <MobileImgSlider src={MobSlideSeven} />
                <MobileImgSlider src={MobSlideEight} />
                {/*<MobileImgSlider src={MobSlideNine} />
                <MobileImgSlider src={MobSlideTen} />
                <MobileImgSlider src={MobSlideEleven} />
                <MobileImgSlider src={MobSlideTwelve} />
                <MobileImgSlider src={MobSlideThirteen} />
                <MobileImgSlider src={MobSlideFourteen} />
                <MobileImgSlider src={MobSlideFifteen} /> */}
              </Slider>
            </div>
          </div>
          <div className="gradiantBg videoSpace sideSpace centerAlign tripello-magic-wrapper">
            <div className="sctionTitle">The Tripello Magic</div>
            <p>Give us a minute to show you how</p>
            <div className="cardStyle">
              <div className="videoBox">
                <div className="videoInBox">
                  <div className="desktop-video">
                    <Button
                      type="button"
                      className="video-play-btn"
                      imageSrc={playButton}
                      onClick={handleOpenCloseVideoModal}
                    />
                  </div>
                  <div className="mobile-video">
                    <Button
                      type="button"
                      className="video-play-btn"
                      imageSrc={playButton}
                      onClick={handleOpenCloseVideoModal}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="tripello-magic-content">
              <div className="video-section-content-wrap">
                <h6>
                  If you like to <strong>find out more</strong> about{" "}
                  <strong>WHAT</strong>, <strong>HOW</strong>, and{" "}
                  <strong>WHY</strong> we do what <strong>WE</strong> do,
                  <strong>
                    <button
                      onClick={handleOpenCloseModal}
                      className="tripello-magic-link"
                    >
                      CLICK HERE.
                    </button>
                  </strong>
                </h6>
              </div>
              <div className="featured-on-icons">
                <img src={featuredOnImg} alt="image" />
              </div>
            </div>
          </div>

          <div className="planCont">
            <div className="container sideSpace centerAlign">
              <h3 className="sctionTitle">Sign Up For Free Today</h3>
              <div className="container">
                {/* <div className="choosePlan">
                  
                </div> */}
                {/* <div className="choosePlanOrTitle">
                  <h6>Or</h6>
                </div> */}
                <div className="choosePlan">
                  <div className="col ">
                    <div className="cardStyle">
                      <div className="planImgBox">
                        <div className="planImgInner">
                          <div className="logo-image">
                            <LogoWhite src={logo} />
                          </div>
                          <div className="planName">Freemium Membership</div>
                        </div>
                      </div>
                      <ul className="planDetails">
                        <li>
                          {/* <i className="icon-tick"></i> */}
                          No Credit/Debit Card
                          Required*
                        </li>
                        <li>
                          {/* <i className="icon-tick"></i> */}
                          Savings of up to 25%
                        </li>
                        <li>
                          {/* <i className="icon-tick"></i> */}
                          Limited Platform Access
                          <br /> (Hotels and Resorts Only)
                        </li>

                        <li>
                          {/* <i className="icon-tick"></i> */}
                          Access to 1.5 million <br />
                          Hotels and Resorts
                        </li>
                      </ul>
                      <div className="planBtmBox">
                        <p className="planNote">
                          * Not required for Sign Up but will be required for
                          Hotel Bookings
                        </p>
                        <div className="planRateBox">
                          <div>
                            <h4>Free</h4>
                            <p>(no time limit)</p>
                          </div>
                          <a href="#" className="btn">
                            I’m In
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="cardStyle paidPlan">
                      <div className="planImgBox">
                        <div className="planImgInner">
                          <div className="logo-image">
                            <LogoWhite src={logo} />
                          </div>
                          <div className="planName">Premium Membership</div>
                          <p>(12 Month)</p>
                          <div className="plus-img">
                            <img src={plusImg} alt="image" />
                          </div>
                        </div>
                      </div>
                      <ul className="planDetails">
                        <li>
                          {/* <i className="icon-tick"></i> */}
                          Credit/Debit Card Required*
                        </li>
                        <li>
                          {/* <i className="icon-tick"></i> */}
                          Unlimited Platform Access
                        </li>
                        <li>
                          {/* <i className="icon-tick"></i> */}
                          Savings of up to 65% and more
                        </li>
                        <li>
                          {/* <i className="icon-tick"></i> */}
                          Access to 1.5 million Hotels
                          and Resorts
                        </li>

                        <li>
                          {/* <i className="icon-tick"></i> */}
                          Access to 1.5 million
                          Vacation Homes
                        </li>

                        <li>
                          {/* <i className="icon-tick"></i> */}
                          Access to Rental Cars and
                          upcoming Chauffeur Service
                        </li>
                        <li>
                          {/* <i className="icon-tick"></i> */}
                          Access to Flight Inventory of
                          all Global Carriers and 140 Low Cost Carriers
                        </li>
                        <li>
                          {/* <i className="icon-tick"></i> */}
                          Access to Member Referral
                          Rewards Program <br />
                          • $25 for each Premium Member <br />• $50 for each
                          Premium Plus Member
                        </li>
                      </ul>
                      <div className="planBtmBox">
                        <p className="planNote">
                          * Required for Sign Up and Bookings
                        </p>
                        <div className="planRateBox">
                          <div>
                            <h4>
                              $24<sub>.95</sub>
                              <span>/ per month</span>
                            </h4>
                            <p>($299.40 total at checkout)</p>
                          </div>
                          <a href="#" className="btn">
                            Sign Me Up
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="cardStyle paidPlan">
                      <div className="planImgBox">
                        <div className="planImgInner">
                          <div className="logo-image">
                            <LogoWhite src={logo} />
                          </div>
                          <div className="planName">Premium Membership</div>
                          <p>(6 Month)</p>
                        </div>
                      </div>
                      <ul className="planDetails">
                        <li>
                          {/* <i className="icon-tick"></i> */}
                          Credit/Debit Card Required*
                        </li>
                        <li>
                          {/* <i className="icon-tick"></i> */}
                          Unlimited Platform Access
                        </li>
                        <li>
                          {/* <i className="icon-tick"></i> */}
                          Savings of up to 65% and more
                        </li>
                        <li>
                          {/* <i className="icon-tick"></i> */}
                          Access to 1.5 million Hotels
                          and Resorts
                        </li>
                        <li>
                          {/* <i className="icon-tick"></i> */}
                          Access to 1.5 million
                          Vacation Homes
                        </li>
                        <li>
                          {/* <i className="icon-tick"></i> */}
                          Access to Rental Cars and
                          upcoming Chauffeur Service
                        </li>
                        <li>
                          {/* <i className="icon-tick"></i> */}
                          Access to Flight Inventory of
                          all Global Carriers and 140 Low Cost Carriers
                        </li>
                      </ul>
                      <div className="planBtmBox">
                        <p className="planNote">
                          * Required for Sign Up and Bookings
                        </p>
                        <div className="planRateBox">
                          <div>
                            <h4>
                              $32<sub>.50</sub>
                              <span>/ per month</span>
                            </h4>
                            <p>($195.00 total at checkout)</p>
                          </div>
                          <a href="#" className="btn">
                            Sign Me Up
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container sectionSpace2 sideSpace faqCnt home-faq">
            <h3 className="centerAlign sctionTitle">
              Here are Some Answers to Common Questions
            </h3>
            <div className="accordion">
              <div className="cardStyle accordWrap">
                <div className="toggle-more-accordions">{getFaqs(faqs)}</div>
              </div>

              {faqs.length > 5 && (
                <MoreLessButton
                  isExpandAccordian={isFaqExpanded}
                  setIsExpandAccoridan={setIsFaqExpanded}
                />
              )}
            </div>
          </div>
        </div>
        <FooterWithMediaIcon />
        {showScrollTopBtn ? (
          <div
            id="back-to-top"
            className="back-to-top"
            onClick={scrollElementToTop}
          >
            <button className="btn btn-primary" title="Back to Top">
              <svg
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                viewBox="0 0 31.479 31.479"
                style={{ enableBackground: "new 0 0 31.479 31.479" }}
                width="18px"
                height="18px"
                className="svg lazyloading replaced-svg"
              >
                <path
                  style={{ fill: "#004185" }}
                  d="M26.477,10.274c0.444,0.444,0.444,1.143,0,1.587c-0.429,0.429-1.143,0.429-1.571,0l-8.047-8.047  v26.555c0,0.619-0.492,1.111-1.111,1.111c-0.619,0-1.127-0.492-1.127-1.111V3.813l-8.031,8.047c-0.444,0.429-1.159,0.429-1.587,0  c-0.444-0.444-0.444-1.143,0-1.587l9.952-9.952c0.429-0.429,1.143-0.429,1.571,0L26.477,10.274z"
                ></path>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
              </svg>
            </button>
          </div>
        ) : null}
      </div>

      {modalOpen && (
        <ModalHome openModal={modalOpen} closeModal={handleOpenCloseModal} />
      )}
      {openModal && (
        <VideoModal
          openModal={openModal}
          closeModal={handleOpenCloseVideoModal}
        />
      )}
    </>
  );
}

export default Home;
