
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import Checkbox from "./FormComponent/Checkbox";
import closeIcon from "../Assets/img/lightbox-close.png";

//Terms Checkbox

function TermsCheckbox(props) {
  const { value, onChange, name, onBlur, touched, error, confirm } = props;
  const [openModal, setModalOpen] = useState(false);
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  return (
    <>
      <div className="checkBoxContent">
        <label className="checkBoxContentWrap">
          <Checkbox
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
          />
          {props.showPolicy ? <p className={touched && error ? "color-red" : ""}>
            I confirm, understand and agree to the
            <button className="btn-confirm" type="button" onClick={() => setModalOpen(true)}> Cancellation and Change Policy.</button> </p>
            : <p className={touched && error ? "color-red" : ""}>
              By clicking «Pay Now», I agree to the{" "}
              <Link target="_blank" to="/terms-conditions">Terms and Conditions</Link> and{" "}
              <Link target="_blank" to="/privacy-policy">Privacy Policy. </Link>
            </p>}
            {!props.showPolicy ? <></> :  " "}
            {/* {!props.showPolicy ? <p>Memberships are nonrefundable and there are no refunds or credits for partially used membership periods.</p> :  " "} */}

          {/* <p className="color-red">{touched && error}</p> */}
          {props.MembershipNonrefundContent ? <p>Memberships are nonrefundable and there are no refunds or credits for partially used membership periods.</p> : " "}
        </label>

      </div>
      {openModal && (<Modal
        isOpen={openModal}
        ariaHideApp={false}
        className="popup popup-open popup-privacy-confirm-text"
      >
        <div className="popup-content">
          <div className="popup-title">
            {/* <button className="btn close" onClick={() => setModalOpen(false)}>
              <img src={closeIcon} alt="icon" />
            </button> */}
          </div>
          <div className="content-box">
            <h5>Flight Cancellation and Change Policy</h5>
            <p>Based on your flight selection and the selected airfare the following charges for Cancellations / Changes of flight tickets can occur in addition to the charges outlined in the Terms and Conditions.</p>
            <h5>Non-Refundable Ticket</h5>
            <p>If you purchased a non-refundable ticket and you do not change the date or do not take the flight, the ticket will have no value and the purchase price is <strong>NOT</strong> refundable.</p>
            <h5>Ticket Refund</h5>
            <p>If you decide to cancel your refundable flight ticket you will be charged a cancellation fee of $30.</p>
            <h5>Name Change</h5>
            <p>If for any reason you will need to change the name on the flight ticket you will be charged a change fee of $40.</p>
            <h5>Ticket Re-Issuance</h5>
            <p>If you need or decide to change the date on your flight ticket, refundable/non-refundable, you will be charge a change fee of $30.</p>
            <h5>24-Hour Cancellation</h5>
            <p>If you need or decide to cancel your refundable/non-refundable flight ticket within 24 hours of your purchase you will be charged a cancellation fee of $30.</p>
            <div className="btn-understand-wrap">
              <button className="btn btn-understand" onClick={() => setModalOpen(false)}>
                I understand
              </button>
            </div>
          </div>
        </div>
      </Modal>)}
    </>
  );
}

export default TermsCheckbox;
