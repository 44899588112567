import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import useOutsideHandler from "../../Stores/Utils/useOutsideHandler";
import InputRange from "react-input-range";
import flightDepartureIcon from "../../Assets/img/icons/flight-departure-icon.svg";
import flightDepartureReturnIcon from "../../Assets/img/icons/flight-departure-return-icon.svg";
import flightArrivalIcon from "../../Assets/img/icons/flight-arrival-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, has } from "lodash";
import { useFilters } from "../../Stores/ConstomHooks/useFilters";

export const FlightTimesFilter = ({
  //  filters,
  // setFilters,
  searchFlight,
  selectedFlightTab,
  disable
}) => {
  const currentGeoLocation =
    window.localStorage.getItem("currentGeoLocation") &&
    JSON.parse(window.localStorage.getItem("currentGeoLocation"));
  const isUS = currentGeoLocation === "US";
  const timeFormat = isUS ? "hh:mm a" : "HH:mm";
  const [showOption, setshowOption] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [filters, setFilters, removeFilter, appliedFiltersList] = useFilters( );

  const filterRef = useRef(null);

  const flightsSearchData = useSelector(
    (state) => state.flightReducer?.flightSearchData
  );

  const [outboundDepatureTime, setOutboundDepatureTime] = useState({
    min: 0,
    max: 24,
  });
  const [outboundArrivalTime, setOutboundArrivalTime] = useState({
    min: 0,
    max: 24,
  });
  const [inboundDepatureTime, setInbuundDepatureTime] = useState({
    min: 0,
    max: 24,
  });
  const [inboundArrivalTime, setInboundArrivalTime] = useState({
    min: 0,
    max: 24,
  });

  const toggleCloseFilter = () => {
    setshowOption(false);
  };

  useEffect(() => {
    if (
      !has(
        filters,
        "outboundDepatureTime" ||
          "outboundArrivalTime" ||
          "inboundDepatureTime" ||
          "inboundArrivalTime"
      )
    ) {
      if (flightsSearchData?.filtersAvailable?.outboundDepatureTime) {
        setOutboundDepatureTime({
          min:
            Number(
              flightsSearchData?.filtersAvailable?.outboundDepatureTime[0]
            ) || 0,
          max:
            Number(
              flightsSearchData?.filtersAvailable?.outboundDepatureTime[1]
            ) || 24,
        });
      }
      if (flightsSearchData?.filtersAvailable?.outboundArrivalTime) {
        setOutboundArrivalTime({
          min:
            Number(
              flightsSearchData?.filtersAvailable?.outboundArrivalTime[0]
            ) || 0,
          max:
            Number(
              flightsSearchData?.filtersAvailable?.outboundArrivalTime[1]
            ) | 24,
        });
      }
      if (flightsSearchData?.filtersAvailable?.outboundArrivalTime) {
        setInbuundDepatureTime({
          min:
            Number(
              flightsSearchData?.filtersAvailable?.inboundDepatureTime[0]
            ) || 0,
          max:
            Number(
              flightsSearchData?.filtersAvailable?.inboundDepatureTime[1]
            ) || 24,
        });
      }
      if (flightsSearchData?.filtersAvailable?.inboundArrivalTime) {
        setInboundArrivalTime({
          min:
            Number(
              flightsSearchData?.filtersAvailable?.inboundArrivalTime[0]
            ) || 0,
          max:
            Number(
              flightsSearchData?.filtersAvailable?.inboundArrivalTime[1]
            ) || 24,
        });
      }
    }
  }, [flightsSearchData]);

  useOutsideHandler(filterRef, showOption, toggleCloseFilter);

  const handleRangeChange = (value, name) => {
    if (name == "outboundDepatureTime") {
      setOutboundDepatureTime({
        min: Number(value.min),
        max: Number(value.max.toFixed(2)),
      });
    }
    if (name == "outboundArrivalTime") {
      setOutboundArrivalTime({
        min: Number(value.min),
        max: Number(value.max.toFixed(2)),
      });
    }
    if (name == "inboundDepatureTime") {
      setInbuundDepatureTime({
        min: Number(value.min),
        max: Number(value.max.toFixed(2)),
      });
    }
    if (name == "inboundArrivalTime") {
      setInboundArrivalTime({
        min: Number(value.min),
        max: Number(value.max.toFixed(2)),
      });
    }

    // setFilters({
    //     ...filters,
    //     [name]: [Number(value.min), Number(value.max)],
    // });

    setFilters(name, [Number(value.min), Number(value.max)]);

    //     if(outboundDepatureTime.min == 0 && outboundDepatureTime.max == 24 && outboundArrivalTime.min == 0 && outboundArrivalTime.max == 24 && inboundDepatureTime.min == 0 && inboundDepatureTime.max == 24 && inboundArrivalTime.min == 0 && inboundArrivalTime.max == 24){
    //         setOpenTag((prev) => !prev);
    //    }
  };

  const handleResetRange = () => {
    setOutboundDepatureTime({ min: Number(0), max: Number(24) });
    setOutboundArrivalTime({ min: Number(0), max: Number(24) });
    setInbuundDepatureTime({ min: Number(0), max: Number(24) });
    setInboundArrivalTime({ min: Number(0), max: Number(24) });
    const newFilters = filters;
    delete newFilters.inboundArrivalTime;
    delete newFilters.inboundDepatureTime;
    delete newFilters.outboundArrivalTime;
    delete newFilters.outboundDepatureTime;
    setFilters({ ...newFilters });
    searchFlight();
  };

  const HandleSubmit = () => {
    const newFilters = filters;
    if (inboundArrivalTime.min == 0 && inboundArrivalTime.max == 24) {
      delete newFilters.inboundArrivalTime;
    }
    if (inboundDepatureTime.min == 0 && inboundDepatureTime.max == 24) {
      delete newFilters.inboundDepatureTime;
    }
    if (outboundArrivalTime.min == 0 && outboundArrivalTime.max == 24) {
      delete newFilters.outboundArrivalTime;
    }
    if (outboundDepatureTime.min == 0 && outboundDepatureTime.max == 24) {
      delete newFilters.outboundDepatureTime;
    }
    setFilters({ ...newFilters });

    searchFlight();
    setshowOption((prev) => !prev);
  };

  let multiCityForm = JSON.parse(window.localStorage.getItem("multiCityForm"));
  let flightPayload = JSON.parse(window.localStorage.getItem("flightPayload"));

  return (
    <>
      <div
        ref={filterRef}
        className={`filterDropdownWrap  ${showOption && "openedOption"}`}
      >
        <div className="flterOption" onClick={() => !disable &&  setshowOption(!showOption)}>
          <span>Times</span>
          <span className="fa fa-angle-down"></span>
        </div>

        <div className="filterDropdown times-wrap">
          <form action="">
            <div className="times-toggle-wrap">
              {selectedFlightTab == "return" && (
                <ul className="times-toggle-ul">
                  <li>
                    <a
                      onClick={() => setActiveTab(0)}
                      className={activeTab == 0 ? "active" : ""}
                    >
                      Departing
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => setActiveTab(1)}
                      className={activeTab == 1 ? "active" : ""}
                    >
                      Returning
                    </a>
                  </li>
                </ul>
              )}

              {selectedFlightTab != "multicity" && (
                <>
                  {activeTab == 0 && (
                    <div id="outbound" className={`flight-info-group`}>
                      <div className="flight-name">
                        <h4>
                          {flightPayload?.flightQueries[0]?.origin} to{" "}
                          {flightPayload?.flightQueries[0]?.destination}
                        </h4>
                      </div>
                      <div className="flight-detail-info-wrap">
                        <div className="flight-detail-info">
                          <img src={flightDepartureIcon} alt="icon" />
                          Departure <span className="dot"></span>{" "}
                          {outboundDepatureTime.min == 0 &&
                          outboundDepatureTime.max == 24
                            ? "Anytime"
                            : `${moment.utc(outboundDepatureTime.min, "hours").format(timeFormat)} - ${moment.utc(outboundDepatureTime.max, "hours").format(timeFormat)}`}
                        </div>
                        <div className="inputRange">
                          <InputRange
                            maxValue={24}
                            minValue={0}
                            step={1}
                            draggableTrack={false}
                            value={outboundDepatureTime}
                            onChange={(value) =>
                              handleRangeChange(value, "outboundDepatureTime")
                            }
                            formatLabel={(e) => {}} //don't remove this it is for removing lable from the range input
                          />
                        </div>
                      </div>
                      <div className="flight-detail-info-wrap">
                        <div className="flight-detail-info">
                          <img src={flightArrivalIcon} alt="icon" />
                          Arrival <span className="dot"></span>{" "}
                          {outboundArrivalTime.min == 0 &&
                          outboundArrivalTime.max == 24
                            ? "Anytime"
                            : `${moment.utc(outboundArrivalTime.min, "hours").format(timeFormat)} - ${moment.utc(outboundArrivalTime.max, "hours").format(timeFormat)}`}
                        </div>
                        <div className="inputRange">
                          <InputRange
                            maxValue={24}
                            minValue={0}
                            step={1}
                            draggableTrack={false}
                            value={outboundArrivalTime}
                            onChange={(value) =>
                              handleRangeChange(value, "outboundArrivalTime")
                            }
                            formatLabel={(e) => {}} //don't remove this it is for removing lable from the range input
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {activeTab == 1 && (
                    <div id="return" className={`flight-info-group `}>
                      <div className="flight-name">
                        <h4>
                          {flightPayload?.flightQueries[1]?.origin} to{" "}
                          {flightPayload?.flightQueries[1]?.destination}
                        </h4>
                      </div>
                      <div className="flight-detail-info-wrap">
                        <div className="flight-detail-info">
                          <img src={flightDepartureReturnIcon} alt="icon" />
                          Departure <span className="dot"></span>{" "}
                          {inboundDepatureTime.min == 0 &&
                          inboundDepatureTime.max == 24
                            ? "Anytime"
                            : `${moment.utc(inboundDepatureTime.min, "hours").format(timeFormat)} - ${moment.utc(inboundDepatureTime.max, "hours").format(timeFormat)}`}
                        </div>
                        <div className="inputRange">
                          <InputRange
                            maxValue={24}
                            minValue={0}
                            step={1}
                            draggableTrack={false}
                            value={inboundDepatureTime}
                            onChange={(value) =>
                              handleRangeChange(value, "inboundDepatureTime")
                            }
                            formatLabel={(e) => {}} //don't remove this it is for removing lable from the range input
                          />
                        </div>
                      </div>
                      <div className="flight-detail-info-wrap">
                        <div className="flight-detail-info">
                          <img src={flightArrivalIcon} alt="icon" />
                          Arrival <span className="dot"></span>{" "}
                          {inboundArrivalTime.min == 0 &&
                          inboundArrivalTime.max == 24
                            ? "Anytime"
                            : `${moment.utc(inboundArrivalTime.min, "hours").format(timeFormat)} - ${moment.utc(inboundArrivalTime.max, "hours").format(timeFormat)}`}
                        </div>
                        <div className="inputRange">
                          <InputRange
                            maxValue={24}
                            minValue={0}
                            step={1}
                            draggableTrack={false}
                            value={inboundArrivalTime}
                            onChange={(value) =>
                              handleRangeChange(value, "inboundArrivalTime")
                            }
                            formatLabel={(e) => {}} //don't remove this it is for removing lable from the range input
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="btnsCnt clear-btn-wrap">
              <button
                type="button"
                onClick={HandleSubmit}
                className="borderBtn apply-btn"
              >
                Apply
              </button>
              <button
                type="button"
                onClick={handleResetRange}
                className="clear-btn"
              >
                Clear
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
