import React from "react";
import LocationPin from "../../Assets/img/location-gray.png";
import MapView from "../../Views/HotelSearchResult/MapView/MapView";
import CheckInCheckOut from "./CheckInCheckOut";
import HotelTheme from "./HotelTheme";
import Theme1 from "../../Assets/img/hotel/business-icon.png";
import Theme2 from "../../Assets/img/hotel/love-icon.png";
import Theme3 from "../../Assets/img/hotel/diamond-icon.png";
import SingleHotelMapView from "../../Views/HotelSearchResult/SingleHotelCard/SingleHotelMapView";
import { useSelector } from "react-redux";
import GreyStarImg from "../../Assets/img/star.png";
import StarImg from "../../Assets/img/yellow-star.png";
function HotelInfo({
  name,
  starImgs,
  address,
  contactNo,
  checkIn,
  checkOut,
  themes,
  rating,
  latitude,
  longitude,
}) {
  const loading = useSelector((state) => state.commonReducer.loader);


  const theme = [
    { image: Theme1, id: 2 },
    { image: Theme2, id: 5 },
    { image: Theme3, id: 7 },
  ];
  // const getRatings = Array(rating)
  //   .fill()
  //   .map((ele, index) => (
  //     <img src={StarImg} alt="yellow-star" key={`${index}_ratings`} />
  //   ));

  function starprint() {
    let star = [];
    let darkStar = 5 - rating;
    for (let i = 0; i < rating; i++) {
      star.push(<img src={StarImg} alt="yellow-star" key={`${i}_rating1`} />);
    }
    for (let i = 0; i < darkStar; i++) {
      star.push(
        <img
          src={GreyStarImg}
          alt="yellow-star"
          key={`${i}_rating2`}
          width="24"
        />
      );
    }
    return star;
  }

  return (
    <div className="row">
      <div className="col leftContent">
        <h3 className="cartTitle">{name}</h3>
        <div className="topSection dflex justifyStart">
          <div className="rating">{starprint()}</div>
        </div>
        <div className="contactInfo">
          <div className="location">
            <img src={LocationPin} alt="icon" /> {address}
          </div>
          <div className="phone">
            <span className="icon-phone"></span>
            {contactNo}
          </div>
        </div>
      </div>
      <div className="col rightContent">
        <div className="timeWrapper">
          {!loading && (
            <SingleHotelMapView
              className=" customShadow desktopViewDisplayNone map-view"
              latitude={latitude}
              longitude={longitude}
            />
          )}
          <div className="row no-gutters">
            <CheckInCheckOut checkIn={checkIn} checkOut={checkOut} />
          </div>
        </div>
        {themes.length > 0 &&
          <div className="themeWrapper hotelTheme">
            <HotelTheme title="Hotel Theme" themes={themes} theme={theme} />
          </div>
        }
      </div>
    </div>
  );
}

export default HotelInfo;
