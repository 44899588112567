import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import hotelBlueImg from "../../Assets/img/apartments-blue.png";
import hotelWhiteImg from "../../Assets/img/apartments-white.png";
import flightBlueImg from "../../Assets/img/flight-blue.png";
import flightWhiteImg from "../../Assets/img/flight-white.png";
import carBlueImg from "../../Assets/img/car-blue.png";
import carWhiteImg from "../../Assets/img/car-white.png";

function TabList() {
  // State of class for list
  const location = useLocation();
  const pathName = location.pathname;

  const [active, setActive] = useState(() => {
    if (pathName === "/hotel-faq") return 0;
    else if (pathName === "/flight-faq") return 1;
    else if (pathName === "/car-faq") return 2;
    return 0;
  });

  // Handling class with id
  const handleActive = (id) => {
    setActive(id);
  };

  // Faqs tab list
  const faqTabList = [
    {
      id: 0,
      title: "Hotels",
      path: "/hotel-faq",
      image: active === 0 ? hotelBlueImg : hotelWhiteImg,
    },
    {
      id: 1,
      title: "Flights",
      path: "/flight-faq",
      image: active === 1 ? flightBlueImg : flightWhiteImg,
    },
    {
      id: 2,
      title: "Cars",
      path: "/car-faq",
      image: active === 2 ? carBlueImg : carWhiteImg,
    },
  ];

  return (
    <div className="col leftContent">
      <div className="title">
        <h1>FAQ’s</h1>
      </div>
      <div className="tabsWrapper">
        {/* getting faqs tab list */}
        <ul className="list-unstyled">
          {faqTabList.map((item, index) => (
            <li
              key={index}
              className={active === index ? "active" : ""}
              onClick={() => handleActive(index)}
            >
              <Link to={item.path} key={index}>
                <img src={item.image} alt="icon" key={index} />
                {item.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default TabList;
