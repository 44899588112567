import React from "react";
import moment from "moment";

function FlightTime({
  className,
  startEndDate,
  startEndTime,
  originAndDest,
  adjustment,
}) {
  const currentGeoLocation =
    window.localStorage.getItem("currentGeoLocation") &&
    JSON.parse(window.localStorage.getItem("currentGeoLocation"));

  const isUS = currentGeoLocation === "US";
  const timeFormat = isUS ? "hh:mm a" : "HH:mm";
  
  return (
    <div className={className}>
      {/* <div className="supText">+1</div> */}
      <div className="flgithDate">
        {moment(startEndDate).format("ddd,D MMM")}
        {/* <sup className="departureDateAdjustment">
          {adjustment && adjustment > 0 ? `+${adjustment}` : ""}
        </sup> */}
      </div>
      <div className="flightTime">
        {moment(startEndTime).format(timeFormat,{trim: false})}
      </div>
      <div className="flightLocation">{originAndDest}</div>
    </div>
  );
}

export default FlightTime;
