import React from 'react'

//flight faq list getting from list of array
function FlightFaqWrapper({faq, openList, index, handleOpenCloseList}) {
  return (
    <div className="faqContent" key={index}>
		<div className="faqQuestion">
			<button className={faq.id === openList ? 'active-btn' : ''} onClick= {() => handleOpenCloseList(faq.id)}>{faq.title}</button>
		</div>
		<div className="faqAnswer" style={faq.id === openList ? null : {display: 'none'}}>
            {faq.headingLine1 && <h3>{faq.headingLine1}</h3> }
            {faq.description1 && <p>{faq.description1}</p>}
            {faq.list6 &&
            <ul>
                {faq.list6.map((item, index) => {
                    return (
                        <li key={index}>{item}</li>
                    )
                })}
            </ul> 
            }
            {faq.headingLine2 && <h3>{faq.headingLine2}</h3>}
            {faq.list7 &&
            <ul>
                {faq.list7.map((item, index) => {
                   return(
                       <li key={index}>
                           {item}
                           {index === 8 && <h3>{item}</h3>}
                        </li>
                   )
                })}
            </ul> 
            }
            {faq.headingListLine3 && <h3>{faq.headingListLine3}</h3> }
            {faq.list8 && 
            <ul>
                {faq.list8.map((item, index) => {
                    return (
                        <li key={index}>{item}</li>
                    )
                })}
            </ul>
            }
            {faq.headingListLine4 && <h3>{faq.headingListLine4}</h3> }
            {faq.list9 &&
            <ul>
                {faq.list9.map((item, index) => {
                    return (
                        <li key={index}>{item}</li>
                    )
                })}
            </ul>
            }
            {faq.headingListLine5 && <h3>{faq.headingListLine5}</h3> }
            {faq.list10 && 
            <ul>
                <li>{faq.list10.list1}</li>
            </ul>
            }
            {faq.description2 && <p>{faq.description2}</p>}
            {faq.headingLine3 && <h3>{faq.headingLine3}</h3> }
            {faq.description3 && <p>{faq.description3}</p>}
            {faq.headingLine4 && <h3>{faq.headingLine4}</h3> }
            {faq.description4 && <p>{faq.description4}</p>}
            {faq.list5 && 
            <ul>
                {faq.list5.map((item, index) => {
                    return(
                        <li key={index}>{item}</li>
                    )
                })}
            </ul>
            }
            {faq.headingLine5 && <h3>{faq.headingLine5}</h3> }
            {faq.description5 && <p>{faq.description5}</p>}
            {faq.headingLine6 && <h3>{faq.headingLine6}</h3> }
            {faq.description6 && <p>{faq.description6}</p>}
            {faq.headingListLine6 && <h3>{faq.headingListLine6}</h3> }
            {faq.list11 &&
            <ul>
                 {faq.list11.map((item, index) => {
                    return(
                        <li key={index}>{item}</li>
                    )
                })}
            </ul>
            }
            {faq.list1 && 
            <ul>
                {faq.list1.map((item, index) => {
                    return(
                        <li key={index}>{item}</li>
                    )
                })}
            </ul> }
            {faq.headingList2Line6 && <h3>{faq.headingList2Line6}</h3> }
            {faq.list2 && 
            <ul>
                {faq.list2.map((item, index) => {
                    return(
                        <li key={index}>{item}</li>
                    )
                })}
            </ul> }
            {faq.headingList3Line6 && <h3>{faq.headingList3Line6}</h3> }
            {faq.list3 && 
            <ul>
               {faq.list3.map((item, index) => {
                    return(
                        <li key={index}>{item}</li>
                    )
                })}
            </ul> }
            {faq.heaidngList4Line6 && <h3>{faq.heaidngList4Line6}</h3> }
            {faq.list4 && 
            <ul>
                <li>{faq.list4.list1}</li>
            </ul> }
            {faq.description7 && <p>{faq.description7}</p>}
            {faq.headingLine8 && <h3>{faq.headingLine8}</h3> }
            {faq.description8 && <p>{faq.description8}</p>}
            {faq.headingLine9 && <h3>{faq.headingLine9}</h3> }
            {faq.description9 && <p>{faq.description9}</p>}
            {faq.headingLine10 && <h3>{faq.headingLine10}</h3> }
            {faq.description10 && <p>{faq.description10}</p>}
            {faq.headingLine11 && <h3>{faq.headingLine11}</h3> }
            {faq.description11 && <p>{faq.description11}</p>}
            {faq.headingLine12 && <h3>{faq.headingLine12}</h3> }
            {faq.description12 && <p>{faq.description12}</p>}
            {faq.description13 && <p>{faq.description13}</p>}

		</div>
	</div>
  )
}

export default FlightFaqWrapper