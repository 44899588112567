import React, { useState } from "react";
import InfoIcon from "../../../Assets/img/info.png";
import LocationIcon from "../../../Assets/img/location-gray.png";

function PropertiesDetails({ description, nearBy }) {
  const [selectedTab, setselectedTab] = useState(0);

  const pointOfInterest = nearBy[0]?.values;
  const metroStations = nearBy[1]?.values;

  return (
    <div id="property-details"  className="propertyDetails">
      <div className="sectionTitle">
        <h2>Property Details</h2>
      </div>
      <div className="propertyDetailsTab">
        <ul className="list-unstyled d-flex">
          <li
            className={selectedTab === 0 ? "active" : ""}
            onClick={() => setselectedTab(0)}
          >
            <img src={InfoIcon} alt="icon" />
            <button type="button">About This Location</button>
          </li>
          <li
            className={selectedTab === 1 ? "active" : ""}
            onClick={() => setselectedTab(1)}
          >
            <img src={LocationIcon} alt="icon" />
            <button type="button">What’s Nearby</button>
          </li>
        </ul>
        {selectedTab === 0 && (
          <div
            className="content list-text"
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
        )}
        {selectedTab === 1 && (
          <div
            className="content "
            // dangerouslySetInnerHTML={{ __html: "123" }}
          >
            <div className="nearby-wrap">
              {pointOfInterest?.map((item, index) => {
                if (index < 4)
                  return (
                    <div key={`${index}pointOf`}>
                      {" "}
                      <span>{item.name}</span>{" "}
                      <span>{item.dist.toFixed(2)} miles</span>
                    </div>
                  );
              })}
            </div>
            <div className="nearby-wrap">
              {metroStations?.map((item, index) => {
                if (index < 4)
                  return (
                    <div key={`${index}station`}>
                      <span>{item.name}</span>
                      <span>{item.dist.toFixed(2)} miles</span>
                    </div>
                  );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PropertiesDetails;
