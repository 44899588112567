import React from "react";
import Slider from "react-slick";

function ImageSlider({ hotelImages, toggleShowGallery }) {
  return (
    <>
      {hotelImages &&
        hotelImages.map((ele, index) => {
          if (index < 5) {
            return (
              <div className="images col" key={`${index}_hotelImg`}>
                <img
                  src={ele}
                  alt="hotel-detail-image"
                  className="w-100"
                  onClick={() => toggleShowGallery(index)}
                />
              </div>
            );
          }
        })}
    </>
  );
}

export default ImageSlider;
