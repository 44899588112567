import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";

export const useFilters = () => {
    const dispatch = useDispatch()

    const currentFilters = useSelector((state) => state.flightReducer?.flightFilters);


    const setFilters = (subFilterName, data , status) => {
        const temp = { ...currentFilters, [subFilterName]: data }
        if(subFilterName == "alliances" && status){
            delete temp.airlines
        } 
        if(subFilterName == "airlines" && status){
            delete temp.alliances
        } 
        dispatch({ type: 'UPDATE_FLIGHT_FILTERS', payload: temp })
    }

    const removeFilter = (removeFilter) => {
        let temp = { ...currentFilters }
        delete temp[removeFilter]

        dispatch({ type: 'UPDATE_FLIGHT_FILTERS', payload: temp })
    }

    const appliedFiltersList = Object.keys(currentFilters) || null

    const searchFlight =() => {
        
    }

    return [currentFilters, setFilters, removeFilter, appliedFiltersList, searchFlight];
}
