import CommissionTable from "./Table/CommissionTable";
import Select from "react-select";
import SelectComponent from "../Select/SelectComponent";
import { useEffect, useState } from "react";
import { commafy } from "../../Util/util";

function FreemiumMemberTravel({
  selectedTab,
  setSelectedTab,
  commisionSummary,
}) {
  const [isMobile, SetMobile] = useState(
    window.innerWidth < 768 ? true : false
  );
  const updateSize = () => {
    if (window.innerWidth < 768) {
      SetMobile(true);
    } else {
      SetMobile(false);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, [window.innerWidth]);

  const optionsSortBy = commisionSummary?.data?.filter?.date_range?.map(
    (ele) => {
      return { value: ele.id, label: ele.label };
    }
  );

  return (
    <form>
      <div className="heading">
        <div className="heading-info">
          <h3>{selectedTab?.label}</h3>
          {isMobile && (
            <div className="desktop-hide tottal-box light-green-text">
              Total:{" "}
              <span>
                $
                {commafy(
                  commisionSummary?.data?.dashboard_details?.commisions[
                    selectedTab?.commission_type
                  ]
                )}
              </span>
            </div>
          )}
        </div>
        <div className="right-content">
          {!isMobile && (
            <div className="mobile-hcommission_typee tottal-box light-green-text">
              Total:{" "}
              <span>
                $
                {
                  commisionSummary?.data?.dashboard_details?.commisions[
                    selectedTab?.commission_type
                  ]
                }
              </span>
            </div>
          )}
          {/* <div className="dropdownWrap listDropdown toggleClass">         */}
          <div className={"dropdownWrap flatDropdown toggleClass"}>
            <SelectComponent
              // isComponents
              className="btnSml width180 toggleBtn"
              icon="fa fa-angle-down"
              name="sortByOption"
              placeholder=""
              options={optionsSortBy}
              onChange={(value) => {
                setSelectedTab({ ...selectedTab, filter: value });
              }}
              value={selectedTab.filter}
            />
          </div>
        </div>
      </div>
      <CommissionTable selectedTab={selectedTab} />
    </form>
  );
}

export default FreemiumMemberTravel;