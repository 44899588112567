import React, { useState, useEffect } from "react";
import {commafy} from "../../Util/util";
import FlightCheckBoxFilter from "../../Component/Filters/FlightCheckBoxFilter";
import FlightRadioFilter from "../../Component/Filters/FlightRadioFilter";
import { FlightTimesFilter } from "../../Component/Filters/FlightTimesFilter";
import { FlightAirlinesFilter } from "../../Component/Filters/FlightAirlinesFilter"; 
// import  {FlightBagsFilter}  from "../../Component/Filters/FlightBagsFilter";
import FlightSortByFilter from "../../Component/Filters/FlightSortByFilter";
import PriceSingleRange from "../../Component/Filters/PriceSingleRange";
import Button from "../../Component/FormComponent/Button";
import { useDispatch, useSelector } from "react-redux";
 
function FlightListingFilters({
  airLines,
  filters,
  priceFilter,
  setFilters,
  searchFlight,
  sortBy,
  selectedFlightTab,
  setshowMobileFilter,
  showMobileFilter
}) {

  const flights = useSelector((state) => state.flightReducer?.flightSearchData);

  // Airline options for filter

  const [airlineOptions, setAirlineOptions] = useState([]);
  const [connectingAirportOptions, setConnectingAirportOptions] = useState([]);
  const [alliancesOptions, setAlliancesOptions] = useState([]); 
  const [duration,setDuration] = useState([]);
 
  useEffect(() => {
    setDuration(flights?.filtersAvailable?.duration);
    setAirlineOptions(
      flights?.filtersAvailable?.airlines?.map((item, index) => {
        return {
          name: item.id,
          label: item.label,
          value: item.id,
        };
      })
    );

    setConnectingAirportOptions(
      flights?.filtersAvailable?.connections?.map((item, index) => {
        return {
          name: item.id,
          label: item.label,
          value: item.id,
        };
      })
    );
    setAlliancesOptions(
      flights?.filtersAvailable?.alliances?.map((item, index) => {
        return {
          name: item.id,
          label: item.label,
          value: item.id,
        };
      })
    );
   
  }, [flights]);

  // flight search api call after applying filters in mobile view
  const handleApply = () => {
    searchFlight();
  };
  // reset filters in mobile view
  const resetFilterHandle = () => {
    window.localStorage.removeItem("flightFilter");
    setFilters({});
    searchFlight(1, 15, true);
    setshowMobileFilter(!showMobileFilter);
  };
  return (
    <>
      <Button
        onClick={() => setshowMobileFilter(!showMobileFilter)}
        className="mobileFilterBtn mobileShow"
      >
        <i className="fa fa-sliders"></i> Filter &amp; Sorting
      </Button>
      <div
        className={`mobileSortFilter mobilePopup ${showMobileFilter ? "d-block" : ""
          }`}
      >
        <div className="mobileShow">
          <Button className={`clearFilter ${showMobileFilter ? "d-none" : ""}`}>
            Clear
          </Button>
          <Button
            className="closePopup closePopup2"
            onClick={() => setshowMobileFilter(!showMobileFilter)}
          >
            <span className="icon-close"></span>
          </Button>
          <h4 className="mobilePopTitle">Sort by</h4>
          <div className="clearBtnMobile">
            <Button
              className="btn"
              type="reset"
              onClick={() => resetFilterHandle()}
            >
              Clear
            </Button>
          </div>
          <div className="applyBtnMobile">
            <Button
              className="btn"
              onClick={() => {
                handleApply();
                setshowMobileFilter(!showMobileFilter);
              }}
            >
              Apply
            </Button>
          </div>
        </div>
        <div className="sortFilter">
          <div className="checkbox-filter">
            <h4 className="mobilePopTitle filterTitle mobileShow">Filter by</h4>
            {/* //Flight price range filter */}
            <PriceSingleRange
              label="Price"
              priceFilter={priceFilter}
              filters={filters}
              setFilters={setFilters}
              type={"price"}
              searchFlight={searchFlight}
              disable={!(flights?.filtersAvailable?.price)}
            />
            {/* //Flight Stops filter */}
            <FlightRadioFilter
              label="Stops"
              filterOptions={flights?.filtersAvailable?.stops?.map((item) => {
                return { label: item.label, value: item.id };
              })}
              filters={filters}
              type="stops"
              searchFlight={searchFlight}
              disable={!(flights?.filtersAvailable?.stops)}
            />
            {/* Flight Airlines filter */}
            <FlightAirlinesFilter
              label="Airlines"
              filterOptions={airlineOptions}
              alliancesOptions={alliancesOptions}
              filters={filters}
              setFilters={setFilters}
              searchFlight={searchFlight}
              type="airline"
              disable={!(flights?.filtersAvailable?.airlines)}
            />

            {/* connecting airpots */}

            <FlightCheckBoxFilter
              label="Connecting Airports"
              filterOptions={connectingAirportOptions}
              filters={filters}
              setFilters={setFilters}
              searchFlight={searchFlight}
              type="connections"
              disable={!(flights?.filtersAvailable?.connections)}
            />
            {/* //Flight duration range filter */}
            <PriceSingleRange
              label="Duration"
              type={"duration"}
              priceFilter={duration}
              searchFlight={searchFlight}
              disable={!(flights?.filtersAvailable?.duration)}
            />

            {/* <FlightRadioFilter
              label="Emissions"
              filterOptions={
                [{ label: "Any", value: "Any" }, { label: "Low", value: "Low" }]
              }
              searchFlight={searchFlight}
              type="emissions"
            /> */}

            {/* <FlightBagsFilter
              label="Bags" 
              filters={filters}
              setFilters={setFilters}
              searchFlight={searchFlight}
            /> */}

            <FlightTimesFilter
              filters={filters}
              setFilters={setFilters}
              searchFlight={searchFlight}
              selectedFlightTab={selectedFlightTab}
              disable={!(flights?.filtersAvailable)}
            />

            {/* Flight Arrival time filter */}
            {/* <FlightCheckBoxFilter
              label="Arrival Time"
              filterOptions={flightArrivalTimeFilter}
              filters={filters}
              setFilters={setFilters}
              searchFlight={searchFlight}
              type="arrival_time"
              openTag={openArrivalTag}
              setOpenTag={setOpenArrivalTag}
            /> */}
            {/* Flight Depature time filter */}
            {/* <FlightCheckBoxFilter
              label="Departure Time"
              filterOptions={flightArrivalTimeFilter}
              filters={filters}
              setFilters={setFilters}
              searchFlight={searchFlight}
              type="departure_time"
              openTag={openDepartureTag}
              setOpenTag={setOpenDepartureTag}
            /> */}
          </div>
          <div className="sort-filter-wrap">
            <FlightSortByFilter
              label="Sort by"
              sortBy={sortBy}
              searchFlight={searchFlight}
              setshowMobileFilter={setshowMobileFilter}
              disable={!(flights?.sortAvailable)}
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default FlightListingFilters;
