import React, { useState } from "react";
import Button from "../../../Component/FormComponent/Button";
import SingleAmenities from "../../../Component/Home/SingleAmenities";
import { isEmpty } from "lodash";
function Amenities({ amenities }) {
  const [showMoreAmenities, setshowMoreAmenities] = useState(false);
  return (
    <div id="amenities" className="amenitiesWrapper">
      <div className="sectionTitle">
        <h2>Amenities</h2>
      </div>
      <div className="amenitiesMainInfo">
        <div className="amenitiesInfo">
          <div className="row">
            {!isEmpty(amenities) &&
              amenities.map((ele, index) => {
                if (!showMoreAmenities && index < 4) {
                  return (
                    <SingleAmenities {...ele} key={`${index}_singleAmen`} />
                  );
                } else if (showMoreAmenities) {
                  return (
                    <SingleAmenities {...ele} key={`${index}_singleAmen`} />
                  );
                }
              })}
          </div>
        </div>
        <div className="showAllLinks text-center">
          <Button onClick={() => setshowMoreAmenities(!showMoreAmenities)}>
            {showMoreAmenities
              ? "Show Less Amenities  "
              : "Show All Amenities "}
            <i
              className={`fa fa-angle-${showMoreAmenities ? "up" : "down"}`}
            ></i>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Amenities;
