import React, { useState, useEffect, useRef } from "react";
import { Formik } from "formik";
import InputField from "../../Component/FormComponent/InputField";
import Button from "../../Component/FormComponent/Button";
import { validation } from "../../Component/Validation/Validation";
import { useDispatch, useSelector } from "react-redux";
import EmailSuccess from "../../Component/Modals/EmailSuccess";
import { cardsAction } from "../../Stores/Actions/cardsAction";

// Input form having firstName, lastName and email
function Email({ para, para2, currentUserId, affiliateLink,emailError , emailStatus ,userType , emailModalText }) {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [emailContent, setEmailContent] = useState(
    <>Your Freemium Member Invitation Email <br /> has been sent successfully.</> 
  );

  const formikRef = useRef();

  const [emailSuccesMsg, setEmailSuccesMsg] = useState(null);

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
  };

  const handleOpenCloseModal = () => {
    if (!openModal) {
      setOpenModal(!openModal);
    } else {
      setOpenModal(false);
      dispatch(cardsAction.resetCardAssignObject());
    }
  };

  useEffect(() => {
    if(userType === "premium") {
      setEmailContent(<>Your Premium Member Invitation Email <br /> has been sent successfully.</> );
    }
    else if(userType === "affiliate") {
      setEmailContent(<>Your Affiliate Member Invitation Email <br /> has been sent successfully.</> );
    }

  }, [userType])
  useEffect(() => {
    if (emailStatus) {
      setOpenModal(true);
    }

    if (emailError) {
      formikRef.current.setTouched({ email: true });
      formikRef.current.setErrors({
        email:emailError,
      });
    }

  }, [emailStatus, emailError])


  const handleSubmit = (value, formikProps) => {
    const data = {
      user_id: currentUserId,
      affiliate_link: affiliateLink,
      first_name: value.firstName.trim(),
      last_name: value.lastName.trim(),
      email: value.email.trim().toLowerCase(),
    };
    dispatch(cardsAction.assignCard(data,userType))

    // dispatch(cardsAction.assignCard(data)).then((res)=>{
    //    if(res.status == 200){
    //       setEmailSuccesMsg("Email sent")
    //       setTimeout(() => {
    //         setEmailSuccesMsg(null)
    //       }, 4000);
    //       // formikProps.resetForm();
    //     }
    //     if(res?.response?.status == 404){
    //       formikProps.setTouched({ email: true });

    //       formikProps.setErrors({
    //         email: res?.response?.data?.message,
    //       });
    //     }
    // })
  };

  const renderForm = (formikProps) => {
    const { values, errors, touched, handleSubmit, handleBlur, handleChange } =
      formikProps;
    return (
      <form onSubmit={handleSubmit}>

        <InputField
          name="firstName"
          type="text"
          label="First Name"
          placeholder="First Name"
          value={values.firstName}
          error={errors.firstName}
          touched={touched.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
          inputClass="formGroup"
        />
        <InputField
          name="lastName"
          type="text"
          label="Last Name"
          placeholder="Last Name"
          value={values.lastName}
          error={errors.lastName}
          touched={touched.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
          inputClass="formGroup"
        />
        <InputField
          name="email"
          type="email"
          label="Email"
          placeholder="Email"
          value={values.email}
          error={errors.email}
          touched={touched.email}
          onChange={handleChange}
          onBlur={handleBlur}
          inputClass="formGroup"
        />
        <div className="success-messages">{emailSuccesMsg}</div>


        <div className="formGroup buttonWrap">
          <p>{para}</p>
          <Button type="submit" text="Send Email" className="btn" />
        </div>
      </form>
    );
  };

  return (
    <>
      <p>{para2}</p>
      <div className="formWrapper">
        <Formik
          onSubmit={handleSubmit}
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validation}
          validateOnBlur={false}

        >
          {renderForm}
        </Formik>
      </div>

      <EmailSuccess
        openModal={openModal}
        closeModal={handleOpenCloseModal}
        text={emailContent}
      />

    </>
  );
}

export default Email;