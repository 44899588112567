export const HotelissuesOptions = [
  {
    label: "I did not receive my Booking Confirmation",
    value: "I did not receive my Booking Confirmation",
  },
  {
    label: "I would like to cancel my booking",
    value: "I would like to cancel my booking",
  },
  {
    label: "I would like to change my booking",
    value: "I would like to change my booking",
  },
  {
    label: "I would like to check on my refund status",
    value: "I would like to check on my refund status",
  },
  {
    label: "My Booking seems to have failed",
    value: "My Booking seems to have failed",
  },
  {
    label: "My Payment doesn’t seem to go through",
    value: "My Payment doesn’t seem to go through",
  },
  {
    label: "My Booking cannot be found by the property",
    value: "My Booking cannot be found by the property",
  },
  {
    label: "The Hotel Information is not accurate",
    value: "The Hotel Information is not accurate",
  },
  {
    label: "I have a non-listed issue",
    value: "I have a non-listed issue",
  },
]

export const carsIssuesOptions = [
  {
    label: "I did not receive my Reservation Confirmation",
    value: "I did not receive my Reservation Confirmation",
  },
  {
    label: "I would like to cancel my Reservation",
    value: "I would like to cancel my Reservation",
  },
  {
    label: "I would like to change my Reservation",
    value: "I would like to change my Reservation",
  },
]

export const flightIssuesOptions = [
  {
    label: "I did not receive my Booking Confirmation",
    value: "I did not receive my Booking Confirmation",
  },
  {
    label: "I did not receive my E-Ticket Number",
    value: "I did not receive my E-Ticket Number",
  },
  {
    label: "I would like to cancel my booking",
    value: "I would like to cancel my booking",
  },
  {
    label: "I would like to change my booking",
    value: "I would like to change my booking",
  },
  {
    label: "I would like to check on my refund status",
    value: "I would like to check on my refund status",
  },
  {
    label: "My Booking seems to have failed",
    value: "My Booking seems to have failed",
  },
  {
    label: "My Payment doesn’t seem to go through",
    value: "My Payment doesn’t seem to go through",
  },
  {
    label: "I would like to change the name on my booking",
    value: "I would like to change the name on my booking",
  },
  {
    label: "I would like to change the Date of Birth on my booking",
    value: "I would like to change the Date of Birth on my booking",
  },
  {
    label: "I would like to change my Passport Information on my booking",
    value: "I would like to change my Passport Information on my booking",
  },
  {
    label: "I would like to change/add my Frequent Flyer Number on my booking",
    value: "I would like to change/add my Frequent Flyer Number on my booking",
  },
  {
    label: "I would like to cancel/change part of my flight booking",
    value: "I would like to cancel/change part of my flight booking",
  },
  {
    label: "I have a non-listed issue",
    value: "I have a non-listed issue",
  },
]

export const vHomesIssuesOptions = [
  {
    label: "I did not receive my Booking Confirmation",
    value: "I did not receive my Booking Confirmation",
  },
  {
    label: "I would like to cancel my booking",
    value: "I would like to cancel my booking",
  },
  {
    label: "I would like to change my booking",
    value: "I would like to change my booking",
  },
  {
      label: "I would like to check on my refund status",
      value: "I would like to check on my refund status",
    },
    {
      label: "My Booking seems to have failed",
      value: "My Booking seems to have failed",
    },
    {
      label: "My Payment doesn’t seem to go through",
      value: "My Payment doesn’t seem to go through",
    },
    {
      label: "My Booking cannot be found by the property",
      value: "My Booking cannot be found by the property",
    },
    {
      label: "The property is not as described",
      value: "The property is not as described",
    },
    {
      label: "I would like to report a concern about the property",
      value: "I would like to report a concern about the property",
    },
    {
      label: "I have a non-listed issue",
      value: "I have a non-listed issue",
    }
];

export const membershipTabIssuesOptions = [
  {
    label: "I would like to cancel my Membership",
    value: "I would like to cancel my Membership",
  },
  {
    label: "I would like to upgrade my Membership",
    value: "I would like to upgrade my Membership",
  },
  {
    label: "I would like to renew my Membership",
    value: "I would like to renew my Membership",
  },
  {
    label: "I would like to update/change my Credit/Debit Card",
    value: "I would like to update/change my Credit/Debit Card",
  },
  {
    label: "My Membership did not renew",
    value: "My Membership did not renew",
  },
  {
    label: "I would like to remove an Authorized User",
    value: "I would like to remove an Authorized User",
  },
]