import { useEffect, useState } from "react";
export default function useCurrentDevice() {
    const [currentDevice, setCurrentDevice] = useState({
        isDesktop: false,
        isIpad: false,
        isMobile: false
    });

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth > 1024) {                                                    //check is desktop screen
                setCurrentDevice({ isDesktop: true, isIpad: false, isMobile: false });
            } else if (window.innerWidth > 768) {                                              //check is ipad screen
                setCurrentDevice({ isDesktop: false, isIpad: true, isMobile: false });
            } else {                                                                           //else mobile screen
                setCurrentDevice({ isDesktop: false, isIpad: false, isMobile: true });
            }
        }

        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return currentDevice;   // return current device 
}

