import React, { useState } from "react";

function HotelOfferCard() {
  const [offer, setOffer] = useState(true);
  return (
    offer && (
      <div className="card2">
        <div className="dflex noteCard">
          <div className="dflex">
            <span className="icon-border-plain"></span>
            Tripello Members save up to 65% or more
          </div>
          <a href="/membership-upgrade" target="_blank" className="btnBorder2">
            Learn How <i className="fa fa-angle-right"></i>
          </a>
          <a href="#" className="closeBtn">
            <i className="icon-close" onClick={() => setOffer(!offer)}></i>
          </a>
        </div>
      </div>
    )
  );
}

export default HotelOfferCard;
