import React from 'react'


//car faq list getting from list of array
function CarFaqWrapper({faq, openList, handleOpenCloseList, index}) {
  return (
        <div className="faqContent" key={index}>
            <div className="faqQuestion">
                <button className={faq.id === openList ? 'active-btn' : ''} onClick={() => handleOpenCloseList(faq.id)}>{faq.title}</button>
            </div>
            <div className="faqAnswer" style={faq.id === openList ? null : {display: 'none'}}>
                {faq.heading && <h3>{faq.heading}</h3> }
                {faq.description1 && <p>{faq.description1}</p> }
                {faq.list && 
                <ul>
                    {faq.list.map((item, index) => {
                        return (
                            <li key={index}>{item}</li>
                        )
                    })}
                </ul> 
                }
                {faq.list6 && 
                <ul>
                    {faq.list6.map((item, index) => {
                        return (
                            <li key={index}>{item}</li>
                        )
                    })}
                </ul> }
                {faq.list7 && 
                <ul>
                    <li>
                        {faq.list7[0]}
                        <ul>
                            <li>{faq.list7[1]}</li>
                            <li>{faq.list7[2]}</li>
                            <li>{faq.list7[3]}</li>
                        </ul>
                    </li>
                    <li>{faq.list7[4]}</li>
                    <li>{faq.list7[5]}</li>
                    <li>{faq.list7[6]}</li>
                    <li>{faq.list7[7]}</li>
                </ul> }
                {faq.heading1 && <h3>{faq.heading1}</h3> }
                {faq.list8 && 
                <ul>
                    {
                        faq.list8.map((item, index) => {
                            return (
                                <li key={index}>{item}</li>
                            )
                        })
                    }
                    
                </ul> }
                {faq.description2 && <p>{faq.description2}</p> }
                {faq.list5 && 
                <ul>
                    {
                        faq.list5.map((item, index) => {
                           return <li key={index}>{item}</li>
                        })
                    }
                </ul> }
                {faq.heading2 && <h3>{faq.heading2}</h3> }
                {faq.list2 && 
                <ul>

                    {faq.list2.map((item, index) => {
                        return(
                            <li key={index}>{item}</li>
                        )
                    })}
                </ul> }
                {faq.description3 && <p>{faq.description3}</p> }
                {faq.description4 && <p>{faq.description4}</p> }
                {faq.list3 && 
                <ul>
                    {faq.list3.map((item, index) => {
                        return(
                            <li key={index}>{item}</li>
                        )
                    })}
                </ul> }
                {faq.description5 && <p>{faq.description5}</p> }
                {faq.description6 && <p>{faq.description6}</p> }
                {faq.description7 && <p>{faq.description7}</p> }
                {faq.description8 && <p>{faq.description8}</p> }
                {faq.description9 && <p>{faq.description9}</p> }
                {faq.description10 && <p>{faq.description10}</p> }
                {faq.list4 && 
                <ul>
                    {
                        faq.list4.map((item, index) => {
                            return (
                                <li key={index}>{item}</li>
                            )
                        })
                    }
                </ul> }
                {faq.description11 && <p>{faq.description11}</p> }

            </div>
        </div>
    )
}

export default CarFaqWrapper