import React, { useEffect, useRef, useState } from "react";
import InputRange from "react-input-range";
import useOutsideHandler from "../../Stores/Utils/useOutsideHandler";
import Button from "../FormComponent/Button";
import { isEmpty, has } from "lodash";
import {commafy} from "../../Util/util";
import { useSelector, useDispatch } from "react-redux";
import {useFilters} from "../../Stores/ConstomHooks/useFilters"
function PriceSingleRange({
  label,
  priceFilter, 
  searchFlight,
  type,
  disable
}) {
  const [showPriceRange, setshowPriceRange] = useState(false);
  const [isMobile, setisMobile] = useState(false);
  const priceRef = useRef(null);

  const [filters,setFilters,removeFilter,appliedFiltersList] = useFilters()
 
  const flightsSearchData = useSelector(
    (state) => state.flightReducer?.flightSearchData
  );

  const toggleCloseFilter = () => {
    setshowPriceRange(false);
  };
  useOutsideHandler(priceRef, showPriceRange, toggleCloseFilter);
  useEffect(() => {
    if (window.innerWidth <= 767) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  }, [window.innerWidth]);
  const [price, setPrice] = useState(
    type == "price"
      ? {
          min: 0,
          max: 100,
        }
      : 100
  );

  useEffect(() => {
    if (!has(filters, type == "price" ? "price" : "duration")) {
      if (type == "price") {
        setPrice({
          min: Number(flightsSearchData?.filtersAvailable?.price?.minPrice),
          max: Number(flightsSearchData?.filtersAvailable?.price?.maxPrice),
        });
      } else {
        setPrice(Number(flightsSearchData?.filtersAvailable?.duration[1]));
      }
    }
  }, [flightsSearchData]);
  // Price Range Set
  const handleRangeChange = (value) => {
    if (type == "price") {
      setPrice({
        min: Number(value.min.toFixed(2)),
        max: Number(value.max.toFixed(2)),
      });

      setFilters("price",{
          minPrice: Number(value.min),
          maxPrice: Number(value.max), 
      });
 
    } else {
      setPrice(value);
      setFilters("duration", value )
    }
  };
  // Reset price range
  const handleResetRange = () => {
      if (type == "price") {
        setPrice({
          min: Number(priceFilter.minPrice),
          max: Number(priceFilter.maxPrice),
        });
      } else {
        setPrice(Number(flightsSearchData?.filtersAvailable?.duration[1]));
      }
      removeFilter(type)
      // searchFlight();
  };

  // Search Flight after applying price range
  const handleSearchByPrice = () => {
    searchFlight();
    setshowPriceRange(!showPriceRange);
  };
  return (
    <div
      className={`filterDropdownWrap ${showPriceRange && "openedOption"}`}
      ref={priceRef}
    >
      <div
        className="flterOption"
        onClick={() => !disable && setshowPriceRange(!showPriceRange)}
      >
        <span>{label}</span>
        <span
          className={`fa fa-angle-${showPriceRange ? "up" : "down"}`}
        ></span>
      </div>
      <div
        className={`filterDropdown priceFilter ${
          showPriceRange ? "d-block" : ""
        }`}
      >
        <span className="priceVal">
          {type == "price" ? (
            <>
              <h4>${commafy(price?.min) || 0}</h4>
              <h4>${commafy(price?.max) || 0}</h4>
            </>
          ) : (
            <>
              Flight duration :{" "}
              {price ==
              Number(flightsSearchData?.filtersAvailable?.duration[1]) ? (
                <p>Any</p>
              ) : (
                <p> Under {price || 0} hrs</p>
              )}
            </>
          )}
        </span>
        <div className="rangeStyle">
          <InputRange
            minValue={
              type == "price"
                ? Number(flightsSearchData?.filtersAvailable?.price?.minPrice)
                : Number(flightsSearchData?.filtersAvailable?.duration[0])
            }
            maxValue={
              type == "price"
                ? Number(flightsSearchData?.filtersAvailable?.price?.maxPrice)
                : Number(flightsSearchData?.filtersAvailable?.duration[1])
            }
            step={type == "price" ? 0.01 : 1}
            draggableTrack={false}
            value={type == "price" ? price : price}
            onChange={(value) => handleRangeChange(value)}
            formatLabel={(e) => {}} //don't remove this it is for removing lable from the range input
            // setRangeVal={setPrice}
          />
        </div>
        <div className="btnsCnt">
          {!isMobile && (
            <Button className="btnBorder" onClick={() => handleSearchByPrice()}>
              Apply
            </Button>
          )}
          <Button
            className="cancelBtn"
            onClick={(e) => {
              setshowPriceRange(false);
              handleResetRange();
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
}
export default PriceSingleRange;
