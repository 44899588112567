import Email from "./Email";
import QrCode from "./QrCode";
import Link from "./Link";

import linkActive from "../../Assets/img/link-active.png";
import link from "../../Assets/img/link.png";
import emailIconActive from "../../Assets/img/email-icon-active.png";
import emailIcon from "../../Assets/img/email-icon.png";
import QRCodeActive from "../../Assets/img/qr-code-icon-active.png";
import QRIcon from "../../Assets/img/qr-code-icon.png";
import qrImage from "../../Assets/img/qr-code-image.png";
import fileDownloadImage from "../../Assets/img/file-download.png";
import appleWalletImage from "../../Assets/img/Apple-Wallet.png";
import gPayImage from "../../Assets/img/G-Pay.png";
import contentCopyImg from "../../Assets/img/content-copy.png";
import whatsAppImg from "../../Assets/img/whatsapp-bg.png";
import telegramImg from "../../Assets/img/telegram-bg.png";
import tiktokImg from "../../Assets/img/tiktok.png";
import instagramImg from "../../Assets/img/instagram-bg2.png";
import pinterestImg from "../../Assets/img/pinterest.png";
import facebookImg from "../../Assets/img/facebook-bg2.png";
import linkedinImg from "../../Assets/img/linkedin-bg.png";
import twitterImg from "../../Assets/img/twitter-bg2.png";
import exampleImage from "../../Assets/img/air.png";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  PinterestShareButton,
  TelegramShareButton,
  WhatsappShareButton,
} from "react-share";

export // Sharing icon list
const socialLinksList = [
  {
    href: "",
    image: whatsAppImg,
  },
  {
    href: "",
    image: telegramImg,
  },
  {
    href: "",
    image: tiktokImg,
  },
  {
    href: "",
    image: instagramImg,
  },
  {
    href: "",
    image: pinterestImg,
  },
  {
    href: "",
    image: facebookImg,
  },
  {
    href: "",
    image: linkedinImg,
  },
  {
    href: "",
    image: twitterImg,
  },
];

// Tab constant for getting list of tab in mobile mode

const user = JSON.parse(window.localStorage.getItem("user"));

const shareUrl = `${process.env.REACT_APP_DOMAIN}/activation?ref=${user?.affiliate_id}&fname=${user?.first_name}&lname=${user?.last_name}`;

const title =
  "Large discounts on Flights, Hotels, Activites, Car Rentals and Extended Stay all round the World!";

export const socialMediaList = [
  {
    href: "",
    image: (
      <WhatsappShareButton
        url={shareUrl}
        title={title}
        separator=":: "
        className="Demo__some-network__share-button"
      >
        <img src={whatsAppImg} alt="social icon" />
      </WhatsappShareButton>
    ),
  },
  {
    href: "",
    image: (
      <TelegramShareButton title={title} url={shareUrl}>
        <img src={telegramImg} alt="social icon" />
      </TelegramShareButton>
    ),
  },
  {
    href: "",
    image: <img src={tiktokImg} alt="social icon" />,
  },
  {
    href: "",
    image: <img src={instagramImg} alt="social icon" />,
  },
  {
    href: "",
    image: (
      <PinterestShareButton
        url={String(window.location)}
        media={`${String(window.location)}/${exampleImage}`}
        className="Demo__some-network__share-button"
      >
        <img src={pinterestImg} alt="social icon" />
      </PinterestShareButton>
    ),
  },
  {
    href: "",
    image: (
      <FacebookShareButton
        url={shareUrl}
        quote={title}
        className="Demo__some-network__share-button"
      >
        <img src={facebookImg} alt="social icon" />
      </FacebookShareButton>
    ),
  },
  {
    href: "",
    image: (
      <LinkedinShareButton
        url={shareUrl}
        className="Demo__some-network__share-button"
      >
        <img src={linkedinImg} alt="social icon" />
      </LinkedinShareButton>
    ),
  },
  {
    href: "",
    image: (
      <TwitterShareButton
        url={shareUrl}
        title={title}
        className="Demo__some-network__share-button"
      >
        <img src={twitterImg} alt="social icon" />
      </TwitterShareButton>
    ),
  },
];
export const TabConstant = [
  {
    id: 0,
    tabkey: "link",
    title: "Link",
    image1: linkActive,
    image2: link,
    body: (
      <Link
        para1="Share your individual Freemium Invitation link. Copy the
                   link and paste into your personal email, send it via SMS or
                   share it through your favorite Social Media Channels."
        contentCopyImg={contentCopyImg}
        heading2="Click to Copy Link"
        para2="Click on the icon to take you to your favorite Social Media
                   Platform to post your link."
        socialMediaList={socialMediaList}
        socialLinksList={socialLinksList}
        className="sharingIconsList"
        linkText={shareUrl}
        MsgShowFun={(ref) => {
          setTimeout(() => {
            ref.current.lastChild.innerText = `Click to Copy Link`;
          }, 500);
          ref.current.lastChild.innerText = "Copied";
        }}
      />
    ),
  },
  {
    id: 1,
    tabkey: "email",
    title: "Email",
    image1: emailIconActive,
    image2: emailIcon,
    body: (
      <Email
        para2="Enter your recipients contact information and we will
                   directly send an email with your individual invitation."
        para="By sending the invite directly you agree to the Terms and Conditions"
      />
    ),
  },
  {
    id: 2,
    tabkey: "qr",
    title: "QR Code",
    image1: QRCodeActive,
    image2: QRIcon,
    body: (
      <QrCode
        para="Share your individual QR Code directly from this page or download it and share it offline."
        qrImage={qrImage}
        fileDownloadImage={fileDownloadImage}
        appleWalletImage={appleWalletImage}
        gPayImage={gPayImage}
        heading="Download QR Code (.PNG)"
      />
    ),
  },
];