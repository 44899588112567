import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Tab, Tabs as TabsComponent, TabList, TabPanel } from 'react-tabs';
import { TabConstant } from './TabsConstant';

// Tab list
function TabLayout({ SocialCardComponant, EmailCardComponant, QrCardComponant }) {
	const [activeTab, setActiveTab] = useState(0);

	const handleTabChange = id => {
		setActiveTab(id);
	};

	const activeNav = id => {
		if (id === activeTab) {
			return 'active';
		}

		return '';
	};

	return (

		<TabsComponent >
			<div className="tabsWrapper">
				<TabList key={1} className="list-unstyled d-flex">
					{TabConstant.map((list, index) => (
						<Tab key={index} className={activeNav(list.id)} onClick={() => handleTabChange(list.id)}>
							<Link key={index} className="anchor-tab" to="#">
								<img src={list.image1} alt="icon" className="active-icon" />
								<img src={list.image2} alt="icon" className="default-icon" />
								{list.title}
							</Link>
						</Tab>
					))}
				</TabList>
			</div>

			<div className="contentWrapper active-tab-content">
				<TabPanel >
					{SocialCardComponant}
				</TabPanel>
			</div>
			<div className="contentWrapper active-tab-content">
				<TabPanel >
					{EmailCardComponant}
				</TabPanel>
			</div>
			<div className="contentWrapper active-tab-content">
				<TabPanel >
					{QrCardComponant}
				</TabPanel>
			</div>

			{/* {TabConstant.map(({body}, i) => (
				<div className="contentWrapper active-tab-content">
					<TabPanel key={i}>{body}</TabPanel>
				</div>))}   */}


		</TabsComponent>

	);
}

export default TabLayout;