import React, { useState } from "react";
import Button from "../FormComponent/Button";
import { isEmpty } from "lodash"

function ReviewBar({ reviews, scrollToView }) {
  const [showMoreReview, setshowMoreReview] = useState(false);
  return (
    <>
      <div className="heading">
        <div className="ratingComment">
          <span className="commentCount">{reviews?.score}</span>
          {reviews?.title}
        </div>
        <h4>{reviews?.review_count} reviews</h4>
      </div>
      <ul>
        {!isEmpty(reviews?.tabs) &&
          reviews.tabs.map((ele, index) => {
            return (
              <li key={`${index}_review`}>
                <div className="d-flex justify-content-between">
                  <h4>{ele.tab_name}</h4>
                  <h3>{ele.reviews_percent}</h3>
                </div>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: `${ele.reviews_percent}%` }}
                    aria-valuenow={String(ele.reviews_percent)}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </li>
            );
          })}
      </ul>
      {reviews && <div className="showMoreLink">
        <Button onClick={() => scrollToView("reviews")}>
          Show All Reviews{" "}
          <i className={`fa fa-angle-${showMoreReview ? "up" : "down"}`}></i>
        </Button>
      </div>
      }
    </>
  );
}

export default ReviewBar;
