import React, { useEffect } from 'react';

const ClarityCode = () => {
  useEffect(() => {
    (function (c, l, a, r, i, t, y) {
      c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
      t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
    })(window, document, "clarity", "script", "j1sf9bxd3m");
  }, []);

  return null; // This component doesn't render any visible content
};

export default ClarityCode;
