import React, {useState} from "react";
import Icon from "./Icon";
import Button from "../../Component/FormComponent/Button";
import { AiOutlineEye,AiOutlineEyeInvisible } from "react-icons/ai";
function IconInputField(props) {
  const {
    type,
    placeholder,
    name,
    onChange,
    value,
    label,
    iconName,
    onFocus,
    onBlur,
    className,
    serverErrors,
    successMessage,
    errors,
    touched,
    src,
    inputClassName,
    autoComplete,
    eyeIcon,
  } = props;
  const [passwordType, setPasswordType] = useState("password");
 
  const togglePassword =()=>{
    if(passwordType==="password")
    {
     setPasswordType("text")
    }else{
      setPasswordType("password")
    }
  }
  return (
    //Input field with Icon
    <>
   
      <div className="form-group-wrap">
        <div className={eyeIcon ? 'inputWrap form-group inputPasswordWrap' : 'inputWrap form-group'}>
          {iconName ? (
            <Icon iconName={iconName} className={className} />
          ) : (
            <div className="icon">
              <img src={src} alt="icon" />
            </div>
          )}
          {label && <label>{label}:</label>}
          <input
            type={eyeIcon ? passwordType : type}
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            onFocus={() => { }}
            onBlur={onBlur}
            value={value}
            className={inputClassName}
            autoComplete={autoComplete}
          />
          {eyeIcon && <><Button type="button" className="btn-eye btn-style-removed" onClick={togglePassword}>{passwordType === "password" ? <AiOutlineEye color="#1d6ec9"/> :  <AiOutlineEyeInvisible color="#1d6ec9"/>}</Button></>}
        </div>
        {errors && touched && <div className="error-messages">{errors}</div>}
        {name === "email" && serverErrors && !errors && touched && (
          <div className="error-messages">{serverErrors?.errors?.email}</div>
        )}

        {name === "password" && serverErrors && !errors && touched && (
          <div className="error-messages">
            {serverErrors?.errors?.password
              ? serverErrors.errors?.password
              : serverErrors.errors?.email}
          </div>
        )}
        {successMessage && !serverErrors && !errors && (
          <div>{successMessage.data?.message}</div>
        )}
      </div>

    </>
  );
}

export default IconInputField;
